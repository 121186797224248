import React from "react";

export default function Pricing() {
  return (
    <div
      id="pricing_tiers"
      className="pricing-tiers section panel overflow-hidden"
    >
      <div className="section-outer panel py-4 md:py-6 xl:py-9 rounded-1-5 lg:rounded-2 bg-primary-600 dark:bg-primary text-white">
        <div className="container sm:max-w-lg xl:max-w-xl">
          <div className="section-inner panel">
            <div className="panel vstack gap-4 sm:gap-6 xl:gap-8">
              <div
                className="heading vstack gap-2 max-w-900px panel mx-auto"
                data-anime="onview: -100; targets: >*; translateY: [48, 0]; opacity: [0, 1]; easing: easeOutCubic; duration: 500; delay: anime.stagger(100, {start: 200});"
              >
                {/* <span className="fw-bold text-secondary">Pricing</span> */}
                <h2 className="title h3 lg:h2 xl:h1 text-white m-0">
                  What is Digital Signage and Its Benefits for Restaurants
                </h2>
                <p className="fs-6 xl:fs-5 text-white max-w-900px">
                Digital signage uses digital displays to show engaging content in restaurants and cafes, making it a crucial part of modern restaurant technology. With our AI-based digital signage software, users can create displays for seasonal products, menus, promotions, and advertisements that adapt to specific customer needs. This latest signage technology keeps content fresh and allows for dynamic content updates based on trending items, upcoming events, or special offers.
                </p>
                <p className="fs-6 xl:fs-5 text-white max-w-900px">
                Using digital signage can boost customer satisfaction and increase sales by creating a more interactive experience. Different types of digital signage ideas are available to fit various restaurant styles, from fast food spots that need quick menu updates to cafes that want to show rotating promotions and fine dining establishments looking to set an elegant mood. With digital signage, restaurants can tailor the customer experience in ways that connect with their audience and keep them coming back.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
