import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Avatar,
    Box,
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Paper,
    Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';

import AWS from 'aws-sdk';
import DownloadIcon from '@mui/icons-material/Download';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';
import config from '../config/config';

//const RDS_URL = config[process.env.REACT_APP_ENV].REACT_APP_RDS_API_URL;
//const redirect_base_url = config[process.env.REACT_APP_ENV].REACT_APP_MAIN_PAGE_URL_HEAD;
//const apk_url = config[process.env.REACT_APP_ENV].REACT_APP_S3_APK;

//const login_url = redirect_base_url+"/login"

// Configure AWS
AWS.config.update({
    region: 'ap-southeast-2',
    accessKeyId: config[process.env.REACT_APP_ENV].REACT_APP_accessKeyId,
    secretAccessKey: config[process.env.REACT_APP_ENV].REACT_APP_secretAccessKey,
});

const s3 = new AWS.S3();

const Installation = () => {
    const [fileUrls, setFileUrls] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedFileUrl, setSelectedFileUrl] = useState('');

    const redirect_base_url = config[process.env.REACT_APP_ENV].REACT_APP_MAIN_PAGE_URL_HEAD;
    //const apk_url = config[process.env.REACT_APP_ENV].REACT_APP_S3_APK;
    //const login_url = `${redirect_base_url}/login`;

    useEffect(() => {
        fetchFileUrls();
    }, []);

    const fetchFileUrls = async () => {
        try {
            const params = {
                Bucket: 'signinspire-marketing-documents',
                Prefix: 'apk/prod/', // Ensure it ends with a slash if it's a folder
            };

            //console.log('params',params)

            const data = await s3.listObjectsV2(params).promise();

            //console.log('data',data)
            const files = data.Contents.filter((file) => file.Key && file.Key !== 'apk/prod/') // Exclude empty or placeholder keys
                .map((file) => ({
                    name: file.Key.split('/').pop(),
                    url: s3.getSignedUrl('getObject', {
                        Bucket: params.Bucket,
                        Key: file.Key,
                        Expires: 60 * 5, // URL expiry time in seconds
                    }),
                }));

            //console.log('files',files)

            setFileUrls(files);
        } catch (err) {
            console.error('Error fetching files from S3:', err);
        }
    };

    const handleDownloadClick = (fileUrl, fileName) => {
        // Create an invisible <a> element
        const link = document.createElement('a');

        //console.log('fileUrl',fileUrl)
        link.href = fileUrl;
        link.download = fileName; // Suggest a filename for the download

        // Append the <a> element to the body (it needs to be in the DOM for the click to work)
        document.body.appendChild(link);

        // Programmatically click the <a> element to trigger the download
        link.click();

        // Remove the <a> element from the DOM after the download is triggered
        document.body.removeChild(link);
    };

    return (
        <div>
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="30vh" padding={4} bgcolor="#f9f9f9">
                <Container>
                    <Grid container spacing={4} justifyContent="center" alignItems="center">
                        <Grid item xs={12} md={6}>
                        <Typography variant="h4" component="h2" gutterBottom>
    Easy Installation Process
</Typography>
<Typography variant="body1" paragraph>
    Experience hassle-free installation with our AI-enhanced digital signage app. <strong>No additional box or player required.</strong> Simply follow the step-by-step instructions and start showcasing your content in no time.
</Typography>

                            <Button
                                className='border-blue-500 bg-blue-500 rounded-md hover:bg-blue-600 text-white'
                                variant="contained"
                                href="/login"
                                // sx={{
                                    // bgcolor: 'primary.main',
                                    // color: '#fff',
                                    // '&:hover': { bgcolor: 'primary.dark' },
                                // }}
                            >
                                Get Started
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <img
                                src="https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/MainPage/google_play_signinspire.png"
                                alt="Easy Installation"
                                style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
                            />
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            {/* Smart Device Section */}
            <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    minHeight="40vh"
    padding={6}
    bgcolor="#f3f4f6"
>
    <Container maxWidth="lg">
        <Typography
            variant="h5"
            display="block"
            gutterBottom
            align="center"
            sx={{ color: "primary.main", fontWeight: "bold" }}
        >
            Smart Device
        </Typography>
        <Typography
            variant="h4"
            component="h2"
            gutterBottom
            align="center"
            sx={{ fontWeight: "bold" }}
        >
            Get Our App on the Google Play Store Today
        </Typography>
        <Typography
            variant="body1"
            align="center"
            paragraph
            sx={{ color: "text.secondary", marginBottom: 4 }}
        >
            Follow these simple instructions to download and install our app directly from the Google Play Store.
        </Typography>
        <Grid container spacing={6} alignItems="center">
            {/* Steps Section */}
            <Grid item xs={12} md={6}>
                <Grid container spacing={4}>
                    {/* Step One */}
                    <Grid item xs={12} sm={6}>
                        <Box display="flex" alignItems="center">
                        <i
        className="fas fa-search"
        style={{ fontSize: '30px', marginRight: '16px', color: '#4A90E2' }}
    ></i>
                            <Box>
                                <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
                                    Step One
                                </Typography>
                                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                                    Open the Google Play Store on your Android device.
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    {/* Step Two */}
                    <Grid item xs={12} sm={6}>
                        <Box display="flex" alignItems="center">
                        <i
        className="fas fa-search"
        style={{ fontSize: '30px', marginRight: '16px', color: '#4A90E2' }}
    ></i>
                            <Box>
                                <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
                                    Step Two
                                </Typography>
                                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                                    Search for our app "SignInspire" using the search bar.
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <Button
                    className='border-blue-500 bg-blue-500 rounded-md hover:bg-blue-600 text-white'
                    variant="contained"
                    href="https://play.google.com/store/apps/details?id=com.signinspire.au.pro&gl=au"
                    // sx={{
                    //     bgcolor: 'primary.main',
                    //     color: '#fff',
                    //     mt: 4,
                    //     '&:hover': { bgcolor: 'primary.dark' },
                    // }}
                >
                    Go to Play Store
                </Button>
            </Grid>
            {/* Google Play and App Icon Section */}
            <Grid item xs={12} md={6} display="flex" justifyContent="center" alignItems="center">
                <Box display="flex" flexDirection="column" alignItems="center">
                    <img
                        src="https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/MainPage/Get_It_From_Google_Play_Store.png"
                        alt="Google Play"
                        style={{ width: '200px', height: 'auto', marginBottom: '20px' }}
                    />
                    <img
                        src="https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/MainPage/tv_play_store.jpg"
                        alt="Sign Inspire Logo"
                        style={{ width: '650px', height: 'auto' }}
                    />
                </Box>
            </Grid>
        </Grid>
    </Container>
</Box>


            {/* Non-Smart Device Section */}
<Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    minHeight="40vh"
    padding={6}
    bgcolor="#f3f4f6"
>
    <Container maxWidth="lg">
        <Typography
            variant="h5"
            display="block"
            gutterBottom
            align="center"
            sx={{ color: "primary.main", fontWeight: "bold" }}
        >
            Non-Smart Device
        </Typography>
        <Typography
            variant="h4"
            component="h2"
            gutterBottom
            align="center"
            sx={{ fontWeight: "bold" }}
        >
            Seamless Digital Signage Installation with Our APK File
        </Typography>
        <Typography
            variant="body1"
            align="center"
            paragraph
            sx={{ color: "text.secondary", marginBottom: 4 }}
        >
            Get started in minutes! Follow these easy steps to install our app on your Android device.
        </Typography>
        <Grid container spacing={6} alignItems="center">
            {/* Steps Section */}
            <Grid item xs={12} md={6}>
                <Grid container spacing={4}>
                    {/* Step One */}
                    <Grid item xs={12} sm={6}>
                        <Box display="flex" alignItems="center">
                        <i
        className="fas fa-search"
        style={{ fontSize: '30px', marginRight: '16px', color: '#4A90E2' }}
    ></i>
                            <Box>
                                <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
                                    Step One
                                </Typography>
                                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                                    Download the APK file from our website to your USB and plug into your device.
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    {/* Step Two */}
                    <Grid item xs={12} sm={6}>
                        <Box display="flex" alignItems="center">
                        <i
        className="fas fa-search"
        style={{ fontSize: '30px', marginRight: '16px', color: '#4A90E2' }}
    ></i>
                            <Box>
                                <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
                                    Step Two
                                </Typography>
                                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                                    Select the apk file and enable 'Unknown Sources' in your device's security settings.
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <Button
                    className='border-blue-500 bg-blue-500 rounded-md hover:bg-blue-600 text-white'
                    // className='border-blue-500 bg-blue-500 rounded-md hover:bg-blue-600 text-white'
                    variant="contained"
                    onClick={() => setDialogOpen(true)}
                    sx={{
                        mt: 4,
                    }}
                >
                    Download APK
                </Button>
            </Grid>
            {/* APK Image Section */}
            <Grid item xs={12} md={6} display="flex" justifyContent="center" alignItems="center">
                <img
                    src="https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/MainPage/tv_apk_install.png"
                    alt="APK Icon"
                    style={{ width: '650px', height: 'auto' }}
                />
            </Grid>
        </Grid>
    </Container>
</Box>

{/* FAQ Section */}
<Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    padding={6}
    bgcolor="#ffffff"
>
    <Container maxWidth="md" sx={{ paddingY: 4 }}>
        <Typography
            variant="h4"
            component="h2"
            gutterBottom
            align="center"
            sx={{ fontWeight: "bold" }}
        >
            Frequently Asked Questions
        </Typography>
        <Typography
            variant="body1"
            align="center"
            paragraph
            sx={{ color: "text.secondary", marginBottom: 4 }}
        >
            Find answers to common questions about installation, troubleshooting, and more.
        </Typography>
        {[
            {
                title: "How to install the app?",
                content: (
                    <>
                        <strong>Via Google Play Store:</strong> Open the Google Play Store app, search
                        for "SignInspire," and tap "Install."
                        <br />
                        <br />
                        <strong>Via APK File:</strong> Download the APK file, enable "Unknown Sources," and
                        follow on-screen instructions to install.
                    </>
                ),
            },
            {
                title: "How to troubleshoot issues?",
                content: (
                    <>
                        <strong>Clear Cache:</strong> Go to Settings &gt; Apps &gt; [Your App] &gt;
                        Storage &gt; Clear Cache.
                        <br />
                        <strong>Update the App:</strong> Ensure the app is up-to-date.
                        <br />
                        <strong>Restart Your Device:</strong> Restart your device if issues persist.
                    </>
                ),
            },
            {
                title: "Can I use the app on multiple devices?",
                content: "Yes, install the app on each device and log in with the same account.",
            },
            {
                title: "How to update the app?",
                content: (
                    <>
                        <strong>Via Google Play Store:</strong> Open the store and check for updates.
                        <br />
                        <strong>Via APK File:</strong> Download the latest APK and install.
                    </>
                ),
            },
            {
                title: "How to uninstall the app?",
                content: (
                    <>
                        <strong>Via Google Play Store:</strong> Search for the app, tap "Uninstall."
                        <br />
                        <strong>Via Settings:</strong> Go to Settings &gt; Apps &gt; [Your App] &gt; Uninstall.
                    </>
                ),
            },
        ].map((faq, index) => (
            <Accordion key={index}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">{faq.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="body1" sx={{ color: "text.secondary" }}>
                        {faq.content}
                    </Typography>
                </AccordionDetails>
            </Accordion>
        ))}
    </Container>
</Box>


            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                minHeight="30vh"
                padding={4}
            >
                <Box mb={2}>
                    <Typography variant="h4" component="h2" gutterBottom align="center">
                        Still have questions?
                    </Typography>
                </Box>
                <Box mb={2}>
                    <Typography variant="h6" align="center">
                        Contact our support team for further assistance.
                    </Typography>
                </Box>
                <Box>
                    <Button
                        className='border-blue-500 bg-blue-500 rounded-md hover:bg-blue-600 text-white'
                        variant="contained"
                        color="primary"
                        // style={{ backgroundColor: '#1E88E5', color: '#ffffff' }}
                    >
                        Contact Us
                    </Button>
                </Box>
            </Box>

            <Dialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                fullWidth
                maxWidth="sm"
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
            >
                <DialogTitle id="dialog-title">Select APK File</DialogTitle>
                <DialogContent>
                    {fileUrls.length > 0 ? (
                        <List>
                            {fileUrls.map((file, index) => (
                                <ListItem
                                    key={index}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        mb: 1,
                                    }}
                                >
                                    <ListItemText primary={file.name} />
                                    <IconButton
                                        color="secondary"
                                        onClick={() => handleDownloadClick(file.url, file.name)}
                                        aria-label={`Download ${file.name}`}
                                    >
                                        <DownloadIcon />
                                    </IconButton>
                                </ListItem>
                            ))}
                        </List>
                    ) : (
                        <Typography variant="body1">No files available for download.</Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogOpen(false)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default Installation;
