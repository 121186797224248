import { Link } from "react-router-dom";
import { useState } from "react";

export default function Features() {
  const [activeTab, setActiveTab] = useState(1);
  return (
    <div
      id="main_features"
      className="main-features section panel overflow-hidden"
    >
      <div className="section-outer panel pb-6 xl:pb-9">
        <div className="container max-w-xl lg:max-w-lg">
          <div
            className="section-inner panel"
            data-anime="onview: -100; targets: >*; translateY: [48, 0]; opacity: [0, 1]; easing: spring(1, 80, 10, 0); duration: 450; delay: anime.stagger(100, {start: 100});"
          >
            <div className="panel vstack items-center gap-2 xl:gap-3 mb-4 sm:mb-8 xl:mb-9 sm:max-w-650px xl:max-w-800px mx-auto text-center">
              <h2 className="h3 lg:h2 xl:h1 m-0">
                Future Trends in Real Estate Digital Signage
              </h2>
              {/* <p className="fs-6 xl:fs-5 text-dark dark:text-white text-opacity-70">
                We focus on helping you to make useful content more accessible
                with an utlimate goal for a good sharing profit as a content
                creator.
              </p> */}
            </div>
            <div className="panel vstack gap-4 xl:gap-6">
              <div className="panel px-3 rounded-2 bg-secondary dark:bg-gray-800">
                <ul
                  className="main-features-nav hstack text-center overflow-auto"
                  data-uc-switcher="connect: .main-features-switcher; active: 1; animation: uc-animation-fade;"
                  role="tablist"
                >
                  <li
                    className={`${activeTab == 1 ? "uc-active" : ""}`}
                    onClick={() => setActiveTab(1)}
                    role="presentation"
                  >
                    <a className="h6 lg:h5 text-nowrap m-0">Set Goals and Objectives</a>
                  </li>
                  <li
                    onClick={() => setActiveTab(2)}
                    className={`${activeTab == 2 ? "uc-active" : ""}`}
                    role="presentation"
                  >
                    <a className="h6 lg:h5 text-nowrap m-0">Assess Needs and Choose Tools</a>
                  </li>
                  <li
                    className={`${activeTab == 3 ? "uc-active" : ""}`}
                    onClick={() => setActiveTab(3)}
                    role="presentation"
                  >
                    <a className="h6 lg:h5 text-nowrap m-0">
                    Create Inclusive Content Strategy
                    </a>
                  </li>
                  
                </ul>
              </div>
              <div
                className="main-features-switcher uc-switcher overflow-hidden"
                role="presentation"
                style={{ touchAction: "pan-y pinch-zoom" }}
              >
                {activeTab == 1 && (
                  <div className="uc-active">
                    <div className="feature-item panel">
                      <div className="row child-cols col-match justify-between g-4">
                        <div className="col-12 sm:col-6">
                          <figure className="featured-image m-0 rounded ratio ratio-1x1 rounded-2 overflow-hidden">
                            <img
                              className="media-cover image"
                              alt="Digital signage in school"
                              src="https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/education/school8.png"
                              width="1200"
                              height="1200"
                            />
                          </figure>
                        </div>
                        <div className="col-12 sm:col-6">
                          <div className="panel">
                            <div className="panel vstack justify-center gap-4 h-100 sm:p-3 lg:p-4">
                              <div>
                                <div className="panel vstack gap-2">
                                  {/* <h3 className="h4 sm:h3 lg:h2 m-0">
                                    Share tools quickly and confidently in
                                    minutes
                                  </h3> */}
                                  <p className="fs-6 lg:fs-5 opacity-70 dark:opacity-80">
                                    Implementing digital signage in educational institutions can be a game-changer for effective communication, student engagement, and campus navigation. With the right digital signage software, schools can create a seamless and interactive experience for students, staff, and visitors. The first step is to identify the goals and objectives of your digital signage implementation. Are you looking to improve communication, enhance student engagement, or increase campus safety? Clearly defining your goals will help guide your decisions throughout the process.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {activeTab == 2 && (
                  <div className="uc-active">
                    <div className="feature-item panel">
                      <div className="row child-cols col-match justify-between g-4">
                        <div className="col-12 sm:col-6">
                          <figure className="featured-image m-0 rounded ratio ratio-1x1 rounded-2 overflow-hidden">
                            <img
                              className="media-cover image"
                              alt="Digital signage in school"
                              src="https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/education/school9.png"
                              width="1200"
                              height="1200"
                            />
                          </figure>
                        </div>
                        <div className="col-12 sm:col-6">
                          <div className="panel">
                            <div className="panel vstack justify-center gap-4 h-100 sm:p-3 lg:p-4">
                              <div>
                                <div className="panel vstack gap-2">
                                  <p className="fs-6 lg:fs-5 opacity-70 dark:opacity-80">
                                  Conducting a thorough needs assessment is crucial. Determine the type of digital signage solution required, including the number of screens, display locations, and content requirements. This will ensure that your digital signage meets the specific needs of your institution. Choosing user-friendly, scalable software that integrates with your existing systems and infrastructure is also essential. This will make it easier for staff to manage and update content, ensuring that your digital signage remains effective and relevant.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {activeTab == 3 && (
                  <div className="uc-active">
                    <div className="feature-item panel">
                      <div className="row child-cols col-match justify-between g-4">
                        <div className="col-12 sm:col-6">
                          <figure className="featured-image m-0 rounded ratio ratio-1x1 rounded-2 overflow-hidden">
                            <img
                              className="media-cover image"
                              alt="Digital signage in school"
                              src="https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/education/school10.png"
                              width="1200"
                              height="1200"
                            />
                          </figure>
                        </div>
                        <div className="col-12 sm:col-6">
                          <div className="panel">
                            <div className="panel vstack justify-center gap-4 h-100 sm:p-3 lg:p-4">
                              <div>
                                <div className="panel vstack gap-2">
                                  <p className="fs-6 lg:fs-5 opacity-70 dark:opacity-80">
                                    Developing a content strategy is another key consideration. Your content should be a mix of educational, informative, and engaging material, such as school events, announcements, and emergency alerts. This will keep your audience interested and informed. Additionally, ensure that your digital signage solution is accessible and inclusive for all members of the school community, including those with disabilities. By considering these factors, you can implement a digital signage solution that truly enhances the educational experience. 
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
