import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import MenuBlog1 from '../../images/menuBlog1.png';
import MenuBlog2 from '../../images/menuBlog2.png';
import MenuBlog3 from '../../images/menuBlog3.png';
import MenuBlog4 from '../../images/menuBlog4.png';
import React from 'react';
import RoundArrowButton from '../../images/RoundArrowButton.svg';
import Shop from '../../images/submenushop.png';
import menuIcon1 from '../../images/menuIcon1.png';
import menuIcon2 from '../../images/menuIcon2.png';
import menuIcon3 from '../../images/menuIcon3.png';
import menuIcon4 from '../../images/menuIcon4.png';

const SubMenu = ({ show, onMouseEnter, onMouseLeave }) => {
    return show === 'service' ? (
        <div
            className="absolute left-[15%] w-[75%] border shadow justify-center items-center z-50"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <div className="flex w-full">
                {/* left column */}
                <div className="w-1/2 bg-white pr-16 flex justify-end">
                    <div className="mb-3 grid grid-cols-2 px-6">
                        <p className="text-sm p-2 mt-3 text-slate-500 font-light ">AI DIGITAL SIGNAGE</p>
                        <p className="text-xs p-2 mt-3 text-slate-500 font-light"></p>

                        <Link
                            to="/product/features"
                            className="flex p-2 text-black  items-center"
                            onClick={onMouseLeave}
                        >
                            <img className="w-12 h-12" src={menuIcon1} />
                            <div className="ml-2 ">
                                <h6 className=" font-display font-bold text-black ">
                                    Content Creation & Optimization
                                </h6>
                                <p className="text-xs font-light">Build your content with AI</p>
                            </div>
                        </Link>

                        <Link
                            to="/product/hardware"
                            className="flex p-2 text-black items-center"
                            onClick={onMouseLeave}
                        >
                            <img className="w-12 h-12" src={menuIcon2} />
                            <div className="ml-2 ">
                                <h6 className="font-display font-bold ">Attention Analytics</h6>
                                <p className="text-xs font-light">
                                    Evaluate Your Audience's First Impression of Your Post{' '}
                                </p>
                            </div>
                        </Link>

                        <Link
                            to="/product/howitworks"
                            className="flex p-2 text-black  items-center"
                            onClick={onMouseLeave}
                        >
                            <img className="w-12 h-12 " src={menuIcon3} />
                            <div className="ml-2 ">
                                <h6 className=" font-display text-black font-bold ">AI Assistant & Tools</h6>
                                <p className=" text-xs font-light">
                                    Branches of AI Functions for Effective Utilization{' '}
                                </p>
                            </div>
                        </Link>
                        <Link
                            to="/product/howitworks"
                            className="flex p-2 text-black  items-center"
                            onClick={onMouseLeave}
                        >
                            <img className="w-12 h-12" src={menuIcon1} />
                            <div className="ml-2 text-black ">
                                <h6 className="font-display font-bold ">Interactive Experiences</h6>
                                <p className="text-xs font-light">
                                    Build your repsonvive website with touch display
                                </p>
                            </div>
                        </Link>
                    </div>
                </div>

                {/* right  column */}
                <div className="w-1/2 bg-custom-bg pl-8 justify-start">
                    <p className="text-sm p-2 my-3 text-slate-500 font-light">LOOKING TO BUY HARDWARE?</p>
                    <div className="flex items-center">
                        <img className="w-48 h-24" src={Shop} />
                        <div className="ml-8 flex flex-col justify-between">
                            <h5 className="font-display font-bold">Shop Hardware</h5>
                            <p className="text-slate-600 text-xs w-60 mb-2">
                                Explore our innovative range of digital signage and commercial displays
                            </p>
                            <a
                                href="/contact"
                                className="font-bold font-display text-base text-customPrimary hover:text-cybercast-blue"
                            >
                                Buy here →
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            {/* bottom  */}
            <div className="w-full bg-customBlue h-24 flex justify-center items-center">
                <div className="max-w-6xl w-full flex justify-between items-center px-12">
                    <div>
                        <h6 className="font-display font-semibold">Request a Demo with our AI Specialist</h6>
                        <p className="text-xs ">
                            Learn more about our AI model and get started with SignInspire
                        </p>
                    </div>
                    <a
                        href="link"
                        className="font-bold font-display text-base text-customPrimary hover:text-cybercast-blue"
                    >
                        Contact Us Today →
                    </a>
                </div>
            </div>
        </div>
    ) : (
        <div
            className="absolute left-[15%] w-[75%] border shadow justify-center items-center z-50"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <div className="flex w-full">
                {/* left column */}
                <div className="w-full bg-white pr-12 py-6 flex ">
                    <div className="mb-3 grid grid-cols-1 ml-12">
                        <p className="text-sm text-slate-500 font-light ">
                            CASE STUDIES                           
                        </p>
                        <div className="flex flex-row">
                            <Link
                                to="/solution/real-estate"
                                className="flex items-center text-black"
                                onClick={onMouseLeave}
                            >
                                <img className="w-12 h-12" src={menuIcon1} />
                                <div className="ml-2">
                                    <h6 className=" font-display font-bold">Real Estate</h6>
                                    <p className="text-xs font-light">Streamlined property showcases, real-time updates</p>
                                </div>
                            </Link>
                            <Link
                                to="/solution/healthcare"
                                className="flex items-center text-black"
                                onClick={onMouseLeave}
                            >
                                <img className="w-12 h-12" src={menuIcon2} />
                                <div className="ml-2">
                                    <h6 className="font-display font-bold">Healthcare</h6>
                                    <p className="text-xs font-light">
                                        Smarter communication and scheduling
                                    </p>
                                </div>
                            </Link>
                            <Link
                                to="/solution/restaurant"
                                className="flex items-center text-black"
                                onClick={onMouseLeave}
                            >
                                <img className="w-12 h-12 " src={menuIcon3} />
                                <div className="ml-2">
                                    <h6 className=" font-display font-bold">Restaurant</h6>
                                    <p className=" text-xs font-light">
                                    Personalized dining and optimized service
                                    </p>
                                </div>
                            </Link>
                            <Link
                                to="/solution/education"
                                className="flex items-center text-black"
                                onClick={onMouseLeave}
                            >
                                <img className="w-12 h-12 " src={menuIcon4} />
                                <div className="ml-2">
                                    <h6 className=" font-display font-bold">Education</h6>
                                    <p className=" text-xs font-light">
                                    Innovative tools inspire academic success
                                    </p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>

                {/* right  column */}
                {/* <div className="w-2/3 bg-custom-bg"></div> */}
                {/* <div className='w-2/3 bg-custom-bg'>
                        <div className='max-w-3xl py-6 pl-16'>
                            <div className='mb-3 grid grid-cols-2 gap-3'>
                                <p className='text-sm text-slate-500 font-light'>BLOG POSTS</p>
                                <Link to="/resources/all-blogs" className='ml-auto text-sm hover:text-customPrimary' onClick={onMouseLeave}>
                                    VIEW ALL {`>`}
                                </Link>
                                <div className='flex h-28 pr-2'>
                                    <img className='h-full rounded' src={MenuBlog1} />
                                    <div className='ml-4 '>
                                        <h3 className='font-display font-bold' >How to Get Started</h3>
                                        <p className='hidden lg:block font-light md:text-xs '>Jump right in - get an overview of the basics and get started on building</p>

                                        <a href="link" className="font-bold font-display text-base text-customPrimary hover:text-cybercast-blue">
                                            <img src={RoundArrowButton} />
                                        </a>
                                    </div>
                                </div> <div className='flex h-28 pr-2'>
                                    <img className='h-full rounded' src={MenuBlog2} />
                                    <div className='ml-4 '>
                                        <h3 className='font-display font-bold' >Retail Solutions</h3>
                                        <p className='hidden lg:block font-light text-xs w-48'>Jump right in - get an overview of the basics and get started on building</p>
                                        <a href="link" className="font-bold font-display text-base text-customPrimary hover:text-cybercast-blue">
                                            <img src={RoundArrowButton} />
                                        </a>
                                    </div>
                                </div> <div className='flex h-28 pr-2'>
                                    <img className='h-full rounded' src={MenuBlog3} />
                                    <div className='ml-4'>
                                        <h3 className='font-display font-bold' >Advance Features</h3>
                                        <p className='hidden lg:block font-light text-xs w-48'>Jump right in - get an overview of the basics and get started on building</p>
                                        <a href="link" className="font-bold font-display text-base text-customPrimary hover:text-cybercast-blue">
                                            <img src={RoundArrowButton} />
                                        </a>
                                    </div>
                                </div> <div className='flex h-28 pr-2'>
                                    <img className='h-full rounded' src={MenuBlog4} />
                                    <div className='ml-4 '>
                                        <h3 className='font-display font-bold' >Healthcare apps</h3>
                                        <p className='hidden lg:block font-light text-xs w-48'>Jump right in - get an overview of the basics and get started on building</p>
                                        <a href="link" className="font-bold font-display text-base text-customPrimary hover:text-cybercast-blue">
                                            <img src={RoundArrowButton} />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
            </div>
            {/* bottom  */}
            <div className="w-full bg-customBlue h-24 flex justify-center items-center ">
                <div className="max-w-6xl w-full flex justify-between items-center px-12">
                    <div>
                        <h6 className="font-display font-semibold">Request a Demo with Sign Inspire</h6>
                        <p className="text-xs">
                            Learn more about our Solutions and get started with Sign Inspire
                        </p>
                    </div>
                    <a
                        href="/contact"
                        className="font-bold font-display text-base text-customPrimary hover:text-cybercast-blue"
                    >
                        Contact Us Today →
                    </a>
                </div>
            </div>
        </div>
    );
};

export default SubMenu;
