import React from "react";

export default function About3() {
  return (
    <div
      id="about_us"
      className="about-us section panel overflow-hidden"
      data-anime="onview: -100; opacity: [0, 1]; easing: spring(1, 80, 10, 0); duration: 450; delay: 250;"
    >
      <div className="section-outer panel py-6 xl:py-9 bg-secondary dark:bg-gray-800">
        <div
          className="d-none lg:d-block"
          data-anime="onview: -100; targets: img; scale: [0.8, 1]; opacity: [0, 1]; easing: spring(1, 80, 10, 0); duration: 450; delay: 350;"
        >
          <div className="position-absolute bottom-0 start-0 ms-n8 mb-6">
            <img
              className="w-150px xl:w-250px d-block dark:d-none"
              alt="walking"
              src="/images/template/walking.svg"
              width="224"
              height="226"
            />
            <img
              className="w-150px xl:w-250px d-none dark:d-block"
              alt="walking-dark"
              src="/images/template/walking-dark.svg"
              width="224"
              height="227"
            />
          </div>
        </div>
        <div className="container max-w-lg">
          <div className="section-inner panel">
            <div
              className="panel vstack text-center"
              data-anime="onview: -100; targets: >*; translateY: [48, 0]; opacity: [0, 1]; easing: spring(1, 80, 10, 0); duration: 450; delay: anime.stagger(100, {start: 200});"
            >
              <h2 className="h3 lg:h2 mb-4">Getting Started with Digital Signage</h2>
              <p className="fs-5 xl:fs-4 text-dark dark:text-white">
              Sign Inspire offers a digital signage platform that enhances the dining experience with an easy-to-use interface, an intuitive user design, and the latest in AI-driven design technology. With digital displays like digital menu boards, interactive touchscreens, and video walls, restaurants can provide an engaging experience that captures customers' attention and promotes special items effectively.
              </p> 
              <p className="fs-5 xl:fs-4 text-dark dark:text-white">
              Sign Inspire provides a content management service with digital media players, making it simple to manage and scale signage across locations. For restaurants, there are customizable display options to meet various business needs, offering flexible and dynamic ways to connect with guests and enhance the overall experience.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
