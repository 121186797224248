
// 此处更新case-studies页面的博文列表（按照格式给对象添加属性即可）
// 当前设置：此对象的第一个属性就是页面中高亮显示的博文
export const blogsPosts4 = [
  {
    id: "real-estate",
    category: "Strategy",
    imgSrc: "https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/real_estate/real3.png",
    imgAlt: "The Best Real Estate Digital Signage Solutions",
    imgCaption: "The Best Real Estate Digital Signage Solutions",
    title: "The Best Real Estate Digital Signage Solutions",
    desc: "Real estate digital signage enhances property visibility and client engagement with dynamic content and real-time updates...",
    date: "07/12/2024",
  },
  {
    id: "healthcare",
    category: "Strategy",
    imgSrc: "https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/health_care/h1.png",
    imgAlt: "Digital Signage for Healthcare",
    imgCaption: "Digital Signage for Healthcare",
    title: "Top Benefits of Digital Signage for Healthcare Facilities",
    date: "11/12/2024",
  },
  {
    id: "restaurant",
    category: "Strategy",
    imgSrc: "https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/restaurant/new.jpg",
    imgAlt: "Restaurant blog",
    imgCaption: "Digital Signage Content Ideas for Restaurants",
    title: "Creative Digital Signage Content Ideas for Restaurants to Boost Sales",
    date: "17/12/2024",
  },
  {
    id: "education",
    category: "Strategy",
    imgSrc: "https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/education/hero.png",
    imgAlt: "The Impact of Education Digital Signage",
    imgCaption: "The Impact of Education Digital Signage",
    title: "Transforming Learning Spaces: The Impact of Education Digital Signage",
    date: "24/12/2024",
  },
];


export const allBlogs = [
  ...blogsPosts4,
];
