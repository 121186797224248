import React from "react";

export default function White() {
  return (
    <div
      id="main_features"
      className="key-features section panel overflow-hidden"
    >
      <div className="section-outer panel py-6 xl:py-9">
        <div className="container sm:max-w-lg xl:max-w-xl">
          <div className="section-inner panel">
            <div
              className="panel vstack items-center gap-2 xl:gap-3 mb-4 sm:mb-8 xl:mb-9 max-w-900px mx-auto"
              data-anime="targets: >*; translateY: [48, 0]; opacity: [0, 1]; easing: spring(1, 80, 10, 0); duration: 450; delay: anime.stagger(100, {start: 200});"
            >
              <h2 className="h3 lg:h2 xl:h1 m-0 text-center">
                Transforming Learning Spaces
              </h2>
              <p className="fs-6 xl:fs-5 text-dark dark:text-white">
                Imagine walking into a school where classrooms, hallways, and libraries come alive with vibrant, interactive displays. Instead of cluttered bulletin boards or outdated posters, you see real-time announcements, engaging visuals, and tools that make learning dynamic and exciting. That's the power of digital signage in education with commercial displays.
              </p>
              <p className="fs-6 xl:fs-5 text-dark dark:text-white">
                Whether it's guiding students with digital signage solutions in a sprawling campus or enhancing engagement with touch-enabled displays, this tech is reshaping how schools communicate and inspire. It's not just about looking modern—it's about creating smarter, more connected learning spaces that truly benefit students and staff alike.
              </p>
              <p className="fs-6 xl:fs-5 text-dark dark:text-white">
                Curious how it works and what makes it so impactful? Stick around as we dive into real-world examples, benefits, and tips to bring this transformation to life.
              </p>
              <p className="fs-6 xl:fs-5 text-dark dark:text-white">
                <span style={{fontWeight:700}}>Tl;DR: </span>Digital signage is revolutionizing education, turning traditional spaces into dynamic hubs of engagement and information sharing. Ready to transform your campus? Let's explore how.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
