import React from "react";

export default function Features2() {
  return (
    <div id="features" className="features section panel  scrollSpysection">
      <div className="section-outer panel pt-6 lg:pt-8 xl:pt-10">
        <div className="container xl:max-w-xl">
          <div className="section-inner panel">
            <div
              className="panel vstack items-center gap-2 xl:gap-3 mb-4 lg:mb-8  mx-auto "
              data-anime="onview: -100; targets: >*; translateY: [48, 0]; opacity: [0, 1]; easing: easeOutCubic; duration: 500; delay: anime.stagger(100, {start: 200});"
            >
              {/* <div className="cstack gap-1 py-1 px-2 border rounded-pill">
                <span className="d-inline-block w-4px h-4px rounded-circle bg-primary dark:bg-secondary" />
                <span className="fs-8 fw-bold text-uppercase">
                  Main features
                </span>
              </div> */}
              <h2 className="h3 lg:h2 xl:h1 m-0 px-2 max-w-650px text-center">
                How to Implement{" "}
                <span className="d-inline-flex px-1 bg-secondary text-primary -rotate-1 lg:-rotate-2 rounded-1 lg:rounded-1-5">
                Digital Signage
                </span>{" "}
                in Your Real Estate Business
              </h2>
              <p className="fs-6 xl:fs-5 text-black dark:text-white max-w-950px">
                Implementing digital signage in a real estate business involves key steps, including selecting the right hardware, managing digital content, and ensuring proper installation and maintenance. Successful implementation requires assessing the business's specific needs and choosing appropriate technology that aligns with marketing goals.
              </p>
              <p className="fs-6 xl:fs-5 text-black dark:text-white max-w-950px">
                Following a structured approach helps real estate agents integrate digital displays into their marketing strategy, enhancing property visibility and client engagement. The following subsections provide detailed guidance on selecting hardware, managing content, and installing and maintaining digital signage.
              </p>
            </div>
            <div className="row child-cols-12 lg:child-cols-5 col-match g-2">
              <div className="lg:col-7">
                <div
                  className="panel overflow-hidden bg-secondary text-gray-900 dark:bg-gray-800 dark:text-white rounded-2 lg:rounded-3"
                  data-anime="onview: -100; translateY: [80, 0]; opacity: [0, 1]; easing: easeOutCubic; duration: 500; delay: 0;"
                >
                  <div
                    className="panel vstack items-start gap-2 p-3 lg:p-4 xl:p-6"
                    data-anime="onview: -100; targets: >*; translateY: [16, 0]; opacity: [0, 1]; easing: easeOutCubic; duration: 500; delay: anime.stagger(100, {start: 500});"
                  >
                    <h4 className="h4 m-0">• Selecting the Right Hardware</h4>
                    {/* <a href="#" className="btn btn-sm btn-primary px-2 mt-2">
                      <span>Try it now</span>
                      <i className="icon icon-narrow unicon-arrow-right fw-bold rtl:rotate-180" />
                    </a> */}
                    <p className="fs-6 lg:fs-5 text-black dark:text-white">
                      Choosing the right hardware is crucial for effective digital signage in real estate offices. Professional displays, designed for longer operational hours and greater durability compared to consumer TVs, are suitable for continuous use in busy environments. Commercial-grade media players, built for extended use, feature stronger materials and higher processing capabilities to ensure reliable performance.
                    </p>
                  </div>
                  <div className="panel ltr:ps-3 ltr:lg:ps-4 ltr:xl:ps-6 rtl:pe-3 rtl:lg:pe-4 rtl:xl:pe-6">
                    <img
                      className="ltr:rounded-top-start-1-5 rtl:rounded-top-end-1-5"
                      alt="Real-estate"
                      src="https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/real_estate/e6.png"
                      width="1280"
                      height="837"
                    />
                  </div>
                </div>
              </div>
              <div>
                <div
                  className="panel vstack items-start overflow-hidden bg-primary-700 rounded-2 lg:rounded-3 uc-dark"
                  data-anime="onview: -100; translateY: [80, 0]; opacity: [0, 1]; easing: easeOutCubic; duration: 500; delay: 100;"
                >
                  <div
                    className="position-cover opacity-70 bg-cover"
                    style={{ backgroundPosition: "50% 85%" }}
                    data-src="/assets/images/template/feature-06-bg-masked.png"
                    data-uc-img=""
                  />
                  <div className="position-cover bg-gradient-to-t from-gray-800 via-transparent to-gray-900" />
                  <div className="position-absolute d-inline-block w-500px h-500px rounded-circle bg-gradient-45 from-primary to-white start-50 blur-10 translate-middle blend-soft-light" />
                  <div className="panel p-3">
                    <img
                      className="rounded-bottom-1-5 lg:rounded-bottom-3"
                      alt="Real-estate"
                      src="https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/real_estate/r5.png"
                      width="664"
                      height="496"
                    />
                  </div>
                  <div
                    className="panel vstack items-start justify-between gap-2 p-3 lg:p-4 xl:p-6 pt-0 lg:pt-0 xl:pt-0"
                    data-anime="onview: -100; targets: >*; translateY: [16, 0]; opacity: [0, 1]; easing: easeOutCubic; duration: 500; delay: anime.stagger(100, {start: 500});"
                  >
                    <div className="content vstack items-start gap-2">
                      <h4 className="h4 m-0">• Selecting the Right Hardware</h4>
                      <p className="fs-6 lg:fs-5 dark:text-white">
                        When choosing digital signage hardware, consider factors like durability, usability, and performance. Devices like the Amazon Fire TV Stick ensure a clean setup without visible wires, offering a sleek and professional appearance.
                      </p>
                      <p className="fs-6 lg:fs-5 dark:text-white">
                        Selecting the right hardware helps real estate agencies create a robust digital signage system meeting their specific needs.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div
                  className="panel vstack items-start overflow-hidden bg-gray-800 rounded-2 lg:rounded-3 uc-dark"
                  data-anime="onview: -100; translateY: [80, 0]; opacity: [0, 1]; easing: easeOutCubic; duration: 500; delay: 200;"
                >
                  <div
                    className="position-cover opacity-70 bg-cover"
                    style={{ backgroundPosition: "50% 85%" }}
                    data-src="/assets/images/template/feature-06-bg-masked-2.png"
                    data-uc-img=""
                  />
                  <div className="position-cover bg-gradient-to-t from-gray-800 via-transparent to-gray-900" />
                  <div className="position-absolute d-inline-block w-500px h-500px rounded-circle bg-gradient-45 from-primary to-white start-50 blur-10 translate-middle blend-soft-light" />
                  <div className="panel px-3 lg:px-4 xl:px-6">
                    <img
                      className="rounded-bottom-1-5 border border-top-0"
                      alt="Real-estate"
                      src="https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/real_estate/r6.png"
                      width="800"
                      height="620"
                    />
                  </div>
                  <div
                    className="panel vstack items-start justify-between gap-2 p-3 lg:p-4 xl:p-6"
                    data-anime="onview: -100; targets: >*; translateY: [16, 0]; opacity: [0, 1]; easing: easeOutCubic; duration: 500; delay: anime.stagger(100, {start: 500});"
                  >
                    <div className="content vstack items-start gap-2">
                      <h4 className="h4 m-0">• Managing Digital Content</h4>
                      <p className="fs-6 lg:fs-5 dark:text-white">
                        Managing digital content is essential for maintaining an effective digital signage system. Updating digital signs is straightforward, allowing realtors to quickly provide the most current information. Digital signage systems manage content across multiple locations, ensuring targeted information reaches the right audience.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="lg:col-7">
                <div
                  className="panel vstack items-start overflow-hidden bg-secondary text-gray-900 dark:bg-gray-800 dark:text-white rounded-2 lg:rounded-3"
                  data-anime="onview: -100; translateY: [80, 0]; opacity: [0, 1]; easing: easeOutCubic; duration: 500; delay: 300;"
                >
                  <div
                    className="panel vstack items-center gap-2 p-3 lg:p-4 xl:p-6"
                    data-anime="onview: -100; targets: >*; translateY: [16, 0]; opacity: [0, 1]; easing: easeOutCubic; duration: 500; delay: anime.stagger(100, {start: 500});"
                  >
                    <h4 className="h4 m-0">• Managing Digital Content</h4>
                    <p className="fs-6 md:fs-5 lg:fs-4 m-0 xl:px-4 text-center">
                      Delivering graphics or video to digital signage displays requires a content management platform and a display device. Keeping digital signage content updated is crucial for maintaining relevance and engaging the audience, making it integral to the overall marketing strategy.
                    </p>
                  </div>
                  <div className="panel px-3 lg:px-4 xl:px-6 mb-2 lg:mb-5">
                    <img
                      alt="Real-estate"
                      src="https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/real_estate/real8.png"
                      width="1280"
                      height="800"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
