import { AppBar, Backdrop, Box, Divider, Drawer, Fab, Fade, Toolbar } from '@mui/material';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
import { AiOutlineClose } from 'react-icons/ai';
import { AiOutlineMenu } from 'react-icons/ai';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { KeyboardArrowUp } from '@mui/icons-material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Link as MUILink } from '@mui/material';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { Profile } from '../../pages';
import ScrollToTop from './ScrollToTop';
import SubMenu from './SubMenu';
import Tooltip from '@mui/material/Tooltip';
import avatar from '../../data/avatar.jpg';
import cybercastLogo from '../../data/CYBERCAST_BLUE.png';
import logo1 from '../../images/logo1.png';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { useStateContext } from '../../contexts/ContextProvider';
import { useTheme } from '@mui/material/styles';

const NavButton = ({ title, customFunc, icon, color, onMouseEnter, onMouseLeave, page }) => (
    <Button
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onMouseLeave}
        component={Link}
        to={`../${page}`}
        // type='button'
        // onClick={customFunc ? () => customFunc() : () => null}
        style={{ color }}
        className="relative flex items-center text-gray-700 hover:bg-slate-100 hover:rounded-md py-3"
    >
        {/* <span
					style={{ background: dotColor }}
					className='absolute inline-flex rounded-full h-2 w-2 right-2 top-2'
				/> */}
        <span className="font-display text-sm font-semibold mx-3">{title === 'Menu' ? '' : title}</span>
        {icon}
    </Button>
);

function ScrollTop(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
        disableHysteresis: true,
        threshold: 100,
    });

    return (
        <Fade in={trigger} timeout={1000} className="z-50">
            <Box role="presentation" sx={{ position: 'fixed', bottom: 16, right: 16 }}>
                {children}
            </Box>
        </Fade>
    );
}

const WebNavbar = () => {
    const {
        currentColor,
        currentUser,
        activeMenu,
        setActiveMenu,
        handleClick,
        isClicked,
        setScreenSize,
        screenSize,
        setShowSidebar,
        showSidebar,
    } = useStateContext();
    const navigate = useNavigate();
    // const { pathname } = useLocation();

    // useEffect(() => {
    //   window.scrollTo(0, 0);
    // }, [pathname]);

    const toggleDrawer = (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setShowSidebar(!showSidebar);
    };
    const handleRedrectToDashboard = () => {
        console.log(
            'file: WebNavbar.jsx:87 => handleRedrectToDashboard => handleRedrectToDashboard:',
            handleRedrectToDashboard,
        );
        window.location.href = './dashboard';
    };
    const WebSideBar = () => {
        const pages = ['', 'contact', 'login'];
        return (
            <>
                <Box className="w-full pt-16">
                    <List>
                        {['Home', 'Contact Us', 'Login', 'Dashboard']
                            .filter((x) => (currentUser ? x !== 'Login' : x !== 'Dashboard'))
                            .map((text, index) => (
                                // <Link to={`../${pages[index]}`} className='no-underline' key={text}>
                                <ListItem divider>
                                    {text === 'Dashboard' ? (
                                        <Button
                                            className="relative text-2xl rounded-full p-3 hover:bg-light-gray"
                                            // title={text}
                                            style={{ currentColor, whiteSpace: 'nowrap' }}
                                            onClick={handleRedrectToDashboard}
                                        >
                                            {text}
                                        </Button>
                                    ) : (
                                        <NavButton title={text} color={currentColor} page={pages[index]} />
                                    )}
                                </ListItem>
                                // </Link>
                            ))}
                    </List>
                </Box>
            </>
        );
    };

    useEffect(() => {
        const handleResize = () => setScreenSize(window.innerWidth);

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // // useEffect(() => {
    // // 	if (screenSize >= 768) {
    // // 		setShowSidebarButton(false);
    // // 	} else {
    // // 		setShowSidebarButton(true);
    // // 	}
    // }, [screenSize]);

    // const handleActiveMenu = () => setActiveMenu(!activeMenu);
    // const handleProfile = () => navigate('/profile');
    const theme = useTheme();
    const [showResourceSubMenu, setShowResourceSubMenu] = useState(false);
    const [showProductSubMenu, setShowProductSubMenu] = useState(false);

    let timer;
    const handleMouseLeave = () => {
        timer = setTimeout(() => {
            setShowProductSubMenu(false);
            setShowResourceSubMenu(false);
        }, 200);
    };
    const handleMouseEnterProduct = () => {
        clearTimeout(timer);
        setShowResourceSubMenu(false);
        setShowProductSubMenu(true);
    };
    const handleMouseEnterResources = () => {
        clearTimeout(timer);
        setShowProductSubMenu(false);
        setShowResourceSubMenu(true);
    };

    return (
        <>
            <ScrollToTop />
            <div className="bg-white h-20 border-b border-gray-300 w-full fixed top-0 z-50 overflow-visible relative">
                <div className="max-w-6xl mx-auto h-full flex items-center justify-between">
                    <Link
                        to="/"
                        className="text-gray-700 text-sm font-bold leading-relaxed inline-block py-[8px] whitespace-nowrap uppercase"
                        style={{marginLeft: '-20px'}}
                        
                    >
                        <img 
                            src="https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/MainPage/wide_logo.jpg" 
                            alt="Sign Inspire" 
                            className="h-16 w-auto"
                            style={{hover: 'pointer',}}
                        />
                    </Link>

                    {/* For larger screens */}
                    <div className="hidden md:flex justify-center items-center overflow-visible">
                        {/* <button className="flex items-center  text-gray-700 px-[12px] py-[16px] lg:py-[8px] text-xs uppercase font-bold hover:bg-slate-100 hover:rounded-md">
                            home
                        </button>
                        <button className="flex items-center  text-gray-700 px-[12px] py-[16px] lg:py-[8px] text-xs uppercase font-bold hover:bg-slate-100 hover:rounded-md">
                            installation
                        </button>
                        <button className="flex items-center  text-gray-700 px-[12px] py-[16px] lg:py-[8px] text-xs uppercase font-bold hover:bg-slate-100 hover:rounded-md">
                            Pricing
                        </button>
                        <button className="flex items-center  text-gray-700 px-[12px] py-[16px] lg:py-[8px] text-xs uppercase font-bold hover:bg-slate-100 hover:rounded-md">
                            Contact us
                        </button>
                        <button className="flex items-center  text-gray-700 px-[12px] py-[16px] lg:py-[8px] text-xs uppercase font-bold hover:bg-slate-100 hover:rounded-md">
                            Attention test
                        </button> */}
                        {/* <NavButton title="home" page="/" /> */}
                        {/* <NavButton
                            title="service"
                            page="/"
                            onMouseEnter={handleMouseEnterProduct}
                            onMouseLeave={handleMouseLeave}
                            onClick={handleMouseLeave}
                        /> */}
                        <NavButton
                            title="use case"
                            page="/solution"
                            onMouseEnter={handleMouseEnterResources}
                            onMouseLeave={handleMouseLeave}
                            onClick={handleMouseLeave}
                        />
                        {/* <NavButton title="Home" page="/" /> */}
                        <NavButton title="Download" page="/download" />
                        <NavButton title="Pricing" color="black" page="/pricing" />
                        <NavButton title="Contact us" color="black" page="/contact" />
                         <NavButton title="Attention test" color="black" page="/ad-attention-test" /> 
                    </div>

                    <div className="hidden md:flex items-center ml-auto space-x-4">
                        {currentUser ? (
                            // <a
                            //     href="/web/dashboard"
                            //     className="mx-4 font-bold font-display text-sm text-customPrimary hover:text-cybercast-blue"
                            // >
                            //     Dashboard
                            // </a>
                            <Button
                                size="large"
                                variant="contained"
                                href="./dashboard"
                                style={{
                                    backgroundColor: theme.palette.primary.main,
                                    whiteSpace: 'nowrap',
                                    // color: '#ffffff',
                                    fontWeight: 500,
                                    padding: '20px 32px',
                                }}
                            >
                                Dashboard
                            </Button>
                        ) : (
                            // <div className="hidden md:flex items-center ml-auto space-x-4">
                            <>
                                <div className="flex items-center border border-blue-500 mr-4 rounded-md">
                                    <button
                                        className=" hover:bg-slate-100 text-gray-700 px-[12px] py-[16px] lg:py-[8px] flex items-center uppercase font-display text-sm font-semibold"
                                        onClick={() => navigate('/login')}
                                    >
                                        Log in
                                    </button>
                                </div>
                                <div className="flex items-center border border-blue-500 bg-blue-500 rounded-md hover:bg-blue-600 uppercase">
                                    <button
                                        className="  px-[12px] py-[16px] lg:py-[8px] flex items-center font-display text-sm font-semibold uppercase text-white"
                                        onClick={() => navigate('/signup')}
                                    >
                                        sign up
                                    </button>
                                </div>
                                {/* <a
                                    href="./login"
                                    className="mx-4 font-bold font-display text-sm text-customPrimary hover:text-cybercast-blue"
                                >
                                    Login
                                </a>
                                <Button
                                    size="large"
                                    variant="contained"
                                    href="./signup"
                                    style={{
                                        backgroundColor: theme.palette.primary.main,
                                        whiteSpace: 'nowrap',
                                        color: '#ffffff',
                                        fontWeight: 500,
                                        padding: '20px 32px',
                                    }}
                                >
                                    Sign up
                                </Button> */}
                            </>
                        )}{' '}
                    </div>

                    {/* For mobile screens */}
                    <div className="md:hidden">
                        <button onClick={() => setShowSidebar(!showSidebar)}>
                            <AiOutlineMenu className="text-2xl mr-2" />
                        </button>
                        {showSidebar && (
                            <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-end">
                                <div className="bg-white w-64 h-full flex flex-col p-4 space-y-4">
                                    <NavButton title="Home" color="black" page="/" />
                                    <NavButton
                                        title="Use Case"
                                        color="black"
                                        page="/solution"
                                    />
                                    <NavButton title="Download" color="black" page="/download" />
                                    <NavButton title="Pricing" color="black" page="/pricing" />
                                    <NavButton title="Contact" color="black" page="/contact" />
                                    <NavButton title="Login" color="black" page="/login" />
                                    <button
                                        onClick={() => setShowSidebar(false)}
                                        className="absolute top-4 right-4"
                                    >
                                        {' '}
                                        <AiOutlineClose size={24} />
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {showProductSubMenu && (
                    <SubMenu
                        show="service"
                        onMouseEnter={handleMouseEnterProduct}
                        onMouseLeave={handleMouseLeave}
                    />
                )}
                {showResourceSubMenu && (
                    <SubMenu
                        show="Resource"
                        onMouseEnter={handleMouseEnterResources}
                        onMouseLeave={handleMouseLeave}
                    />
                )}
            </div>
            <ScrollTop>
                <Fab
                    size="small"
                    aria-label="scroll back to top"
                    onClick={() => {
                        window.scrollTo(0, 0);
                    }}
                >
                    <KeyboardArrowUp />
                </Fab>
            </ScrollTop>
            <Outlet />
        </>
    );
};

export default WebNavbar;
