import React from "react";

export default function KeyFeatures() {
  return (
    <div
      id="key_features"
      className="key-features section panel overflow-hidden bg-gray-900 uc-dark"
    >
      <div className="section-outer panel py-6 xl:py-9 dark:bg-gray-800">
        <div className="container sm:max-w-md lg:max-w-lg">
          <div className="section-inner panel">
            <div className="panel vstack gap-4 sm:gap-6 xl:gap-8">
              <h2
                className="title h3 lg:h2 xl:h1 m-0 text-center max-w-550px mx-auto"
                data-anime="onview: -100; translateY: [48, 0]; opacity: [0, 1]; easing: spring(1, 80, 10, 0); duration: 450; delay: 250;"
              >
                <span className="text-primary">Summary</span>
              </h2>
              <p className="desc fs-6 dark:text-white">
              In conclusion, digital signage offers a plethora of benefits for real estate agencies, from enhancing property visibility and client engagement to promoting sustainable marketing practices. By leveraging high-brightness window displays, video walls, and interactive kiosks, real estate businesses can create dynamic and immersive experiences that attract potential buyers and drive sales. Successful implementations, as seen in Sydney and New South Wales, demonstrate the transformative impact of digital signage in modern real estate marketing.
              </p>
              <p className="desc fs-6 dark:text-white">
              Looking ahead, future trends such as AI integration and augmented reality will further revolutionize the real estate industry, providing personalized and interactive experiences for clients. By staying ahead of these technological advancements, real estate agencies can maintain a competitive edge and continue to thrive in an ever-evolving market. Embracing digital signage is not just about keeping up with trends—it's about leading the way in innovative and effective property marketing.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
