import { Box, Container, Grid, IconButton, Link, Typography } from '@mui/material';
import { Facebook, Instagram, LinkedIn, Twitter } from '@mui/icons-material';

import React from 'react';
import config from '../config/config';

//const RDS_URL = config[process.env.REACT_APP_ENV].REACT_APP_RDS_API_URL;
//const redirect_base_url = config[process.env.REACT_APP_ENV].REACT_APP_MAIN_PAGE_URL_HEAD;

const installation_url = '/download';
const pricing_url = '/pricing';
const contact_url = '/contact';

const WebFooter = () => {
    return (
        <div className="pb-6 pt-12 border-t-1">
            <div className="container mx-auto">
                <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                    {/* First Column */}
                    <div>
                        <h6 className="text-lg font-semibold mb-4">AI Leading Digital Signage Platform</h6>
                        <a href="/" target="_blank" rel="noopener">
                            <img
                                src="https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/MainPage/icon_app.png"
                                alt="App Icon"
                                className="w-24 h-24"
                            />
                        </a>
                    </div>

                    {/* Second Column */}
                    <div>
                        <h6 className="text-lg font-semibold mb-4">Quick Links</h6>
                        <ul className="pl-0">
                            <li className="pb-2">
                                <a
                                    href="/"
                                    className="text-slate-600 hover:text-slate-800 font-semibold text-sm"
                                >
                                    Home
                                </a>
                            </li>
                            <li className="pb-2">
                                <a
                                    href={installation_url}
                                    className="text-slate-600 hover:text-slate-800 font-semibold text-sm"
                                >
                                    Download
                                </a>
                            </li>
                            <li className="pb-2">
                                <a
                                    href={pricing_url}
                                    className="text-slate-600 hover:text-slate-800 font-semibold text-sm"
                                >
                                    Pricing
                                </a>
                            </li>
                            <li className="pb-2">
                                <a
                                    href={contact_url}
                                    className="text-slate-600 hover:text-slate-800 font-semibold text-sm"
                                >
                                    Contact
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div>
                        <h6 className="text-lg font-semibold mb-4">Solutions</h6>
                        <ul className="pl-0">
                            <li className="pb-2">
                                <a
                                    href="/solution/healthcare"
                                    className="text-slate-600 hover:text-slate-800 font-semibold text-sm"
                                >
                                    Healthcare
                                </a>
                            </li>
                            <li className="pb-2">
                                <a
                                    href="/solution/real-estate"
                                    className="text-slate-600 hover:text-slate-800 font-semibold text-sm"
                                >
                                    Real Estate
                                </a>
                            </li>
                            <li className="pb-2">
                                <a
                                    href="/solution/restaurant"
                                    className="text-slate-600 hover:text-slate-800 font-semibold text-sm"
                                >
                                    Restaurant
                                </a>
                            </li>
                            <li className="pb-2">
                                <a
                                    href="/solution/education"
                                    className="text-slate-600 hover:text-slate-800 font-semibold text-sm"
                                >
                                    Education
                                </a>
                            </li>
                        </ul>
                    </div>

                    {/* Third Column */}
                    <div>
                        <h6 className="text-lg font-semibold mb-4">Follow Us</h6>
                        <div className="flex space-x-2">
                            <a
                                href="https://twitter.com"
                                className="bg-white text-sky-400 shadow-lg font-normal h-10 w-10 flex items-center justify-center rounded-full"
                            >
                                <i className="fab fa-twitter"></i>
                            </a>
                            <a
                                href="https://facebook.com"
                                className="bg-white text-blue-600 shadow-lg font-normal h-10 w-10 flex items-center justify-center rounded-full"
                            >
                                <i className="fab fa-facebook-square"></i>
                            </a>
                            <a
                                href="https://linkedin.com"
                                className="bg-white text-blue-400 shadow-lg font-normal h-10 w-10 flex items-center justify-center rounded-full"
                            >
                                <i className="fab fa-linkedin"></i>
                            </a>
                            <a
                                href="https://instagram.com"
                                className="bg-white text-pink-500 shadow-lg font-normal h-10 w-10 flex items-center justify-center rounded-full"
                            >
                                <i className="fab fa-instagram"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="mt-4 text-center">
                    <p className="text-sm text-gray-500">
                        &copy; {new Date().getFullYear()} Sign Inspire. All rights reserved.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default WebFooter;
