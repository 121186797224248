import React from 'react';

const Footer = () => (
    <div>
        <p className="dark:text-gray-200 text-gray-700 text-center mb-4">
            © 2025 All rights reserved by Sign Inspire
        </p>
    </div>
);

export default Footer;
