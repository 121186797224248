// import { features6 } from "./../data/features";
import React from "react";

export default function Section() {
  return (
    <div id="hero_header" className="hero-header section panel overflow-hidden">
      <div
        className="position-absolute top-0 start-0 end-0 min-h-screen overflow-hidden d-none lg:d-block"
        data-anime="targets: >*; scale: [0, 1]; opacity: [0, 1]; easing: spring(1, 80, 10, 0); duration: 450; delay: anime.stagger(100, {start: 750});"
      >
        <div
          className="position-absolute rotate-45"
          style={{
            top: "30%",
            left: "18%",
          }}
        >
          <img
            className="w-32px text-gray-900 dark:text-white"
            src="/images/template/star-1.svg"
            width={193}
            height={216}
            alt="star-1"
            data-uc-svg=""
          />
        </div>
        <div
          className="position-absolute rotate-45"
          style={{ top: "15%", right: "18%" }}
        >
          <img
            className="w-24px text-gray-900 dark:text-white"
            src="/images/template/star-2.svg"
            width={69}
            height={95}
            alt="star-2"
            data-uc-svg=""
          />
        </div>
      </div>
      <div className="section-outer panel pt-9 lg:pt-10 pb-6 xl:pb-9">
        <div className="container max-w-lg">
          <div className="section-inner panel mt-2 sm:mt-4 lg:mt-0">
            <div
              className="panel vstack items-center gap-3 lg:gap-4 mb-6 sm:mb-8 lg:mb-9 max-w-650px mx-auto text-center"
              data-anime="targets: >*; translateY: [48, 0]; opacity: [0, 1]; easing: spring(1, 80, 10, 0); duration: 450; delay: anime.stagger(100, {start: 200});"
            >
              <h1 className="h2 sm:h1 lg:display-6 xl:display-5 m-0">
                Conclusion
              </h1>
            </div>
            {/* 无图的section */}
            <div className="sticky-scene panel vstack gap-4 sm:gap-6 xl:gap-8">
                <div
                  className="feature-item panel px-3 lg:px-4 py-4 rounded-2 bg-secondary dark:bg-gray-800"
                  data-anime="onview: -200; translateY: [48, 0]; opacity: [0, 1]; easing: spring(1, 80, 10, 0); duration: 450; delay: 100;"
                >
                  <div className="panel vstack justify-center gap-4 h-100">
                      <div className="panel vstack gap-2">
                        {/* <h3 className="h4 lg:h2 m-0">• Instant Property Listings Updates</h3> */}
                        <p className="fs-6 lg:fs-5 dark:opacity-80">
                        Digital signage is a game-changing tool for transforming learning spaces and enhancing communication in educational institutions. It offers a modern, efficient way to share information, engage students, and create a more connected school environment.
                        </p>
                      </div>
                  </div>
                </div>
            </div>

            <div className="sticky-scene panel vstack gap-4 sm:gap-6 xl:gap-8 mt-8">
                <div
                  className="feature-item panel px-3 lg:px-4 py-4 rounded-2 bg-secondary dark:bg-gray-800"
                  data-anime="onview: -200; translateY: [48, 0]; opacity: [0, 1]; easing: spring(1, 80, 10, 0); duration: 450; delay: 100;"
                >
                  <div className="panel vstack justify-center gap-4 h-100">
                      <div className="panel vstack gap-2">
                        <p className="fs-6 lg:fs-5 dark:opacity-80">
                        By selecting the right digital signage software, schools can easily manage and update their displays. Combining this with effective content strategies—like showcasing events, announcements, and student achievements—can boost student motivation and improve the overall learning experience.
                        </p>
                      </div>
                  </div>
                </div>
            </div>

            <div className="sticky-scene panel vstack gap-4 sm:gap-6 xl:gap-8 mt-8">
                <div
                  className="feature-item panel px-3 lg:px-4 py-4 rounded-2 bg-secondary dark:bg-gray-800"
                  data-anime="onview: -200; translateY: [48, 0]; opacity: [0, 1]; easing: spring(1, 80, 10, 0); duration: 450; delay: 100;"
                >
                  <div className="panel vstack justify-center gap-4 h-100">
                      <div className="panel vstack gap-2">
                        <p className="fs-6 lg:fs-5 dark:opacity-80">
                        Measuring the impact of digital signage is just as important. Schools can use data and feedback to refine their content strategies, ensuring their digital displays stay relevant and engaging. Regular updates and adjustments help schools get the most out of their digital signage solutions.
                        </p>
                      </div>
                  </div>
                </div>
            </div>

            <div className="sticky-scene panel vstack gap-4 sm:gap-6 xl:gap-8 mt-8">
                <div
                  className="feature-item panel px-3 lg:px-4 py-4 rounded-2 bg-secondary dark:bg-gray-800"
                  data-anime="onview: -200; translateY: [48, 0]; opacity: [0, 1]; easing: spring(1, 80, 10, 0); duration: 450; delay: 100;"
                >
                  <div className="panel vstack justify-center gap-4 h-100">
                      <div className="panel vstack gap-2">
                        <p className="fs-6 lg:fs-5 dark:opacity-80">
                        With the right approach, digital signage can be a powerful tool to achieve educational goals, making schools not just places for learning but vibrant hubs of communication and creativity.
                        </p>
                      </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
