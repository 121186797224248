export const accordionItems = [
  {
    id: 1,
    question: "• How does digital signage enhance property visibility?",
    answer:
      "Digital signage enhances property visibility by displaying engaging videos and images that capture the attention of potential buyers. This dynamic content effectively draws interest and increases awareness of the property.",
    controls: "uc-accordion-24",
    expanded: true,
  },
  {
    id: 2,
    question: "• What are the benefits of using digital signage in a real estate office?",
    answer:
      "Digital signage offers real-time updates and boosts customer engagement, while also promoting eco-friendly marketing by minimizing print materials. This makes it a valuable tool for any real estate office looking to enhance communication and sustainability.",
    controls: "uc-accordion-26",
    expanded: false,
  },
  {
    id: 3,
    question: "• What types of digital signage are commonly used in real estate?",
    answer: "In real estate, high-brightness window displays, video walls, and interactive kiosks are commonly used digital signage solutions that effectively engage potential buyers. These tools enhance property showcases and provide interactive information to viewers.",
    controls: "uc-accordion-28",
    expanded: false,
  },
  {
    id: 4,
    question: "• How can real estate agencies manage digital content effectively?",
    answer: "Real estate agencies can manage digital content effectively by utilizing content management platforms, which allow for the seamless updating and distribution of property information across various digital channels. This approach ensures that content remains current and engaging for potential clients.",
    controls: "uc-accordion-30",
    expanded: false,
  },
  {
    id: 5,
    question: "• What future trends should real estate agencies look out for in digital signage?",
    answer:
      "Real estate agencies should focus on AI integration for personalized content and augmented reality displays for immersive property experiences, as these trends are set to enhance user engagement significantly. Adopting these technologies will help agencies stay competitive in a rapidly evolving market.",
    controls: "uc-accordion-32",
    expanded: false,
  },
];

export const accordionItems_healthcare = [
  {
    id: 1,
    question: "• How does digital signage improve communication in healthcare facilities?",
    answer:
      "Digital signage revolutionizes communication in healthcare by delivering real-time updates and cutting down on printed materials, ensuring everyone stays informed and compliant with health regulations. Embrace this technology to enhance clarity and efficiency in your facility!",
    controls: "uc-accordion-24",
    expanded: true,
  },
  {
    id: 2,
    question: "• How can digital signage enhance the patient experience?",
    answer:
      "Digital signage significantly enhances the patient experience by delivering clear information, reducing perceived wait times, and offering engaging educational and entertainment content. Embrace this technology to elevate satisfaction and keep patients informed!",
    controls: "uc-accordion-26",
    expanded: false,
  },
  {
    id: 3,
    question: "• What are the benefits of integrating digital signage with healthcare systems?",
    answer: 
      "Integrating digital signage with healthcare systems significantly improves patient flow and communication by securely displaying patient-specific information and real-time updates on appointments. Embrace this technology to elevate the healthcare experience for both patients and staff!",
    controls: "uc-accordion-28",
    expanded: false,
  },
  {
    id: 4,
    question: "• How does digital signage contribute to cost-effectiveness and efficiency?",
    answer: 
    "Digital signage is a game-changer for cost-effectiveness and efficiency as it cuts down on printing costs and streamlines communication. Embrace this technology to optimize your operations and save resources!",
    controls: "uc-accordion-30",
    expanded: false,
  },
  {
    id: 5,
    question: "• Can I try digital signage solutions before committing?",
    answer:
    "Absolutely! Our digital signage app allow free trials, giving you the chance to experience the benefits personally before making a decision. Dive in and discover how it can elevate your communications!",
    controls: "uc-accordion-32",
    expanded: false,
  },
];

export const accordionItems2 = [
  {
    id: 23,
    title: "Manage tasks",
    icon: "/assets/images/common/icons/zap.svg",
    content:
      "Offers a unified platform that fosters innovation while providing end-to-end data management.",
    isOpen: false,
  },
  {
    id: 25,
    title: "Set priorities",
    icon: "/assets/images/common/icons/target.svg",
    content:
      "Providing end-to-end data management. See how we help your team solve today’s biggest challenges.",
    isOpen: false,
  },
  {
    id: 27,
    title: "Collaborate with your team",
    icon: "/assets/images/common/icons/settings.svg",
    content:
      "Our shared team inboxes keep everyone on the same page and in the loop.",
    isOpen: true,
  },
];
