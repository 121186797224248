import Footer from './footer/Footer';
import IndexNavbar from './navbar/IndexNavbar';
import { Link } from 'react-router-dom';
/*eslint-disable*/
import React from 'react';
import {
    Box,
    Button,
    Container,
    Grid,
    IconButton,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    Paper,
    Typography,
    Switch,
    FormControlLabel,
} from '@mui/material';
import FourKPlusIcon from '@mui/icons-material/FourKPlus';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import ArtTrackIcon from '@mui/icons-material/ArtTrack';
import TextFieldsIcon from '@mui/icons-material/TextFields';


export default function HomePage() {
    return (
        <>
            {/* <IndexNavbar fixed /> */}
            <div className="zls-section zls-panel zls-overflow-hidden">
                <div className="zls-position-cover" />
                <div className="zls-panel zls-pt-8">
                    <div className="zls-container zls-max-w-xl">
                    <div className="zls-panel">
                        <div className="zls-row child-cols justify-center lg: items-center zls-g-0">
                        <div className="zls-col-12 sm:zls-col-10 md:zls-col-8 lg:zls-col-5">
                            <div className="zls-panel zls-vstack zls-gap-2 sm:zls-text-start lg:zls-text-start rtl:lg:zls-text-start zls-py-4">
                                <h1 className="h2 font-semibold text-4xl text-blueGray-600 px-4 zls-col-6 sm: zls-col-5 md: zls-col-4">
                                    AI-Powered Digital Signage Solutions
                                </h1>
                                <p className="mt-4 text-lg leading-relaxed px-4 zls-col-6 sm: zls-col-5 md: zls-col-4">
                                    SignInspire, the AI leading digital signage software, simplifies creating
                                    stunning content for any screen. Whether it's TVs, monitors, tablets, or
                                    kiosks, connect seamlessly and communicate effectively with SignInspire.
                                </p>
                            <div className='mt-12 mb-12'>
                                <a
                                    href="/login"
                                    className="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 border-blue-500 bg-blue-500 rounded-md hover:bg-blue-600 uppercase text-sm shadow ease-linear transition-all duration-150"
                                >
                                    1-Month Free Trial
                                </a>
                                <a
                                    href="/contact"
                                    className="github-star ml-1 text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-blueGray-700 active:bg-blueGray-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                                >
                                    Request Demo
                                </a>
                            </div>
                            
                            
                            </div>
                        </div>
                        <div className="zls-col-12 lg:zls-col-6">
                            <div
                            className="expand-container mt-0 lg:mt-4 ltr:ms-n2 rtl:me-n2"
                            >
                            <img
                                className="ltr:d-block rtl:d-none"
                                alt="Sign Inspire home page image"
                                src="https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/homepage-AIGC-images/new1.jpeg"
                                width="1492"
                                height="1250"
                            />
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>

            <section className="mt-48 md:mt-40 pb-40 relative bg-blueGray-100">
                <div
                    className="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
                    style={{ transform: 'translateZ(0)' }}
                >
                    <svg
                        className="absolute bottom-0 overflow-hidden"
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="none"
                        version="1.1"
                        viewBox="0 0 2560 100"
                        x="0"
                        y="0"
                    >
                        <polygon
                            className="text-blueGray-100 fill-current"
                            points="2560 0 2560 100 0 100"
                        ></polygon>
                    </svg>
                </div>
                <div className="container mx-auto">
                    <div className="flex flex-wrap items-center">
                        <div className="w-10/12 md:w-6/12 lg:w-4/12 px-12 md:px-4 mr-auto ml-auto -mt-32">
                            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-lightBlue-500">
                                {/* <img
                                    alt="..."
                                    src="https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/homepage-AIGC-images/lib.jpeg"
                                    className="w-full align-middle rounded-t-lg"
                                /> */}
                                <video
                                    className="w-full align-middle rounded-t-lg"
                                    src="https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/homepage-AIGC-images/home-video.mov"
                                    autoPlay
                                    loop
                                    muted
                                ></video>
                                <blockquote className="relative p-8 mb-4">
                                    <svg
                                        preserveAspectRatio="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 583 95"
                                        className="absolute left-0 w-full block h-95-px -top-94-px"
                                    >
                                        <polygon
                                            points="-30,95 583,95 583,65"
                                            className="text-lightBlue-500 fill-current"
                                        ></polygon>
                                    </svg>
                                    <h4 className="text-xl font-bold text-black">
                                        Great for your awesome project
                                    </h4>
                                    <p
                                        className="text-md font-light mt-2 text-lightBlue-500"
                                        style={{ lineHeight: '2.2' }}
                                    >
                                        • Simple way digital signage software <br />
                                        • 2-minute setup & screen deployment <br />
                                        • AI-powered content inspiration <br />
                                        • Pay-As-You-Go & flexible plan <br />
                                    </p>
                                </blockquote>
                            </div>
                        </div>

                        <div className="w-full md:w-6/12 px-4">
                            <div className="flex flex-wrap">
                                <div className="w-full md:w-6/12 px-4">
                                    <div className="relative flex flex-col mt-4">
                                        <div className="px-4 py-5 flex-auto">
                                            <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                                                <i className="fas fa-sitemap"></i>
                                            </div>
                                            <h6 className="text-xl mb-1 font-semibold">Screen Manager</h6>
                                            <p className="mb-4 text-blueGray-500">
                                                Ensure the software can manage content across various screens
                                                and mobile devices.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="relative flex flex-col min-w-0 mt-4">
                                        <div className="px-4 py-5 flex-auto">
                                            <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                                                <i className="fas fa-list"></i>{' '}
                                                {/* Updated icon for playlist */}
                                            </div>
                                            <h6 className="text-xl mb-1 font-semibold">Playlist</h6>
                                            <p className="mb-4 text-blueGray-500">
                                                Create and manage playlists seamlessly for targeted content
                                                delivery and better engagement.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full md:w-6/12 px-4">
                                    <div className="relative flex flex-col min-w-0 mt-4">
                                        <div className="px-4 py-5 flex-auto">
                                            <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                                                <i className="fas fa-folder"></i> {/* Updated icon */}
                                            </div>
                                            <h6 className="text-xl mb-1 font-semibold">Content & Assets</h6>
                                            <p className="mb-4 text-blueGray-500">
                                                Cloud-Based content for AI inspiration and collaboration
                                            </p>
                                        </div>
                                    </div>

                                    <div className="relative flex flex-col min-w-0">
                                        <div className="px-4 py-5 flex-auto">
                                            <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                                                <i className="fas fa-calendar-alt"></i>
                                            </div>
                                            <h6 className="text-xl mb-1 font-semibold">Schedule</h6>
                                            <p className="mb-4 text-blueGray-500">
                                                Targeted content delivery at specific times enhances relevance
                                                and engagement.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                

                <div className="container mx-auto overflow-hidden pb-20">
                    <div className="flex flex-wrap items-center">
                        <div className="w-full md:w-4/12 px-12 md:px-4 ml-auto mr-auto mt-48">
                            <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                                <i className="fas fa-sitemap text-xl"></i>
                            </div>
                            <h3 className="text-3xl mb-2 font-semibold leading-normal">
                                Why Digital Signage is a Game-Changer
                            </h3>
                            <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
                                Digital signage transforms engagement by delivering dynamic, real-time
                                content, boosting visibility, and enhancing customer experience
                            </p>
                            <div className="block pb-6">
                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2">
                                    Retail
                                </span>
                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2">
                                    Hospitality
                                </span>
                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2">
                                    Healthcare
                                </span>
                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2">
                                    Education
                                </span>
                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2">
                                    Corporate
                                </span>
                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2">
                                    Transportation
                                </span>
                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2">
                                    Restaurants
                                </span>
                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white uppercase last:mr-0 mr-2 mt-2">
                                    Fitness Centers
                                </span>
                            </div>
                            <a
                                href="/solution"
                                //target="_blank"
                                className="font-bold text-blueGray-700 hover:text-blueGray-500 ease-linear transition-all duration-150"
                            >
                                View All <i className="fa fa-angle-double-right ml-1 leading-relaxed"></i>
                            </a>
                        </div>

                        <div className="w-full md:w-5/12 px-4 mr-auto ml-auto mt-32">
                            <div className="relative flex flex-col min-w-0 w-full mb-6 mt-48 md:mt-0">
                                 {/* center */}
                                <img
                                    alt="..."
                                    src="https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/MainPage/icon_app.png"
                                    // src="https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/homepage-AIGC-images/home0.jpeg"
                                    className="w-full align-middle rounded absolute shadow-lg max-w-150-px z-3 left-145-px -top-29-px"
                                    style={{opacity: 0.9}}
                                />
                                <img
                                    alt="..."
                                    src="https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/real_estate/real0.png"
                                    className="w-full align-middle rounded-lg absolute shadow-lg -top-160-px left-260-px max-w-210-px"
                                />
                                <img
                                    alt="..."
                                    src="https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/education/edu9.png"
                                    className="w-full align-middle rounded-lg absolute shadow-lg max-w-200-px -top-225-px left-10-px z-2"
                                />
                                <img
                                    alt="..."
                                    src="https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/restaurant/food5.png"
                                    className="w-full align-middle rounded-lg absolute shadow-2xl max-w-200-px -left-50-px top-25-px"
                                />

                                <img
                                    alt="..."
                                    src="https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/homepage-AIGC-images/burger.jpeg"
                                    className="w-full align-middle rounded absolute shadow-xl max-w-180-px left-195-px top-95-px"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-wrap items-center pt-32">
                        <div className="w-full md:w-6/12 px-4 mr-auto ml-auto mt-32">
                            <div className="justify-center flex flex-wrap relative">
                                <div className="my-4 w-full lg:w-6/12 px-4">
                                    <a
                                        //href="https://www.creative-tim.com/learning-lab/tailwind/svelte/alerts/notus?ref=vtw-index"
                                        target="_blank"
                                    >
                                        <div
                                            className="shadow-lg rounded-lg text-center p-8 bg-cover bg-center"
                                            style={{
                                                backgroundImage:
                                                    "url('https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/homepage-AIGC-images/h1.jpeg')",
                                            }}
                                        >
                                            <div
                className="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white transition-transform duration-150 ease-linear hover:-translate-y-2"
            >
                 <FourKPlusIcon style={{ fontSize: '2rem' }} color="primary" />
            </div>
                                            <p 
                                                className="text-lg text-white mt-4 font-semibold"
                                                style={{textShadow: '0 0 5px red',}}
                                            >
                                                4K Upscale
                                            </p>
                                        </div>
                                    </a>

                                    <a
                                        //href="https://www.creative-tim.com/learning-lab/tailwind/react/alerts/notus?ref=vtw-index"
                                        target="_blank"
                                    >
                                        <div
                                            className="bg-lightBlue-500 shadow-lg rounded-lg text-center p-8 mt-8"
                                            style={{
                                                backgroundSize: '100% 100%', 
                                                backgroundRepeat: 'no-repeat',
                                                backgroundPosition: 'center',
                                                backgroundImage:
                                                    "url('https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/homepage-AIGC-images/h3.jpeg')",
                                            }}
                                        >
                                            <div
                className="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white transition-transform duration-150 ease-linear hover:-translate-y-2"
            >
                <AddPhotoAlternateIcon style={{ fontSize: '2rem' }} color="primary"  />
            </div>
                                            <p className="text-lg text-white mt-4 font-semibold" style={{textShadow: '0 0 5px red',}}>AI Image</p>
                                        </div>
                                    </a>

                                    <a
                                        //href="https://www.creative-tim.com/learning-lab/tailwind/nextjs/alerts/notus?ref=vtw-index"
                                        target="_blank"
                                    >
                                        <div
                                            className="bg-blueGray-700 shadow-lg rounded-lg text-center p-8 mt-8"
                                            style={{
                                                backgroundSize: '100% 100%', 
                                                backgroundRepeat: 'no-repeat',
                                                backgroundPosition: 'center',
                                                backgroundImage:
                                                    "url('https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/homepage-AIGC-images/h5.jpeg')",
                                            }}
                                        >
                                            <div
                className="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white transition-transform duration-150 ease-linear hover:-translate-y-2"
            >
                <VideoCameraBackIcon style={{ fontSize: '2rem' }} color="primary"  />
            </div>
                                            <p className="text-lg text-white mt-4 font-semibold" style={{textShadow: '0 0 5px red',}}>Img2Video</p>
                                        </div>
                                    </a>
                                </div>

                                <div className="my-4 w-full lg:w-6/12 px-4 lg:mt-16">
                                    <a
                                        //href="https://www.creative-tim.com/learning-lab/tailwind/js/alerts/notus?ref=vtw-index"
                                        target="_blank"
                                    >
                                        <div
                                            className="bg-yellow-500 shadow-lg rounded-lg text-center p-8"
                                            style={{
                                                // backgroundSize: 'contain',
                                                backgroundSize: '100% 100%', 
                                                backgroundRepeat: 'no-repeat',
                                                backgroundPosition: 'center',
                                                backgroundImage:
                                                    "url('https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/homepage-AIGC-images/h2.jpeg')",
                                            }}
                                        >
                                            <div
                className="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white transition-transform duration-150 ease-linear hover:-translate-y-2"
            >
                <CrisisAlertIcon style={{ fontSize: '2rem' }} color="primary"  />
            </div>
                                            <p className="text-lg text-white mt-4 font-semibold" style={{textShadow: '0 0 5px red',}}>
                                            Attention Detect
                                            </p>
                                        </div>
                                    </a>

                                    <a
                                        //href="https://www.creative-tim.com/learning-lab/tailwind/angular/alerts/notus?ref=vtw-index"
                                        target="_blank"
                                    >
                                        <div
                                            className="bg-red-700 shadow-lg rounded-lg text-center p-8 mt-8"
                                            style={{
                                                backgroundSize: '100% 100%', 
                                                backgroundRepeat: 'no-repeat',
                                                backgroundPosition: 'center',
                                                backgroundImage:
                                                    "url('https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/homepage-AIGC-images/h4.jpeg')",
                                            }}
                                        >
                                            <div
                className="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white transition-transform duration-150 ease-linear hover:-translate-y-2"
            >
                <ArtTrackIcon style={{ fontSize: '2rem' }} color="primary"  />
            </div>
                                            <p className="text-lg text-white mt-4 font-semibold" style={{textShadow: '0 0 5px red',}}>Restyle</p>
                                        </div>
                                    </a>

                                    <a
                                        //href="https://www.creative-tim.com/learning-lab/tailwind/vue/alerts/notus?ref=vtw-index"
                                        target="_blank"
                                    >
                                        <div
                                            className="bg-emerald-500 shadow-lg rounded-lg text-center p-8 mt-8"
                                            style={{
                                                backgroundSize: '100% 100%', 
                                                backgroundRepeat: 'no-repeat',
                                                backgroundPosition: 'center',
                                                backgroundImage:
                                                    "url('https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/homepage-AIGC-images/h6.jpeg')",
                                            }}
                                        >
                                            <div
                className="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white transition-transform duration-150 ease-linear hover:-translate-y-2"
            >
                <TextFieldsIcon style={{ fontSize: '2rem' }} color="primary"  />
            </div>
                                            <p className="text-lg text-white mt-4 font-semibold" style={{textShadow: '0 0 5px red',}}>AI Writer</p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="w-full md:w-4/12 px-12 md:px-4 ml-auto mr-auto mt-48">
                            {/* Icon Section */}
                            <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                                <i className="fas fa-drafting-compass text-xl"></i>
                            </div>
                            {/* Title */}
                            <h3 className="text-3xl mb-2 font-semibold leading-normal">
                                Get Inspired and Rated From AI
                            </h3>
                            {/* Description */}
                            <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
                                AI digital signage transforms traditional displays by leveraging artificial
                                intelligence for personalized and impactful communication. Here’s how it makes
                                a difference:
                            </p>
                            {/* Key Features */}
                            <ul className="list-disc list-inside text-blueGray-600 space-y-2">
                                <li>
                                    <strong>Audience Targeting:</strong> Identifies audience demographics and
                                    displays tailored content in real-time.
                                </li>
                                <li>
                                    <strong>Dynamic Content Optimization:</strong> Adjusts content based on
                                    location, time of day, or current events.
                                </li>
                                <li>
                                    <strong>Attention Metrics:</strong> Tracks and analyzes viewer engagement
                                    to measure impact.
                                </li>
                            </ul>
                            {/* Tags */}
                            <div className="block pb-6 mt-6">
                                {[
                                    'Text2Image',
                                    'Image2Video',
                                    '4K Upscale',
                                    'Text Refiner',
                                    'Image Animation',
                                    'Post Evaluation',
                                    'Attention Metric',
                                    'Everyday Recommendation',
                                ].map((tag) => (
                                    <span
                                        key={tag}
                                        className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-white last:mr-0 mr-2 mt-2"
                                    >
                                        {tag}
                                    </span>
                                ))}
                            </div>
                            {/* Call to Action */}
                            <a
                                href="/pricing"
                                //target="_blank"
                                className="font-bold text-blueGray-700 hover:text-blueGray-500 ease-linear transition-all duration-150"
                            >
                                View all <i className="fa fa-angle-double-right ml-1 leading-relaxed"></i>
                            </a>
                        </div>
                    </div>
                </div>

                <div className="container mx-auto px-4 pb-32 pt-48">
                    <div className="items-center flex flex-wrap">
                        {/* Text Content */}
                        <div className="w-full md:w-5/12 ml-auto px-12 md:px-4">
                            <div className="md:pr-12">
                                <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                                    <i className="fas fa-file-alt text-xl"></i>
                                </div>
                                <h3 className="text-3xl font-semibold">
                                    Discover How to Make Your Digital Signage Content Attract Audiences
                                </h3>
                                <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                                    In today's advertising, capturing the customer's first glance is crucial.
                                    Tailored digital signage ensures your message connects instantly, aligning
                                    perfectly with the right audience, platform, and timing for maximum
                                    impact.
                                </p>
                                <ul className="list-none mt-6">
                                    <li className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3">
                                                    <i className="fas fa-fingerprint"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">
                                                    Capture the customer's attention at first glance with
                                                    impactful visuals.
                                                </h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3">
                                                    <i className="fab fa-html5"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">
                                                    Align content with the right audience, platform, and
                                                    timing.
                                                </h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3">
                                                    <i className="far fa-paper-plane"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">
                                                    Drive results by ensuring messages resonate with diverse
                                                    audiences.
                                                </h4>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="w-full md:w-6/12 mr-auto px-4 pt-24 md:pt-0">
                            <div className="relative">
                                {/* Original Image */}
                                <div className="relative group overflow-hidden rounded-lg shadow-xl hover:shadow-2xl transition-shadow duration-300">
                                    <img
                                        alt="Original"
                                        className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-300"
                                        src="https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/MainPage/Heatmap/h1.png"
                                    />
                                    <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent opacity-70 group-hover:opacity-50 transition-opacity duration-300"></div>
                                    <p className="absolute bottom-2 left-2 text-white bg-black bg-opacity-70 text-sm px-3 py-1 rounded-lg">
                                        Original
                                    </p>
                                </div>

                                {/* VS Label */}
                                <div className="absolute left-1/2 transform -translate-x-1/2 top-1/2 -translate-y-1/2 z-10">
                                    <span className="text-white bg-red-500 text-xl font-bold px-4 py-2 rounded-full shadow-lg">
                                        VS
                                    </span>
                                </div>

                                {/* Heatmap Image */}
                                <div className="relative mt-6 group overflow-hidden rounded-lg shadow-xl hover:shadow-2xl transition-shadow duration-300">
                                    <img
                                        alt="Heatmap"
                                        className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-300"
                                        src="https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/MainPage/Heatmap/h1_heatmap.png"
                                    />
                                    <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent opacity-70 group-hover:opacity-50 transition-opacity duration-300"></div>
                                    <p className="absolute bottom-2 left-2 text-white bg-black bg-opacity-70 text-sm px-3 py-1 rounded-lg">
                                        Heatmap
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="justify-center text-center flex flex-wrap mt-24">
                    <div className="w-full md:w-6/12 px-12 md:px-4">
                        <h2 className="font-semibold text-4xl">Use Cases</h2>
                        <p className="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500">
                        Digital signage enhances communication and engagement by delivering dynamic, targeted content that captures attention and improves audience interaction.
                        </p>
                    </div>
                </div>
            </section>

            <section className="block relative z-1 bg-blueGray-600">
                <div className="container mx-auto">
                    <div className="justify-center flex flex-wrap">
                        <div className="w-full lg:w-12/12 px-4  -mt-24">
                            <div className="flex flex-wrap">
                                <div className="w-full lg:w-4/12 px-4">
                                    <h5 className="text-xl font-semibold pb-4 text-center">Restaurant</h5>
                                    <Link to="/solution/restaurant">
                                        <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                                            <img
                                                alt="..."
                                                className="align-middle border-none max-w-full h-auto rounded-lg"
                                                src="https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/restaurant/food6.png"
                                            />
                                        </div>
                                    </Link>
                                </div>

                                <div className="w-full lg:w-4/12 px-4">
                                    <h5 className="text-xl font-semibold pb-4 text-center">Healthcare</h5>
                                    <Link to="/solution/healthcare">
                                        <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                                            <img
                                                alt="..."
                                                className="align-middle border-none max-w-full h-auto rounded-lg"
                                                src="https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/health_care/heal1.png"
                                            />
                                        </div>
                                    </Link>
                                </div>

                                <div className="w-full lg:w-4/12 px-4">
                                    <h5 className="text-xl font-semibold pb-4 text-center">Education</h5>
                                    <Link to="/solution/education">
                                        <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                                            <img
                                                alt="..."
                                                className="align-middle border-none max-w-full h-auto rounded-lg"
                                                src="https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/education/school1.png"
                                            />
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section
                className="py-20 bg-blueGray-600 overflow-hidden"
                style={{
                    backgroundImage: "url('https://your-background-image-url.com/image.jpg')",
                }}
            >
                <div className="container mx-auto pb-64">
                    <div className="flex flex-wrap justify-center">
                        <div className="w-full md:w-5/12 px-12 md:px-4 ml-auto mr-auto md:mt-24">
                            <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                                <i className="fas fa-desktop text-xl"></i>
                            </div>
                            <h3 className="text-3xl mb-2 font-semibold leading-normal text-white">
                                Revolutionize the Potential of Digital Signage in Only 4 Steps in 1 Minute.
                            </h3>
                            <ul className="list-decimal list-inside text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-400 space-y-4">
                                <li>
                                    <span className="font-semibold">
                                        Assign your Screen by Activation Code:
                                    </span>{' '}
                                    AI-powered digital signage streamlines operations and automates processes,
                                    saving you time and resources.
                                </li>
                                <li>
                                    <span className="font-semibold">Manage Your Content & Assets:</span>{' '}
                                    Leverage AI to deliver targeted content and tailor experiences to each
                                    individual viewer, increasing engagement and satisfaction.
                                </li>
                                <li>
                                    <span className="font-semibold">
                                        Create Your Playlist with Custom Template:
                                    </span>{' '}
                                    Unlock valuable insights from data collected by AI-powered digital
                                    signage, enabling data-driven decision-making and optimization.
                                </li>
                                <li>
                                    <span className="font-semibold">
                                        Add Schedule and Push to Your Screen:
                                    </span>{' '}
                                    Our AI-powered digital signage seamlessly integrates with your existing
                                    systems and workflows, ensuring a smooth transition and maximum
                                    compatibility.
                                </li>
                            </ul>
                            <a
                                href="/login"
                                //target="_blank"
                                className="github-star mt-4 inline-block text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-blueGray-700 active:bg-blueGray-600 uppercase text-sm shadow hover:shadow-lg"
                            >
                                Try Now
                            </a>
                        </div>

                        <div className="w-full md:w-4/12 px-4 mr-auto ml-auto mt-32 relative">
                            <img
                                src="https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/homepage-AIGC-images/home.jpeg"
                                
                                // src="https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/MainPage/A0.png"
                                alt="Custom Icon"
                                className="absolute top-0 right-0 opacity-80"
                                style={{
                                    width: '650px', // Adjusted size to be more visible
                                    height: '650px', // Proportional to width
                                }}
                            />
                        </div>
                    </div>
                </div>
            </section>

            <section className="pb-8 bg-blueGray-200 relative pt-32">
                <div
                    className="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
                    style={{ transform: 'translateZ(0)' }}
                >
                    <svg
                        className="absolute bottom-0 overflow-hidden"
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="none"
                        version="1.1"
                        viewBox="0 0 2560 100"
                        x="0"
                        y="0"
                    >
                        <polygon
                            className="text-blueGray-200 fill-current"
                            points="2560 0 2560 100 0 100"
                        ></polygon>
                    </svg>
                </div>

                <div className="container mx-auto">
                    <div className="flex flex-wrap justify-center bg-white shadow-xl rounded-lg -mt-64 py-16 px-12 relative z-10">
                        <div className="w-full text-center lg:w-8/12">
                            {/* <p className="text-4xl text-center">
                                <span role="img" aria-label="love">
                                    😍
                                </span>
                            </p> */}
                            <h3 className="font-semibold text-3xl">Still have questions?</h3>
                            <p className="text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                                Let's keep in touch! Find us on any of these platforms, we respond 1-2
                                business days.
                            </p>
                            <div className="sm:block flex flex-col mt-10">
                                <a
                                    href="/login"
                                    className="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-2 border-blue-500 bg-blue-500 rounded-md hover:bg-blue-600 uppercase text-sm shadow ease-linear transition-all duration-150"
                                >
                                    Get started
                                </a>
                                <a
                                    href="contact"
                                    className="github-star sm:ml-1 text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-blueGray-700 active:bg-blueGray-600 uppercase text-sm shadow hover:shadow-lg"
                                >
                                    <i className="fas fa-desktop text-lg mr-1"></i>
                                    <span>Contact Us</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
