import React from "react";

export default function About3() {
  return (
    <div
      id="about_us"
      className="about-us section panel overflow-hidden"
      data-anime="onview: -100; opacity: [0, 1]; easing: spring(1, 80, 10, 0); duration: 450; delay: 250;"
    >
      <div className="section-outer panel py-6 xl:py-9 bg-secondary dark:bg-gray-800">
        <div
          className="d-none lg:d-block"
          data-anime="onview: -100; targets: img; scale: [0.8, 1]; opacity: [0, 1]; easing: spring(1, 80, 10, 0); duration: 450; delay: 350;"
        >
          <div className="position-absolute bottom-0 start-0 ms-n8 mb-6">
            <img
              className="w-150px xl:w-250px d-block dark:d-none"
              alt="walking"
              src="/images/template/walking.svg"
              width="224"
              height="226"
            />
            <img
              className="w-150px xl:w-250px d-none dark:d-block"
              alt="walking-dark"
              src="/images/template/walking-dark.svg"
              width="224"
              height="227"
            />
          </div>
        </div>
        <div className="container max-w-lg">
          <div className="section-inner panel">
            <div
              className="panel vstack text-center"
              data-anime="onview: -100; targets: >*; translateY: [48, 0]; opacity: [0, 1]; easing: spring(1, 80, 10, 0); duration: 450; delay: anime.stagger(100, {start: 200});"
            >
              <h2 className="h3 lg:h2 mb-4">Best Practices for Digital Signage Management</h2>
              <p className="fs-5 xl:fs-4 text-dark dark:text-white">
                Managing digital signage effectively starts with using a reliable content management system (CMS). A CMS allows you to easily update and organize your digital signage content, ensuring that it stays relevant and engaging. Whether you're sharing announcements, schedules, or creative displays, a good CMS saves time and keeps everything running smoothly.
              </p> 
              <p className="fs-5 xl:fs-4 text-dark dark:text-white">
                It's also important to make sure your content is accessible and visible to everyone. Place screens in high-traffic areas and use clear, bold text that's easy to read from a distance. Bright, high-quality visuals can help grab attention and communicate your message effectively.
              </p>
              <p className="fs-5 xl:fs-4 text-dark dark:text-white">
                Consistency is key when it comes to design. Stick to a standard aspect ratio and use a uniform template for your displays. This ensures a professional look that represents your school or institution well.
              </p>
              <p className="fs-5 xl:fs-4 text-dark dark:text-white">
                Finally, don't forget to keep your content fresh. Regularly update and rotate what's shown on your digital signage. This keeps users interested and ensures that your displays continue to provide valuable and timely information. With these practices, your digital signage can be a reliable tool for communication and engagement.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
