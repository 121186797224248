import React from "react";

export default function About2() {
  return (
    <div
      id="about_us"
      className="about-us section panel overflow-hidden"
      data-anime="onview: -100; opacity: [0, 1]; easing: spring(1, 80, 10, 0); duration: 450; delay: 250;"
    >
      <div className="section-outer panel py-6 xl:py-9 bg-secondary dark:bg-gray-800">
        <div
          className="d-none lg:d-block"
          data-anime="onview: -100; targets: img; scale: [0.8, 1]; opacity: [0, 1]; easing: spring(1, 80, 10, 0); duration: 450; delay: 350;"
        >
          <div className="position-absolute bottom-0 start-0 ms-n8 mb-6">
            <img
              className="w-150px xl:w-250px d-block dark:d-none"
              alt="walking"
              src="/images/template/walking.svg"
              width="224"
              height="226"
            />
            <img
              className="w-150px xl:w-250px d-none dark:d-block"
              alt="walking-dark"
              src="/images/template/walking-dark.svg"
              width="224"
              height="227"
            />
          </div>
        </div>
        <div className="container max-w-lg">
          <div className="section-inner panel">
            <div
              className="panel vstack text-center"
              data-anime="onview: -100; targets: >*; translateY: [48, 0]; opacity: [0, 1]; easing: spring(1, 80, 10, 0); duration: 450; delay: anime.stagger(100, {start: 200});"
            >
              <h2 className="h3 lg:h2 mb-4">Integrating Digital Signage with Healthcare Systems</h2>
              <p className="fs-5 xl:fs-4 text-dark dark:text-white">
                Integrating digital signage with healthcare systems offers numerous benefits, such as managing patient flow and providing real-time updates on queue status. By displaying real-time updates, digital signage enhances the flow of patients and improves overall efficiency in healthcare facilities.                
              </p> 
              <p className="fs-5 xl:fs-4 text-dark dark:text-white">
              Digital signage software allows for managing displays remotely over the web, enhancing convenience and ensuring timely communication within healthcare teams.                
              </p>
              <p className="fs-5 xl:fs-4 text-dark dark:text-white" style={{fontWeight: 700,}}>
              • EHR Integration
              </p>
              <p className="fs-5 xl:fs-4 text-dark dark:text-white">
              Integrating digital signage with Electronic Health Records enables secure display of patient-specific information, ensuring that patients receive personalized care. This integration allows for automatic display of patient information and appointment details, enhancing the patient experience.
              </p>
              <p className="fs-5 xl:fs-4 text-dark dark:text-white" style={{fontWeight: 700,}}>
              • Scheduling Systems
              </p>
              <p className="fs-5 xl:fs-4 text-dark dark:text-white">
              Digital signage enhances patient satisfaction by managing perceived wait times and providing engaging content. By integrating digital signage with existing appointment scheduling systems, healthcare facilities can provide real-time updates on appointment statuses and automate queue calling displays, improving patient flow and reducing perceived wait times.                
              </p>
              <p className="fs-5 xl:fs-4 text-dark dark:text-white">
              Integrating digital signage with Electronic Health Records (EHR) further enhances the patient experience by securely displaying patient-specific information.                
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
