import { useState } from "react";

export default function Features() {
  const [activeTab, setActiveTab] = useState(1);
  return (
    <div
      id="main_features"
      className="main-features section panel overflow-hidden"
    >
      <div className="section-outer panel pb-6 xl:pb-9">
        <div className="container max-w-xl lg:max-w-lg">
          <div
            className="section-inner panel"
            data-anime="onview: -100; targets: >*; translateY: [48, 0]; opacity: [0, 1]; easing: spring(1, 80, 10, 0); duration: 450; delay: anime.stagger(100, {start: 100});"
          >
            <div className="panel vstack items-center gap-2 xl:gap-3 mb-4 sm:mb-8 xl:mb-9 sm:max-w-650px xl:max-w-800px mx-auto text-center">
              <h2 className="h3 lg:h2 xl:h1 m-0">
                Customizable Content for Diverse Audiences
              </h2>
              {/* <p className="fs-6 xl:fs-5 text-dark dark:text-white text-opacity-70">
              Enhancing the patient experience is a top priority for healthcare providers. Digital signage solutions offer a powerful tool to achieve this by providing real-time updates and flexible content management.
              </p> */}
            </div>
            <div className="panel vstack gap-4 xl:gap-6">
              <div className="panel px-3 rounded-2 bg-secondary dark:bg-gray-800">
                <ul
                  className="main-features-nav hstack text-center overflow-auto"
                  data-uc-switcher="connect: .main-features-switcher; active: 1; animation: uc-animation-fade;"
                  role="tablist"
                >
                  <li
                    className={`${activeTab == 1 ? "uc-active" : ""}`}
                    onClick={() => setActiveTab(1)}
                    role="presentation"
                  >
                    <a className="h6 lg:h5 text-nowrap m-0">Customizable Healthcare Communication</a>
                  </li>
                  <li
                    onClick={() => setActiveTab(2)}
                    className={`${activeTab == 2 ? "uc-active" : ""}`}
                    role="presentation"
                  >
                    <a className="h6 lg:h5 text-nowrap m-0">Patient-specific Messaging</a>
                  </li>
                  <li
                    className={`${activeTab == 3 ? "uc-active" : ""}`}
                    onClick={() => setActiveTab(3)}
                    role="presentation"
                  >
                    <a className="h6 lg:h5 text-nowrap m-0">
                      Staff Updates
                    </a>
                  </li>
                  
                </ul>
              </div>
              <div
                className="main-features-switcher uc-switcher overflow-hidden"
                role="presentation"
                style={{ touchAction: "pan-y pinch-zoom" }}
              >
                {activeTab == 1 && (
                  <div className="uc-active">
                    <div className="feature-item panel">
                      <div className="row child-cols col-match justify-between g-4">
                        <div className="col-12 sm:col-6">
                          <figure className="featured-image m-0 rounded ratio ratio-1x1 rounded-2 overflow-hidden">
                            <img
                              className="media-cover image"
                              alt="healthcare"
                              src="https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/health_care/heal5.png"
                              width="1200"
                              height="1200"
                            />
                          </figure>
                        </div>
                        <div className="col-12 sm:col-6">
                          <div className="panel">
                            <div className="panel vstack justify-center gap-4 h-100 sm:p-3 lg:p-4">
                              <div>
                                <div className="panel vstack gap-2">
                                  {/* <h3 className="h4 sm:h3 lg:h2 m-0">
                                    Share tools quickly and confidently in
                                    minutes
                                  </h3> */}
                                  <p className="fs-6 lg:fs-5 opacity-70 dark:opacity-80">
                                  Digital signage enables healthcare facilities to strategically reach patients, staff, doctors, and visitors with tailored content. This extensive customization is suitable for the unique needs of different healthcare facilities, ensuring that the right message reaches the right audience at the right time.
                                  </p>
                                  <p className="fs-6 lg:fs-5 opacity-70 dark:opacity-80">
                                  Users can select from a vast array of customizable templates to create captivating and engaging presentations, enhancing the relevance of displayed information. By engaging diverse audiences through customizable content, healthcare facilities can improve communication and information retention.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {activeTab == 2 && (
                  <div className="uc-active">
                    <div className="feature-item panel">
                      <div className="row child-cols col-match justify-between g-4">
                        <div className="col-12 sm:col-6">
                          <figure className="featured-image m-0 rounded ratio ratio-1x1 rounded-2 overflow-hidden">
                            <img
                              className="media-cover image"
                              alt="healthcare"
                              src="https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/health_care/heal4.png"
                              width="1200"
                              height="1200"
                            />
                          </figure>
                        </div>
                        <div className="col-12 sm:col-6">
                          <div className="panel">
                            <div className="panel vstack justify-center gap-4 h-100 sm:p-3 lg:p-4">
                              <div>
                                <div className="panel vstack gap-2">
                                  <p className="fs-6 lg:fs-5 opacity-70 dark:opacity-80">
                                  Healthcare digital signage allows for the creation of unique messages tailored to specific departments or patient groups, ensuring relevant information delivery. By addressing the unique information needs of different patient groups, digital signage enhances their engagement and understanding.                 
                                  </p>
                                  <p className="fs-6 lg:fs-5 opacity-70 dark:opacity-80">
                                  Custom messaging for specific patient demographics improves the relevance of information provided, contributing to a better patient experience.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {activeTab == 3 && (
                  <div className="uc-active">
                    <div className="feature-item panel">
                      <div className="row child-cols col-match justify-between g-4">
                        <div className="col-12 sm:col-6">
                          <figure className="featured-image m-0 rounded ratio ratio-1x1 rounded-2 overflow-hidden">
                            <img
                              className="media-cover image"
                              alt="healthcare"
                              src="https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/health_care/heal2.png"
                              width="1200"
                              height="1200"
                            />
                          </figure>
                        </div>
                        <div className="col-12 sm:col-6">
                          <div className="panel">
                            <div className="panel vstack justify-center gap-4 h-100 sm:p-3 lg:p-4">
                              <div>
                                <div className="panel vstack gap-2">
                                  <p className="fs-6 lg:fs-5 opacity-70 dark:opacity-80">
                                  Digital signage serves as an effective method for internal communication, allowing healthcare organizations to share essential updates and training materials with staff in real-time. Using digital displays in staff areas keeps employees informed and engaged, fostering a positive workplace culture.
                                  </p>
                                  <p className="fs-6 lg:fs-5 opacity-70 dark:opacity-80">
                                  Centralized control over digital signage solutions facilitates consistent messaging across departments, ensuring that all staff members receive uniform information. Real-time alerts and notifications can be sent through digital signage, enhancing communication regarding urgent matters or changes in protocols.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
