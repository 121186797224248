import { Link } from "react-router-dom";
import { useState } from "react";

export default function Features() {
  const [activeTab, setActiveTab] = useState(1);
  return (
    <div
      id="main_features"
      className="main-features section panel overflow-hidden"
    >
      <div className="section-outer panel pb-6 xl:pb-9">
        <div className="container max-w-xl lg:max-w-lg">
          <div
            className="section-inner panel"
            data-anime="onview: -100; targets: >*; translateY: [48, 0]; opacity: [0, 1]; easing: spring(1, 80, 10, 0); duration: 450; delay: anime.stagger(100, {start: 100});"
          >
            <div className="panel vstack items-center gap-2 xl:gap-3 mb-4 sm:mb-8 xl:mb-9 sm:max-w-650px xl:max-w-800px mx-auto text-center">
              <h2 className="h3 lg:h2 xl:h1 m-0">
                Future Trends in Real Estate Digital Signage
              </h2>
              {/* <p className="fs-6 xl:fs-5 text-dark dark:text-white text-opacity-70">
                We focus on helping you to make useful content more accessible
                with an utlimate goal for a good sharing profit as a content
                creator.
              </p> */}
            </div>
            <div className="panel vstack gap-4 xl:gap-6">
              <div className="panel px-3 rounded-2 bg-secondary dark:bg-gray-800">
                <ul
                  className="main-features-nav hstack text-center overflow-auto"
                  data-uc-switcher="connect: .main-features-switcher; active: 1; animation: uc-animation-fade;"
                  role="tablist"
                >
                  <li
                    className={`${activeTab == 1 ? "uc-active" : ""}`}
                    onClick={() => setActiveTab(1)}
                    role="presentation"
                  >
                    <a className="h6 lg:h5 text-nowrap m-0">Trends</a>
                  </li>
                  <li
                    onClick={() => setActiveTab(2)}
                    className={`${activeTab == 2 ? "uc-active" : ""}`}
                    role="presentation"
                  >
                    <a className="h6 lg:h5 text-nowrap m-0">AI Integration</a>
                  </li>
                  <li
                    className={`${activeTab == 3 ? "uc-active" : ""}`}
                    onClick={() => setActiveTab(3)}
                    role="presentation"
                  >
                    <a className="h6 lg:h5 text-nowrap m-0">
                      AR Displays
                    </a>
                  </li>
                  
                </ul>
              </div>
              <div
                className="main-features-switcher uc-switcher overflow-hidden"
                role="presentation"
                style={{ touchAction: "pan-y pinch-zoom" }}
              >
                {activeTab == 1 && (
                  <div className="uc-active">
                    <div className="feature-item panel">
                      <div className="row child-cols col-match justify-between g-4">
                        <div className="col-12 sm:col-6">
                          <figure className="featured-image m-0 rounded ratio ratio-1x1 rounded-2 overflow-hidden">
                            <img
                              className="media-cover image"
                              alt="Real-estate"
                              src="https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/real_estate/e7.png"
                              width="1200"
                              height="1200"
                            />
                          </figure>
                        </div>
                        <div className="col-12 sm:col-6">
                          <div className="panel">
                            <div className="panel vstack justify-center gap-4 h-100 sm:p-3 lg:p-4">
                              <div>
                                <div className="panel vstack gap-2">
                                  {/* <h3 className="h4 sm:h3 lg:h2 m-0">
                                    Share tools quickly and confidently in
                                    minutes
                                  </h3> */}
                                  <p className="fs-6 lg:fs-5 opacity-70 dark:opacity-80">
                                    The digital signage landscape is continuously evolving, driven by advancements in technology and changing consumer expectations. Emerging digital signage technologies focus on high-definition visuals and interactivity, significantly improving customer engagement and property marketing effectiveness. The global market for digital signage in the real estate sector is projected to grow significantly, expected to reach approximately $35.94 billion by 2026.                
                                  </p>
                                  <p className="fs-6 lg:fs-5 opacity-70 dark:opacity-80">
                                  These trends suggest that real estate businesses must stay abreast of technological developments to maintain a competitive edge. By adopting the latest digital signage innovations, real estate agencies can provide immersive and engaging experiences for potential buyers, ultimately driving higher sales and customer satisfaction.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {activeTab == 2 && (
                  <div className="uc-active">
                    <div className="feature-item panel">
                      <div className="row child-cols col-match justify-between g-4">
                        <div className="col-12 sm:col-6">
                          <figure className="featured-image m-0 rounded ratio ratio-1x1 rounded-2 overflow-hidden">
                            <img
                              className="media-cover image"
                              alt="Real-estate"
                              src="https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/real_estate/real6.png"
                              width="1200"
                              height="1200"
                            />
                          </figure>
                        </div>
                        <div className="col-12 sm:col-6">
                          <div className="panel">
                            <div className="panel vstack justify-center gap-4 h-100 sm:p-3 lg:p-4">
                              <div>
                                <div className="panel vstack gap-2">
                                  <p className="fs-6 lg:fs-5 opacity-70 dark:opacity-80">
                                    Artificial Intelligence (AI) is set to revolutionize digital signage by enhancing user engagement through personalized content. AI-driven digital signage can analyze customer data to provide customized property recommendations, improving the chances of successful transactions. Imagine walking into a real estate office and seeing property listings tailored to your preferences based on your previous interactions—it's a game-changer for client engagement.
                                  </p>
                                  <p className="fs-6 lg:fs-5 opacity-70 dark:opacity-80">
                                    By utilizing AI technology, real estate agents can deliver real-time updates and personalized content that adjusts based on user interactions, creating a more engaging and effective marketing strategy. This level of customization not only enhances the user experience but also increases the likelihood of converting inquiries into sales.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {activeTab == 3 && (
                  <div className="uc-active">
                    <div className="feature-item panel">
                      <div className="row child-cols col-match justify-between g-4">
                        <div className="col-12 sm:col-6">
                          <figure className="featured-image m-0 rounded ratio ratio-1x1 rounded-2 overflow-hidden">
                            <img
                              className="media-cover image"
                              alt="Real-estate"
                              src="https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/real_estate/e2.png"
                              width="1200"
                              height="1200"
                            />
                          </figure>
                        </div>
                        <div className="col-12 sm:col-6">
                          <div className="panel">
                            <div className="panel vstack justify-center gap-4 h-100 sm:p-3 lg:p-4">
                              <div>
                                <div className="panel vstack gap-2">
                                  <p className="fs-6 lg:fs-5 opacity-70 dark:opacity-80">
                                  Augmented Reality is another future trend poised to transform property viewings by providing interactive and engaging visual experiences for potential buyers. AR allows users to visualize 3D models of properties and furniture within their own environments, improving engagement and lead qualification in real estate. Imagine a client walking into a real estate office and using an AR display to virtually tour a property and see how their furniture would look in the space—it's an innovative way to enhance the decision-making process.
                                  </p>
                                  <p className="fs-6 lg:fs-5 opacity-70 dark:opacity-80">
                                  By integrating AR into property viewings, potential buyers can better understand and imagine living in a space, leading to higher conversion rates and more informed decisions. This technology not only enhances viewer engagement but also provides a competitive advantage for real estate agencies adopting these innovative solutions.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
