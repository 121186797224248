import { FcComments, FcHeatMap, FcIdea } from 'react-icons/fc';
import { FetchAIRecommend, FetchHeatmapURL, PostDescribeImage } from '../ai_tools/Api';
import { HeatmapDialog, ImageDescriptionDialog, PreviewMediaDialog, RecommendImageDialog } from './';
import { IconButton, ImageList, ImageListItem, ImageListItemBar } from '@mui/material';
import React, { useEffect, useState } from 'react';

import ChatBubbleOutline from '@mui/icons-material/ChatBubbleOutline';
import Equalizer from '@mui/icons-material/Equalizer'; // For heatmap
import Lightbulb from '@mui/icons-material/Lightbulb'; // For ideas
import RateReview from '@mui/icons-material/RateReview';
import FourKPlusIcon from '@mui/icons-material/FourKPlus';
import { GrClose } from 'react-icons/gr';
import LazyLoad from 'react-lazyload';
import { TbCloudUpload } from 'react-icons/tb';
import { Tooltip } from '@mui/material';
import config from '../../../config/config';
import { styled } from '@mui/material/styles';
import { SaltUpscaleImage, SaltUpscaleResult } from '../ai_tools/Api';
import UpscaleImageDialog from './UpscaleImageDialog';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const ImageListItemBarCenter = styled(ImageListItemBar)(({ theme }) => ({
    ' .MuiImageListItemBar-titleWrap': {
        padding: '0',
    },
    '& .MuiImageListItemBar-title': {
        textAlign: 'center',
        fontSize: '14px',
        paddingBottom: '10px',
        paddingLeft: '10px',
        paddingRight: '10px',
    },
}));
const ImageListSection = ({
    handleOpenUploadDialog,
    handleOpenDeleteDialog,
    chosenfolderMedia,
    item,
    currentUser,
    screenSize,
}) => {
    //console.log('screenSize:', screenSize);
    const [openPreviewMediaDialog, setOpenPreviewMediaDialog] = useState(false);
    const [previewMedia, setPreviewMedia] = useState({});

    const handleOpenPreviewMediaDialog = (e, item) => {
        // e.stopPropagation();

        //console.log('handleOpenPreviewMediaDialog => item:', item);

        setPreviewMedia(item);
        setOpenPreviewMediaDialog(true);
    };
    const handleClosePreviewMediaDialog = () => {
        setOpenPreviewMediaDialog(false);
    };

    // Recommend Sec
    const [openRecommendDialog, setOpenRecommendDialog] = useState(false);
    const [recommend, setRecommend] = useState();

    const handleOpenRecommendDialog = async (e, item) => {
        //  e.stopPropagation();

        console.log('handleOpenRecommendDialog => item:', item);
        setOpenRecommendDialog(true);
        const result = await PostDescribeImage({
            image_url: item.resource_url,
            user_id: currentUser.user_id,
        });
        console.log('handleOpenPreviewMediaDialog => result:', result);
        if (result && result.statusCode === 200) {
            setRecommend(result.message);
        } else {
            setRecommend('System busy, please try again!');
        }
    };
    const handleCloseRecommendDialog = () => {
        setOpenRecommendDialog(false);
        setRecommend();
    };

    // Heatmap Section
    const [openHeatmapDialog, setOpenHeatmapDialog] = useState(false);
    const [heatmap, setHeatmap] = useState();
    const [originalImage, setOriginalImage] = useState();

    //const heatmap_url = config[process.env.REACT_APP_ENV].REACT_APP_HEATMAP_API_URL;

    const handleOpenHeatmapDialog = async (e, item) => {
        setOpenHeatmapDialog(true);
        setOriginalImage(item.resource_url);

        console.log('Calling backend with URL:', item.resource_url);

        //const originalImage = item.resource_url;

        const response = await FetchHeatmapURL({ url: item.resource_url, user_id: currentUser.user_id });

        //console.log('Backend response:', response);

        if (response && response.statusCode === 200) {
            //console.log('Heatmap URL set:', response.message.s3_url);
            setHeatmap(response);
        } else {
            setHeatmap('error');
        }
    };
    const handleCloseHeatmapDialog = () => {
        setOpenHeatmapDialog(false);
        setHeatmap();
    };

    // ImageDescription Sec
    const [openImageDescriptionDialog, setOpenImageDescriptionDialog] = useState(false);
    const [ImageDescription, setImageDescription] = useState();

    const handleOpenImageDescriptionDialog = async (e, item) => {
        setOriginalImage(item.resource_url);
        //  e.stopPropagation();

        console.log('handleOpenImageDescriptionDialog => item:', item);
        setOpenImageDescriptionDialog(true);
        const result = await FetchAIRecommend({ url: item.resource_url });
        console.log('handleOpenImageDescriptionDialog => result:', result);
        // console.log('handleOpenPreviewMediaDialog => result:', result);
        if (result && result.statusCode === 200) {
            let cleanedMessage = result.message.replace(/```json\n/, '').replace(/\n```/, '');
            let cleanedMessage_json = cleanedMessage.replace(/^\[/, '{').replace(/\]$/, '}');

            const res = JSON.parse(cleanedMessage_json);
            console.log('handleOpenImageDescriptionDialog => res:', res);

            // 访问数据
            console.log(res['Strengths']);
            console.log(res['Areas for Improvement']);
            console.log(res['Overall Effectiveness']);
            setImageDescription(res);
        } else {
            setImageDescription('System busy, please try again!');
        }
    };
    const handleCloseImageDescriptionDialog = () => {
        setOpenImageDescriptionDialog(false);
        setImageDescription();
    };

    // Upscale Sec
    const [openUpscaleDialog, setopenUpscaleDialog] = useState(false);
    const [upscale, setupscale] = useState();
    const [upscaleError, setupscaleError] = useState();

    const handleOpenUpscaleDialog = async (e, item) => {
        setOriginalImage(item.resource_url);
        setopenUpscaleDialog(false); // Close dialog initially

        console.log('handleOpenUpscaleDialog => item:', item);

        // Load the image to check dimensions
        const img = new Image();
        img.src = item.resource_url;

        img.onload = async () => {
            // Check dimensions
            if (img.width > 2560 || img.height > 2560) {
                console.log('Image dimensions exceed the limit.');
                setupscaleError(
                    'The source image exceeds the maximum dimensions of 2560x2560. Upgrade to Designer to unlock this feature.',
                );
                return;
            }

            // If dimensions are within limits, proceed
            setopenUpscaleDialog(true);
            const result = await SaltUpscaleImage({
                url: item.resource_url,
            });
            console.log('handleOpenUpscaleDialog => result:', result);

            if (result && (result.statusCode === 201 || result.statusCode === 200)) {
                setupscale(result);
                console.log('success exc id', result);
            } else {
                setupscale('System busy, please try again!');
            }
        };

        img.onerror = () => {
            console.error('Failed to load the image.');
            setupscaleError('Failed to load the image. Please check the URL or try again.');
        };
    };
    const handleCloseUpscaleDialog = () => {
        setopenUpscaleDialog(false);
        setupscale();
    };

    useEffect(() => {
        // Set the cookie when the component mounts
        document.cookie = 'Set-Cookie: __Host-example=34d8g; SameSite=None; Secure; Path=/; Partitioned;';

        // Optionally, you might want to clean up the cookie when the component unmounts
        return () => {
            // Clear the cookie when the component unmounts (if needed)
            document.cookie =
                'myCookie=; expires=Thu, 01 Jan 1970 00:00:00 GMT; SameSite=None; Secure; Path=/; Partitioned;';
        };
    }, []); // The empty dependency array ensures that the effect runs only once when the component mounts

    return (
        <ImageList
            gap={10}
            cols={screenSize < 1512 ? (screenSize < 970 ? (screenSize < 700 ? 1 : 2) : 3) : 4}
            rowHeight={121}
        >
            {item.folder_type === 2 ? (
                currentUser.user_level === 0 && (
                    <div
                        className="cursor-pointer upload-div flex flex-col align-middle justify-center h-[250px] w-[250px]"
                        style={{
                            borderRadius: '8px',
                            backgroundColor: 'rgba(229,236,246, 0.15)',
                        }}
                        onClick={handleOpenUploadDialog}
                    >
                        <div className="flex justify-center m-2">
                            <TbCloudUpload size={70} />
                        </div>
                        <div className="flex justify-center">
                            <p className="font-semi">Upload</p>
                        </div>
                    </div>
                )
            ) : (
                <div
                    className="cursor-pointer upload-div flex flex-col align-middle justify-center h-[250px] w-[250px]"
                    style={{
                        borderRadius: '8px',
                        backgroundColor: 'rgba(229,236,246, 0.15)',
                        height: '250px !important',
                        width: '250px !important',
                    }}
                    onClick={handleOpenUploadDialog}
                >
                    <div className="flex justify-center m-2">
                        <TbCloudUpload size={70} />
                    </div>
                    <div className="flex justify-center">
                        <p className="font-semi">Upload</p>
                    </div>
                </div>
            )}
            {chosenfolderMedia?.length > 0 &&
                chosenfolderMedia?.map((media, index) => (
                    <ImageListItem
                        key={media.resource_url + index}
                        sx={{
                            // border: '2px solid rgba(18, 188, 215, 0.2)',
                            borderRadius: '8px',
                            cursor: 'pointer',
                            '&:hover': {
                                backgroundColor: 'rgba(229,236,246, 0.4)',
                            },
                            backgroundColor: 'rgb(248, 249, 249)',
                            minHeight: '250px',
                            height: '250px',
                            maxWidth: '250px',
                        }}
                    >
                        <div
                            className="flex h-full w-full justify-center items-center mt-10"
                            onClick={(e) => handleOpenPreviewMediaDialog(e, media)}
                        >
                            {media.resource_type === 'image' ? (
                                <LazyLoad>
                                    <img
                                        // srcSet={`${media.resource_url}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                        style={{ maxHeight: '160px' }}
                                        src={media.resource_url}
                                        loading="lazy"
                                        className="max-w-full items-center p-3"
                                    />
                                </LazyLoad>
                            ) : (
                                <LazyLoad>
                                    <video
                                        // srcSet={`${media.resource_url}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                        style={{ height: '200px' }}
                                        loading="lazy"
                                        className="max-h-full max-w-full items-center p-3"
                                        controls
                                        controlsList="nodownload noremoteplayback nofullscreen noplaybackrate" // Remove download button
                                        disablePictureInPicture // Disable Picture-in-Picture mode
                                        disableRemotePlayback // Disable remote playback
                                        playsInline // Ensure the video is played inline on mobile devices
                                        src={media.resource_url}
                                    />
                                </LazyLoad>
                            )}
                        </div>

                        <ImageListItemBarCenter
                            title={media.resource_name}
                            position="below"
                            sx={{
                                height: '30px',
                                fontSize: '14px',
                            }}
                        />
                        {
                            (item.folder_type !== 2 ||
                                (item.folder_type === 2 && currentUser.user_level === 0)) && (
                                <ImageListItemBar
                                    sx={{
                                        background: 'transparent',
                                        padding: '6px',
                                    }}
                                    title={
                                        media.resource_type === 'image' && (
                                            <>
                                                <Tooltip title="Everyday Recommendation" arrow>
                                                    <IconButton
                                                        sx={{
                                                            color: 'white',
                                                            zIndex: 1000,
                                                        }}
                                                        aria-label={`star ${media.resource_name}`}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleOpenRecommendDialog(e, media, 'media');
                                                        }}
                                                        // onClick={(e) => handleOpenDeleteDialog(e, media, 'media')}
                                                    >
                                                        <Lightbulb
                                                            fontSize="small"
                                                            sx={{ color: '#FFA000' }}
                                                        />
                                                    </IconButton>
                                                </Tooltip>

                                                <Tooltip title="Attention Heatmap" arrow>
                                                    <IconButton
                                                        sx={{
                                                            color: 'white',
                                                            zIndex: 1000,
                                                        }}
                                                        aria-label={`star ${media.resource_name}`}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleOpenHeatmapDialog(e, media, 'media');
                                                        }}
                                                        // onClick={(e) => handleOpenHeatmapDialog(e, media, 'media')}
                                                    >
                                                        <Equalizer
                                                            fontSize="medium"
                                                            sx={{ color: '#FF6347' }}
                                                        />
                                                    </IconButton>
                                                </Tooltip>

                                                <Tooltip title="Content Evaluation" arrow>
                                                    <IconButton
                                                        sx={{
                                                            color: 'white',
                                                            zIndex: 1000,
                                                        }}
                                                        aria-label={`star ${media.resource_name}`}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleOpenImageDescriptionDialog(
                                                                e,
                                                                media,
                                                                'media',
                                                            );
                                                        }}
                                                        // onClick={(e) => handleOpenDeleteDialog(e, media, 'media')}
                                                    >
                                                        <RateReview fontSize="small" color="primary" />
                                                    </IconButton>
                                                </Tooltip>

                                                <Tooltip title="Super Resolution Upscale" arrow>
                                                    <IconButton
                                                        sx={{
                                                            color: 'white',
                                                            zIndex: 1000,
                                                        }}
                                                        aria-label={`star ${media.resource_name}`}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleOpenUpscaleDialog(e, media, 'media');
                                                        }}
                                                        // onClick={(e) => handleOpenDeleteDialog(e, media, 'media')}
                                                    >
                                                        <FourKPlusIcon
                                                            fontSize="small"
                                                            style={{ color: 'green' }}
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            </>
                                        )
                                    }
                                    position="top"
                                    actionIcon={
                                        <IconButton
                                            sx={{
                                                color: 'white',
                                                zIndex: '1000',
                                            }}
                                            aria-label={`star ${media.resource_name}`}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleOpenDeleteDialog(e, media, 'media');
                                            }}
                                            // onClick={(e) => handleOpenDeleteDialog(e, media, 'media')}
                                        >
                                            <GrClose size={14} />
                                        </IconButton>
                                    }
                                    actionPosition="right"
                                />
                            )
                            // : (
                            //     <></>
                            // )
                        }
                    </ImageListItem>
                ))}
            <PreviewMediaDialog
                openPreviewMediaDialog={openPreviewMediaDialog}
                handleClosePreviewMediaDialog={handleClosePreviewMediaDialog}
                previewMedia={previewMedia}
            />
            <ImageDescriptionDialog
                ImageDescription={ImageDescription}
                originalImage={originalImage}
                // handleOpenRecommendDialog={handleOpenRecommendDialog}
                handleCloseImageDescriptionDialog={handleCloseImageDescriptionDialog}
                openImageDescriptionDialog={openImageDescriptionDialog}
            />

            <HeatmapDialog
                Heatmap={heatmap}
                originalImage={originalImage}
                // handleCloseHeatmapDialog={handleOpenRecommendDialog}
                handleCloseHeatmapDialog={handleCloseHeatmapDialog}
                openHeatmapDialog={openHeatmapDialog}
            />
            {openRecommendDialog && (
                <RecommendImageDialog
                    recommend={recommend}
                    // handleOpenRecommendDialog={handleOpenRecommendDialog}
                    handleCloseRecommendDialog={handleCloseRecommendDialog}
                    openRecommendDialog={openRecommendDialog}
                />
            )}
            <UpscaleImageDialog
                upscale={upscale}
                originalImage={originalImage}
                // handleOpenRecommendDialog={handleOpenRecommendDialog}
                handleCloseUpscaleDialog={handleCloseUpscaleDialog}
                openUpscaleDialog={openUpscaleDialog}
            />
            {upscaleError && (
                <Snackbar
                    open={true}
                    autoHideDuration={6000}
                    onClose={() => setupscaleError(null)}
                    message={upscaleError}
                />
            )}
        </ImageList>
    );
};

export default ImageListSection;
