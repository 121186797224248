import Blogs1 from "./../blogs/component/Blogs1";
import React, { useEffect, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';


function CaseStudy() {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);

  const handleClick = () => {
    if (!email.trim()) {
      setError(true);
      return
    }
    setError(true);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [isCSSLoaded, setIsCSSLoaded] = useState(false);
  useEffect(() => {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = '/main.min.purge.css';

    link.onload = () => setIsCSSLoaded(true);
    document.head.appendChild(link);

    return () => {
        document.head.removeChild(link);
    };
  }, []);
  return (
    <div style={{ visibility: isCSSLoaded ? 'visible' : 'hidden' }}>
      <div className="page-wrapper uni-body panel bg-white text-gray-900 dark:bg-gray-900 dark:text-gray-200 overflow-x-hidden bp-xs bp-sm bp-md bp-lg bp-xl bp-xxl dom-ready">
        <div id="wrapper" className="wrap">
          {/* blog区域 */}
          <Blogs1 />
          {/* Get the latest updates */}
          <div
        id="blog_newsletter"
        className="blog-newsletter section panel overflow-hidden"
      >
        <div className="section-outer panel pb-4 lg:pb-6 xl:pb-9">
          <div className="container max-w-xl">
            <div className="section-inner panel p-3 py-6 lg:p-6 xl:p-8 rounded-2 bg-secondary dark:bg-gray-800 overflow-hidden">
              <div
                className="row child-cols-12 md:child-cols g-6 justify-between items-center"
                data-uc-grid=""
              >
                <div>
                  <div className="vstack gap-2 max-w-500px xl:max-w-600px">
                    <h2 className="h4 md:h3 lg:h2 m-0">Get the latest updates</h2>
                    <p className="fs-6 lg:fs-5">
                      Subscribe to get our most-popular digital signage solutions!
                    </p>
                    <form
                      onSubmit={(e) => e.preventDefault()}
                      className="row child-cols g-1 mt-1 xl:mt-2"
                    >
                      <div>
                        <input
                          className="form-control h-48px xl:h-56px w-full bg-white dark:border-white dark:bg-opacity-10 dark:border-opacity-0 dark:text-white"
                          type="text"
                          placeholder="Your email address"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </div>
                      <div className="col-12 sm:col-auto">
                        <button className="btn btn-md h-48px xl:h-56px w-100 lg:min-w-150px xl:min-w-200px btn-primary text-white" onClick={handleClick}>
                          Subscribe
                        </button>
                      </div>
                    </form>
                    <p className="fs-7 text-dark dark:text-white text-opacity-70">
                      Don't worry we don't spam.
                    </p>
                  </div>
                </div>
                <div className="md:col-auto d-none md:d-block">
                  <img
                    className="w-250px lg:w-300px xl:w-400px d-block dark:d-none"
                    alt="newsletter"
                    src="https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/MainPage/icon_app.png"
                    width="500"
                    height="372"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
          <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          >
            <Alert
              icon={<CheckIcon fontSize="inherit" />}
              severity="success"
            >
              You have succesfully subscribed our blogs!
            </Alert>
          </Snackbar>
        </div>
      </div>
    </div>
  );
}

export default CaseStudy;