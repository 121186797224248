// import { features6 } from "./../data/features";
import React from "react";

export default function Hero() {
  return (
    <div id="hero_header" className="hero-header section panel overflow-hidden">
      <div
        className="position-absolute top-0 start-0 end-0 min-h-screen overflow-hidden d-none lg:d-block"
        data-anime="targets: >*; scale: [0, 1]; opacity: [0, 1]; easing: spring(1, 80, 10, 0); duration: 450; delay: anime.stagger(100, {start: 750});"
      >
        <div
          className="position-absolute rotate-45"
          style={{
            top: "30%",
            left: "18%",
          }}
        >
          <img
            className="w-32px text-gray-900 dark:text-white"
            src="/images/template/star-1.svg"
            width={193}
            height={216}
            alt="star-1"
            data-uc-svg=""
          />
        </div>
        <div
          className="position-absolute rotate-45"
          style={{ top: "15%", right: "18%" }}
        >
          <img
            className="w-24px text-gray-900 dark:text-white"
            src="/images/template/star-2.svg"
            width={69}
            height={95}
            alt="star-2"
            data-uc-svg=""
          />
        </div>
      </div>
      <div className="section-outer panel pt-9 lg:pt-10 pb-6 xl:pb-9">
        <div className="container max-w-lg">
          <div className="section-inner panel mt-2 sm:mt-4 lg:mt-0">
            {/* <div
              className="panel vstack items-center gap-3 lg:gap-4 mb-6 sm:mb-8 lg:mb-9 max-w-650px mx-auto text-center"
              data-anime="targets: >*; translateY: [48, 0]; opacity: [0, 1]; easing: spring(1, 80, 10, 0); duration: 450; delay: anime.stagger(100, {start: 200});"
            >
              <h1 className="h2 sm:h1 lg:display-6 xl:display-5 m-0">
                Benefits of Using Digital Signage in Real Estate Offices
              </h1>
              <p className="fs-6 sm:fs-5 text-dark dark:text-white">
                Digital signage in real estate offices enhances both property visibility and customer engagement. Agencies using digital signage report increased property inquiries and higher sales, as these displays showcase homes through rotating images and vibrant videos, drawing more attention from potential buyers. A passerby glancing at a high-brightness window display can be instantly drawn in by the stunning visuals of a featured property.
              </p>
            </div> */}

            {/* 无图的section */}
            <div className="sticky-scene panel vstack gap-4 sm:gap-6 xl:gap-8">
                <div
                  className="feature-item panel px-3 lg:px-4 py-4 rounded-2 bg-secondary dark:bg-gray-800"
                  data-anime="onview: -200; translateY: [48, 0]; opacity: [0, 1]; easing: spring(1, 80, 10, 0); duration: 450; delay: 100;"
                >
                  <div className="panel vstack justify-center gap-4 h-100">
                      <div className="panel vstack gap-2">
                        <h3 className="h4 lg:h2 m-0">• Instant Property Listings Updates</h3>
                        <p className="fs-6 lg:fs-5 dark:opacity-80">
                          One of the standout features of digital signage is its ability to instantly update property listings. By leveraging AI-generated content, agencies can efficiently create and manage engaging displays that ensure property information remains current and visually appealing. Using cloud-based dashboards or dedicated apps, agencies can streamline their content updates, maintaining viewer interest and enhancing engagement.
                        </p>
                        <p className="fs-6 lg:fs-5 dark:opacity-80">
                          In competitive markets, timely updates and dynamic content generation are crucial. By incorporating AI to generate rotating property information and branding, agencies can keep their digital displays fresh and relevant, ensuring a more effective connection with their audience.
                        </p>
                      </div>
                  </div>
                </div>
            </div>

            <div className="sticky-scene panel vstack gap-4 sm:gap-6 xl:gap-8 mt-8">
                <div
                  className="feature-item panel px-3 lg:px-4 py-4 rounded-2 bg-secondary dark:bg-gray-800"
                  data-anime="onview: -200; translateY: [48, 0]; opacity: [0, 1]; easing: spring(1, 80, 10, 0); duration: 450; delay: 100;"
                >
                  <div className="panel vstack justify-center gap-4 h-100">
                      <div className="panel vstack gap-2">
                        <h3 className="h4 lg:h2 m-0">• Enhanced Customer Interactivity</h3>
                        <p className="fs-6 lg:fs-5 dark:opacity-80">
                          Interactive digital signs elevate customer engagement by allowing potential buyers to explore property portfolios in detail. Touch-enabled digital displays let users browse through property images, videos, and information at their own pace, significantly boosting engagement. A client using a touchscreen kiosk to view a 3D layout of a property in a real estate office experiences an immersive interaction that can lead to quicker decision-making.
                        </p>
                        <p className="fs-6 lg:fs-5 dark:opacity-80">
                          Outdoor kiosks with touch-enabled displays offer an engaging way for users to interact with websites and online forms, enhancing mobility within real estate offices.
                        </p>
                      </div>
                  </div>
                </div>
            </div>

            <div className="sticky-scene panel vstack gap-4 sm:gap-6 xl:gap-8 mt-8">
                <div
                  className="feature-item panel px-3 lg:px-4 py-4 rounded-2 bg-secondary dark:bg-gray-800"
                  data-anime="onview: -200; translateY: [48, 0]; opacity: [0, 1]; easing: spring(1, 80, 10, 0); duration: 450; delay: 100;"
                >
                  <div className="panel vstack justify-center gap-4 h-100">
                      <div className="panel vstack gap-2">
                        <h3 className="h4 lg:h2 m-0">• Sustainable Marketing Practices</h3>
                        <p className="fs-6 lg:fs-5 dark:opacity-80">
                          Digital signage is not only effective but also promotes sustainable marketing practices. Minimizing reliance on printed materials significantly reduces paper waste, leading to a lower environmental impact. This eco-friendly approach is crucial in today's business environment, where sustainability is a growing priority.
                        </p>
                        <p className="fs-6 lg:fs-5 dark:opacity-80">
                          Adopting digital signage contributes to sustainable advertising practices, aligning with broader business goals of minimizing environmental impact. This shift benefits the environment and resonates with clients who value sustainability, enhancing the brand image of the real estate business.
                        </p>
                      </div>
                  </div>
                </div>
            </div>


            {/* <div className="sticky-scene panel vstack gap-4 sm:gap-6 xl:gap-8">
              {features6.map((feature) => (
                <div
                  key={feature.id}
                  className="feature-item panel px-3 lg:px-4 py-4 rounded-2 bg-secondary dark:bg-gray-800"
                  data-anime="onview: -200; translateY: [48, 0]; opacity: [0, 1]; easing: spring(1, 80, 10, 0); duration: 450; delay: 100;"
                >
                  <div className="row child-cols col-match justify-between g-4 lg:g-8 xl:g-10">
                    <div className="order-0 lg:order-1">
                      <div className="panel w-100 rounded lg:rounded-2 overflow-hidden">
                        <img
                          src={feature.imgSrc}
                          width={780}
                          height={728}
                          alt={feature.imgAlt}
                        />
                      </div>
                    </div>
                    <div className="order-1 lg:order-0 col-12 sm:col-5">
                      <div className="panel vstack justify-center gap-4 h-100">
                        <div>
                          <div className="panel vstack gap-2">
                            <span className="fs-6 fw-bold m-0 text-primary">
                              {feature.id.toString().padStart(2, "0")}.
                            </span>
                            <h3 className="h4 lg:h2 m-0">{feature.title}</h3>
                            <p className="fs-6 lg:fs-5 opacity-70 dark:opacity-80">
                              {feature.description}
                            </p>
                            {feature.link && (
                              <a
                                href={feature.link.href}
                                className="uc-link fw-bold hstack gap-narrow"
                              >
                                <span>{feature.link.text}</span>
                                <i className="position-relative icon icon-1 unicon-arrow-right rtl:rotate-180 translate-y-px" />
                              </a>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
