import {
    Box,
    Button,
    Container,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import { Email, LocationOn } from '@mui/icons-material';
import React, { useState } from 'react';

import axios from 'axios';
import config from '../config/config';

const baseURL = config[process.env.REACT_APP_ENV].REACT_APP_API_URL;

const ContactUs = () => {
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        subject: '',
        message: '',
        source_where: 'website', // Default value for the dropdown
    });

    const [responseMessage, setResponseMessage] = useState('');

    // Function to handle form input changes
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    // Function to handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            console.log('baseURL', baseURL);

            const response = await axios.post(baseURL + 'tools/contact_us/submit', formData);
            console.log('response', response);
            setResponseMessage('Message sent successfully!');
        } catch (error) {
            setResponseMessage('Failed to send message.');
        }
    };

    return (
        <div className="max-w-screen-lg mx-auto my-10">
            {/* Section 1 */}
            <div className="mb-8">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    <div className="flex flex-col justify-center text-center md:text-left">
                        <h1 className="text-3xl font-semibold mb-4">We offer 24/7 support</h1>
                        <p className="text-base">
                            Contact us today for more information or to request a demo.
                        </p>
                    </div>
                    <div>
                        <img
                            src="https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/MainPage/D1.jpg"
                            alt="Get in touch"
                            className="w-full h-auto object-cover"
                        />
                    </div>
                </div>
            </div>

            {/* Section 2 */}
            <div className="mb-8">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    <div className="flex flex-col justify-center">
                        <h1 className="text-3xl font-semibold mb-4">Get in Touch</h1>
                        <p className="text-base mb-4">
                            Have any questions or need assistance? We're here to help!
                        </p>
                        <div className="flex items-center mb-4">
                            <Email fontSize="small" />
                            <span className="ml-2 text-sm">
                                <strong>Technical Support:</strong>{' '}
                                <a href="mailto:support@signinspire.com" className="text-blue-500">
                                    support@signinspire.com
                                </a>
                            </span>
                        </div>
                        <div className="flex items-center mb-4">
                            <Email fontSize="small" />
                            <span className="ml-2 text-sm">
                                <strong>Sale Enquiry:</strong>{' '}
                                <a href="mailto:sale@signinspire.com" className="text-blue-500">
                                    sale@signinspire.com
                                </a>
                            </span>
                        </div>
                    </div>

                    {/* Form Section */}
                    <div>
                        <form className="space-y-4" noValidate autoComplete="off" onSubmit={handleSubmit}>
                            <input
                                type="text"
                                name="first_name"
                                value={formData.first_name}
                                onChange={handleChange}
                                required
                                placeholder="First Name"
                                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500"
                            />
                            <input
                                type="text"
                                name="last_name"
                                value={formData.last_name}
                                onChange={handleChange}
                                required
                                placeholder="Last Name"
                                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500"
                            />
                            <input
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                                placeholder="Email"
                                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500"
                            />
                            <input
                                type="text"
                                name="subject"
                                value={formData.subject}
                                onChange={handleChange}
                                required
                                placeholder="Subject"
                                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500"
                            />
                            <textarea
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                required
                                placeholder="Message"
                                rows="4"
                                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500"
                            ></textarea>
                            <div>
                                <label
                                    htmlFor="source_where"
                                    className="block mb-2 text-sm font-medium text-gray-700"
                                >
                                    Where Source
                                </label>
                                <select
                                    id="source_where"
                                    name="source_where"
                                    value={formData.source_where}
                                    onChange={handleChange}
                                    required
                                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                                >
                                    <option value="website">Website</option>
                                    <option value="google">Google</option>
                                    <option value="facebook">Facebook</option>
                                    <option value="twitter">Twitter</option>
                                    <option value="linkedin">LinkedIn</option>
                                    <option value="others">Others</option>
                                </select>
                            </div>
                            <button
                                type="submit"
                                className="w-full py-2 px-4 border-blue-500 bg-blue-500 rounded-md hover:bg-blue-600 text-white"
                            >
                                Submit
                            </button>
                        </form>
                        {responseMessage && (
                            <p className="mt-4 text-base text-green-600">{responseMessage}</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactUs;
