import White from './component/Education/White';
import TextArea3 from "./component/Education/TextArea3";
import Cta from './component/Education/Cta';
import Features from './component/Education/Features';
import Hero from './component/Education/Hero';
import Paragraph2 from "./component/Education/Paragraph2";
import TextArea from './component/Education/TextArea';
import Section from './component/Education/Section';
import Paragraph from './component/Education/Paragraph';
import About2 from "./component/Education/About2";
import Features2 from "./component/Education/Features2";
import About3 from "./component/Education/About3";
import Sldies from "./component/Education/Slides";
import React, { useEffect, useState } from 'react';


export default function Education() {
  const [isCSSLoaded, setIsCSSLoaded] = useState(false);
    useEffect(() => {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = '/main.min.purge.css';
        link.onload = () => setIsCSSLoaded(true);
        document.head.appendChild(link);
    
        return () => {
            document.head.removeChild(link);
        };
    }, []);
  return (
    <div style={{ visibility: isCSSLoaded ? 'visible' : 'hidden' }}>
      <div className="uni-body panel bg-white text-gray-900 dark:bg-gray-900 dark:text-gray-200 overflow-x-hidden bp-xs bp-sm bp-md bp-lg bp-xl bp-xxl-max dom-ready">
        {/* <Header2 /> */}
        <div id="wrapper" className="wrap">
          {/* 标题区域 */}
          <Hero />
          <White />
          <TextArea3 />
          <Paragraph />
          <div className="pt-6 xl:pt-9"></div>
          <Sldies />
          <About2 />
          <Features />
          <Features2 />
          <div className="pt-6 xl:pt-9"></div>
          <Paragraph2 />
          <TextArea />
          <div className="pt-6 xl:pt-9"></div>
          <About3 />
          <Section />
          <Cta />
        </div>
      </div>
    </div>
  );
}
