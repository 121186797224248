import Cta from './component/Restaurant/Cta';
import Hero from './component/Restaurant/Hero';
import Paragraph2 from "./component/Restaurant/Paragraph2";
import TextArea from './component/Restaurant/TextArea';
import Section from './component/Restaurant/Section';
import Paragraph from './component/Restaurant/Paragraph';
import About2 from "./component/Restaurant/About2";
import Features2 from "./component/Restaurant/Features2";
import About3 from "./component/Restaurant/About3";
import White from './component/Restaurant/White';
import Green from './component/Restaurant/Green';
import TextArea2 from './component/Restaurant/TextArea2'
import React, { useEffect, useState } from 'react';

export default function HomePage2() {
  const [isCSSLoaded, setIsCSSLoaded] = useState(false);
    useEffect(() => {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = '/main.min.purge.css';
        link.onload = () => setIsCSSLoaded(true);
        document.head.appendChild(link);
    
        return () => {
            document.head.removeChild(link);
        };
    }, []);
  return (
    <div style={{ visibility: isCSSLoaded ? 'visible' : 'hidden' }}>
      <div className="uni-body panel bg-white text-gray-900 dark:bg-gray-900 dark:text-gray-200 overflow-x-hidden bp-xs bp-sm bp-md bp-lg bp-xl bp-xxl-max dom-ready">
        {/* <Header2 /> */}
        <div id="wrapper" className="wrap">
          {/* blog标题 */}
          <Hero />
          {/* 紧跟一级标题的文字段落 */}
          <White />
          {/* 标题 + 单个段落的文字 */}
          <Green />
          {/* 标题 + 4个分点 */}
          <TextArea />
          {/* 标题 + 2个段落（无分点） */}
          <About2 />
          {/* 标题 + 5个分点 */}
          <TextArea2 />
          {/* 标题 + 3个段落 */}
          <Paragraph2 />
          {/* 标题 + 2个段落 */}
          {/* 标题 + 2个段落 */}
          {/* 标题 + 2个段落 */}
          <Section />
          {/* 标题 + 5个分点 */}
          <Paragraph />
          {/* 标题 + 2个段落 */}
          <Features2 />
          {/* 标题 + 2个段落 */}
          <div className="pt-6 xl:pt-9"></div>
          <About3 />
          {/* <div className="pt-6 xl:pt-9"></div> */}
          {/* <Faq /> */}
          <Cta />
        </div>
      </div>
    </div>
  );
}
