import React from "react";

export default function Paragraph2() {
  return (
    <div
      id="key_features"
      className="key-features section panel overflow-hidden bg-gray-900 uc-dark"
    >
      <div className="section-outer panel py-6 xl:py-9 dark:bg-gray-800">
        <div className="container sm:max-w-md lg:max-w-lg">
          <div className="section-inner panel">
            <div className="panel vstack gap-4 sm:gap-6 xl:gap-8">
              <h2
                className="title h3 lg:h2 xl:h1 m-0 text-center max-w-550px mx-auto"
                data-anime="onview: -100; translateY: [48, 0]; opacity: [0, 1]; easing: spring(1, 80, 10, 0); duration: 450; delay: 250;"
              >
                <span className="text-primary">Measuring the Impact of Digital Signage</span>
              </h2>
              <p className="desc fs-6 dark:text-white">
                To understand how well your digital signage content is working, it's important to measure its impact. Start by tracking engagement metrics like views, clicks, and interactions. These numbers can show you which content is catching attention and which areas may need improvement.
              </p>
              <p className="desc fs-6 dark:text-white">
                Another helpful method is gathering feedback directly from students, staff, and visitors. Conduct simple surveys to ask them how digital signage has affected their experience at school. This feedback can give you valuable insights into what's working and what can be improved.
              </p>
              <p className="desc fs-6 dark:text-white">
                Data and analytics play a key role in refining your strategy. Use the information you collect to adjust your content, making it more engaging and effective over time. For example, if videos get more attention than text, you might include more video content.
              </p>
              <p className="desc fs-6 dark:text-white">
                Finally, monitor the broader effects of digital signage on the school environment. Look at how it impacts student motivation, engagement, and the overall learning experience. These insights can help your school make the most of digital signage.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
