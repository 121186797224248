import { useState } from "react";

export default function Features() {
  const [activeTab, setActiveTab] = useState(1);
  return (
    <div
      id="main_features"
      className="main-features section panel overflow-hidden"
    >
      <div className="section-outer panel pb-6 xl:pb-9">
        <div className="container max-w-xl lg:max-w-lg">
          <div
            className="section-inner panel"
            data-anime="onview: -100; targets: >*; translateY: [48, 0]; opacity: [0, 1]; easing: spring(1, 80, 10, 0); duration: 450; delay: anime.stagger(100, {start: 100});"
          >
            <div className="panel vstack items-center gap-2 xl:gap-3 mb-4 sm:mb-8 xl:mb-9 sm:max-w-650px xl:max-w-800px mx-auto text-center">
              <h2 className="h3 lg:h2 xl:h1 m-0">
                Improving Patient Experience
              </h2>
              <p className="fs-6 xl:fs-5 text-dark dark:text-white text-opacity-70">
              Enhancing the patient experience is a top priority for healthcare providers. Digital signage solutions offer a powerful tool to achieve this by providing real-time updates and flexible content management.
              </p>
            </div>
            <div className="panel vstack gap-4 xl:gap-6">
              <div className="panel px-3 rounded-2 bg-secondary dark:bg-gray-800">
                <ul
                  className="main-features-nav hstack text-center overflow-auto"
                  data-uc-switcher="connect: .main-features-switcher; active: 1; animation: uc-animation-fade;"
                  role="tablist"
                >
                  <li
                    className={`${activeTab == 1 ? "uc-active" : ""}`}
                    onClick={() => setActiveTab(1)}
                    role="presentation"
                  >
                    <a className="h6 lg:h5 text-nowrap m-0">Improving Patient Experience</a>
                  </li>
                  <li
                    onClick={() => setActiveTab(2)}
                    className={`${activeTab == 2 ? "uc-active" : ""}`}
                    role="presentation"
                  >
                    <a className="h6 lg:h5 text-nowrap m-0">Queue Management</a>
                  </li>
                  <li
                    className={`${activeTab == 3 ? "uc-active" : ""}`}
                    onClick={() => setActiveTab(3)}
                    role="presentation"
                  >
                    <a className="h6 lg:h5 text-nowrap m-0">
                      Entertainment and Education
                    </a>
                  </li>
                  
                </ul>
              </div>
              <div
                className="main-features-switcher uc-switcher overflow-hidden"
                role="presentation"
                style={{ touchAction: "pan-y pinch-zoom" }}
              >
                {activeTab == 1 && (
                  <div className="uc-active">
                    <div className="feature-item panel">
                      <div className="row child-cols col-match justify-between g-4">
                        <div className="col-12 sm:col-6">
                          <figure className="featured-image m-0 rounded ratio ratio-1x1 rounded-2 overflow-hidden">
                            <img
                              className="media-cover image"
                              alt="healthcare"
                              src="https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/health_care/hh12.png"
                              width="1200"
                              height="1200"
                            />
                          </figure>
                        </div>
                        <div className="col-12 sm:col-6">
                          <div className="panel">
                            <div className="panel vstack justify-center gap-4 h-100 sm:p-3 lg:p-4">
                              <div>
                                <div className="panel vstack gap-2">
                                  {/* <h3 className="h4 sm:h3 lg:h2 m-0">
                                    Share tools quickly and confidently in
                                    minutes
                                  </h3> */}
                                  <p className="fs-6 lg:fs-5 opacity-70 dark:opacity-80">
                                    Clear information and reduced perceived wait times through digital signage significantly enhance patient satisfaction. Hospitals using digital displays have reported higher patient satisfaction scores compared to those relying on traditional communication methods.
                                  </p>
                                  <p className="fs-6 lg:fs-5 opacity-70 dark:opacity-80">
                                    The ability to manage and update content remotely allows healthcare facilities to tailor their messaging to different audience needs, further enhancing the patient experience. Whether it's through queue management or providing entertainment and educational content, digital signage helps create a more engaging and informative environment for patients.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {activeTab == 2 && (
                  <div className="uc-active">
                    <div className="feature-item panel">
                      <div className="row child-cols col-match justify-between g-4">
                        <div className="col-12 sm:col-6">
                          <figure className="featured-image m-0 rounded ratio ratio-1x1 rounded-2 overflow-hidden">
                            <img
                              className="media-cover image"
                              alt="healthcare"
                              src="https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/health_care/hh13.png"
                              width="1200"
                              height="1200"
                            />
                          </figure>
                        </div>
                        <div className="col-12 sm:col-6">
                          <div className="panel">
                            <div className="panel vstack justify-center gap-4 h-100 sm:p-3 lg:p-4">
                              <div>
                                <div className="panel vstack gap-2">
                                  <p className="fs-6 lg:fs-5 opacity-70 dark:opacity-80">
                                    Managing patient flow efficiently is crucial in healthcare settings. Digital signage can help by providing real-time updates on wait times and appointment statuses, effectively managing perceived wait times for patients. Automated calling displays can trigger when clinicians are ready for patients, ensuring a smooth and organized flow.
                                  </p>
                                  <p className="fs-6 lg:fs-5 opacity-70 dark:opacity-80">
                                    Integrating digital signage with scheduling systems allows healthcare facilities to synchronize patient flow and provide timely updates, improving overall efficiency. This not only enhances the patient experience but also reduces anxiety and frustration in waiting rooms.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {activeTab == 3 && (
                  <div className="uc-active">
                    <div className="feature-item panel">
                      <div className="row child-cols col-match justify-between g-4">
                        <div className="col-12 sm:col-6">
                          <figure className="featured-image m-0 rounded ratio ratio-1x1 rounded-2 overflow-hidden">
                            <img
                              className="media-cover image"
                              alt="healthcare"
                              src="https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/health_care/hh11.png"
                              width="1200"
                              height="1200"
                            />
                          </figure>
                        </div>
                        <div className="col-12 sm:col-6">
                          <div className="panel">
                            <div className="panel vstack justify-center gap-4 h-100 sm:p-3 lg:p-4">
                              <div>
                                <div className="panel vstack gap-2">
                                  <p className="fs-6 lg:fs-5 opacity-70 dark:opacity-80">
                                  While patients wait for their appointments, digital displays can provide educational videos and health tips, keeping them informed and engaged. Additionally, entertainment content such as calming nature videos or other engaging materials can help patients relax and feel more comfortable. By combining educational and entertaining content, healthcare facilities can manage patient perceptions of wait times and improve their overall experience.
                                  </p>
                                  <p className="fs-6 lg:fs-5 opacity-70 dark:opacity-80">
                                  Engaging patients with content while they wait not only enhances their experience but also reinforces important health messages, contributing to better health outcomes.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
