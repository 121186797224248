export const features_realestate = [
  {
    id: 4,
    imgSrc: "https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/real_estate/r3.png",
    imgAlt: "real estate",
    title: "Enhancing visibility and engagement",
    description:
      "Digital signage is essential in real estate for enhancing property visibility and client engagement through dynamic content and real-time updates.",
  },
  {
    id: 5,
    imgSrc: "https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/real_estate/real0.png",
    imgAlt: "real estate",
    title: "Boosting interaction and immersion",
    description:
      "Interactive digital displays, such as touch kiosks and augmented reality tools, significantly increase customer engagement and facilitate immersive property exploration.",
  },
  {
    id: 6,
    imgSrc: "https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/real_estate/real1.png",
    imgAlt: "real estate",
    title: "Hardware and content management",
    description:
      "Implementing digital signage requires careful selection of hardware, effective content management, and proper installation to maximize its benefits in the competitive real estate market.",
  },
];

export const features_education= [
  {
    id: 4,
    imgSrc: "https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/education/edu2.png",
    imgAlt: "edu",
    // title: "Enhancing visibility and engagement",
    description:
    "When selecting digital signage software for your school, it's important to consider the specific needs of your institution and the layout of your facility. Every school is unique, so the software should fit your requirements, whether you need it for a single building or a sprawling campus.",
  },
  {
    id: 5,
    imgSrc: "https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/education/edu4.png",
    imgAlt: "edu",
    // title: "Boosting interaction and immersion",
    description:
    "Displaying wayfinding information is another important function. Digital signs can provide directions to specific locations, building names, and room numbers, ensuring that everyone can find their way quickly and easily. Real-time information on campus events, such as lectures, meetings, and conferences, can also be displayed, keeping everyone informed about what's happening on campus.",
  },
  {
    id: 6,
    imgSrc: "https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/education/edu3.png",
    imgAlt: "edu",
    description:
    "Digital signage can also provide information on campus amenities, such as food outlets, libraries, and recreational facilities. This helps students and visitors make the most of the resources available to them. Additionally, digital signage can promote campus safety and security by displaying emergency contact information and safety protocols. By enhancing campus navigation, digital signage helps create a more connected and efficient educational environment.",
  },
];

export const features_healthcare = [
  {
    id: 4,
    imgSrc: "https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/health_care/hh2.png",
    imgAlt: "healthcare",
    // title: "Enhancing visibility and engagement",
    description:
    "Digital signage enhances real-time communication, improving patient experience and staff productivity in healthcare settings.",
  },
  {
    id: 5,
    imgSrc: "https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/health_care/hh1.png",
    imgAlt: "healthcare",
    description:
    "Customizable content tailored to diverse audiences fosters better engagement and understanding of important health information.",
  },
  {
    id: 6,
    imgSrc: "https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/health_care/hh5.png",
    imgAlt: "healthcare",
    description:
    "Integrating digital signage with existing healthcare systems streamlines operations, reduces costs, and promotes efficiency.",
  },
];

// export const features2_realestate = [
//   {
//     icon: "unicon-document",
//     title: "Proposals",
//     description:
//       "Digital signage is now vital for real estate agents aiming to improve property visibility and client engagement. Strategically placed digital displays attract potential buyers by showcasing dynamic content, such as promotional videos and gallery images, making property listings more appealing. Unlike traditional signage, digital signs create a sense of urgency, encouraging buyers to act swiftly in competitive markets.",
//     backgroundColor: "bg-white dark:bg-gray-800",
//   },
//   {
//     icon: "unicon-model",
//     title: "Quotes",
//     description:
//       "Find out in real time when they’re opened, viewed and signed.",
//     backgroundColor: "bg-primary text-white",
//   },
//   {
//     icon: "unicon-task-approved",
//     title: "Contracts",
//     description:
//       "Use our native CRM integrations to pull customer data into your Tools fast.",
//     backgroundColor: "bg-white dark:bg-gray-800",
//   },
//   {
//     icon: "unicon-touch-interaction",
//     title: "eSignatures",
//     description:
//       "Keep your data secure with our legally-binding e-Signature software.",
//     backgroundColor: "bg-white dark:bg-gray-800",
//   },
// ];

export const features4 = [
  {
    title: "• Video Walls for Property Showcases",
    description:
      "Video walls offer an engaging platform for showcasing featured properties and upcoming events. These large displays can be configured using multiple individual screens to create a seamless visual experience, ideal for real estate offices. A stunning video wall displaying high-resolution images and videos of various properties can impress clients and capture their interest upon entering a real estate agency.",
  },
  {
    title: "• Video Walls for Property Showcases",
    description:
      "Promoting key properties and events through video walls allows real estate agencies to create a dynamic and visually appealing environment that draws in potential buyers. These real estate displays are effective for showcasing detailed property tours and highlighting unique features, making them a valuable tool in modern real estate marketing.",
  },
  {
    title: "• Interactive Kiosks",
    description:
    "Interactive kiosks serve as informative touchpoints, enhancing the experience for potential buyers by providing detailed property information. Touch-enabled displays allow users to interact with websites and online forms directly from the kiosk, facilitating better engagement. For example, outdoor touchscreen kiosks feature rugged metal all-weather enclosures, ensuring durability and reliability in various weather conditions.",
  },
  {
    title: "• Interactive Kiosks",
    description:
    "The operation of outdoor kiosks can be scheduled to automatically turn on and off, providing flexibility. Creating interactive spaces for clients to explore property details helps real estate agencies attract more attention and provide a memorable customer experience.",
  },
];

export const features4_restaurant = [
  {
    title: "• Keep It Simple",
    description:
    "Use clear and concise language and avoid cluttering the display with too much information. Simplicity ensures that your message is easily understood and more impactful.",
  },
  {
    title: "• Use High-Quality Images",
    description:
    "High-quality images and videos create visually appealing displays that attract customers. Investing in professional photography and videography can make a significant difference in the effectiveness of your digital signage.",
  },
  {
    title: "• Update Regularly",
    description:
    "Regularly update your digital signage to keep the content fresh and relevant. This not only keeps customers engaged but also ensures that your promotions and menu items are always up-to-date.",
  },
  {
    title: "• Use Analytics",
    description:
    "Utilize analytics to track the effectiveness of your digital signage. By analyzing data on customer engagement and sales, you can make informed decisions and optimize your content for better results.",
  },
  {
    title: "• Integrate with Other Systems",
    description:
    "Integrate your digital signage with other systems, such as point-of-sale systems or loyalty programs. This integration can streamline operations and provide a seamless experience for both customers and staff.",
  },
  {
    title: "• To conclude...",
    description:
    "By following these best practices, restaurants can maximize the impact of their digital signage and create a more engaging and effective customer experience.",
  },
];

export const features4_education = [
  {
    title: "• Enhancing Communication",
    description:
    "Digital signage is more than just a tool for sharing information—it's a way to transform the educational experience for students, staff, and visitors alike. With eye-catching screens and interactive features, schools can create engaging content that grabs attention and keeps people informed.",
  },
  {
    title: "• Effortless Updates and Relevance",
    description:
    "One key benefit is the ability to easily update or revise content. Whether it's sharing a last-minute schedule change, promoting upcoming events, or celebrating student achievements, digital signage makes it simple to keep your message fresh and relevant.",
  },
  {
    title: "• Boosting Student Engagement",
    description:
    "For students, digital signage can boost engagement and motivation. Displaying creative visuals, interactive lessons, or even fun trivia can make learning more exciting. Displaying content in various formats can further enhance engagement and streamline communication. It's also a way to connect schools to the global stage by incorporating modern teaching methods and up-to-date resources.",
  },
  {
    title: "• Empowering the School Community",
    description:
    "From helping students navigate campus to creating a more dynamic learning environment, digital signage is a powerful tool that benefits the entire school community. It's efficient, modern, and designed to make education more accessible and enjoyable for everyone.",
  },
];


export const features4_healthcare = [
  {
    title: "• Interactive Wayfinding for Visitors",
    description:
    "Navigating large and complex healthcare facilities can be daunting for patients and visitors. Digital signage offers interactive tools such as digital directories and kiosks to provide quick check-in and navigation assistance. These features allow visitors to easily find their way within the facility, reducing stress and improving their overall experience.",
  },
  {
    title: "• Seamless Navigation with Digital Signage",
    description:
    "Integrating wayfinding solutions enhances navigation for patients and visitors, ensuring easy destination access.",
  },
  {
    title: "• Interactive Maps",
    description:
    "Digital wayfinding signage can display maps and location information that guide visitors to their destinations, making navigation within healthcare facilities more straightforward. Interactive maps and directories provide clear guidance, reducing stress and uncertainty for patients. By utilizing these interactive tools, healthcare facilities can enhance the visitor experience and ensure that patients reach their appointments and services with ease.",
  },
  {
    title: "• To conclude...",
    description:
    "These digital tools are essential for helping patients and visitors navigate complex healthcare environments effectively, improving their overall experience.",
  },
];

export const featureItems = [
  {
    imageSrc: "https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/real_estate/real4.png",
    altText: "real-estate",
    title: "Digital Signage for Real Estate Success",
    description:
    'Digital signage is now vital for real estate agents aiming to improve property visibility and client engagement. Strategically placed digital displays attract potential buyers by showcasing dynamic content, such as promotional videos and gallery images, making property listings more appealing. Unlike traditional signage, digital signs create a sense of urgency, encouraging buyers to act swiftly in competitive markets.',
    icon: "unicon-cloud-download",
    reverseOrder: false,
  },
  {
    imageSrc: "https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/real_estate/real9.png",
    altText: "real-estate",
    title: "Boosting Branding with Digital Signage",
    description:
    "Digital signage also enhances branding for real estate agencies, presenting a modern and dynamic appearance that effectively communicates to the target audience and updates marketing efforts. Think about walking into a real estate office and being greeted by sleek digital screens displaying listings, video walkthroughs, ratings, and testimonials—it's a powerful way to enhance your brand.",
    icon: "unicon-course",
    reverseOrder: true,
  },
  {
    imageSrc: "https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/real_estate/r2.png",
    altText: "real-estate",
    title: "Sustainable Real Estate Marketing",
    description:
    "Digital signage reduces the need for print materials, saving costs and promoting environmentally friendly, sustainable marketing practices. This modern advertising approach is a necessity in today's digital age, helping real estate businesses stand out in a crowded market.",
    icon: "unicon-volume-block-storage",
    reverseOrder: false,
  },
];

export const featureItems_restaurant = [
  {
    imageSrc: "https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/restaurant/food6.png",
    altText: "restaurant",
    title: "Digital Menu Boards: ",
    description:
    "These electronic displays showcase menu items, prices, and promotions. They are easily updated and customized to fit the restaurant's brand and style. Digital menu boards can highlight special deals, new items, and even nutritional information, making them a versatile tool for any restaurant.",
    icon: "unicon-cloud-download",
    reverseOrder: false,
  },
  {
    imageSrc: "https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/restaurant/food5.png",
    altText: "restaurant",
    title: "Interactive Touchscreens: ",
    description:
    "These allow customers to place orders, pay bills, and access loyalty programs directly from the screen. They can also provide nutritional information and menu recommendations, enhancing the customer experience by making it more interactive and informative.",
    icon: "unicon-course",
    reverseOrder: true,
  },
  {
    imageSrc: "https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/restaurant/rest7.png",
    altText: "restaurant",
    title: "Video Walls: ",
    description:
    "Large displays that showcase high-definition videos and images, video walls can create a visually appealing atmosphere and promote special deals and events. They are perfect for making a big impact and drawing attention to key promotions.",
    icon: "unicon-volume-block-storage",
    reverseOrder: false,
  },
  {
    imageSrc: "https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/restaurant/rest6.png",
    altText: "restaurant",
    title: "Transparent LED Window Displays: ",
    description:
    "These digital displays can be placed in windows to showcase menu items, promotions, and events. They are energy-efficient and can be easily updated, making them an excellent choice for attracting passersby and boosting sales.",
    icon: "unicon-volume-block-storage",
    reverseOrder: true,
  },
];

export const featureItems_education2 = [
  {
    imageSrc: "https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/education/edu6.png",
    altText: "edu",
    title: 'Engaging School Events with Digital Signage',
    description:
    "Digital signage is a versatile tool that can bring life to any school environment. One of the best uses is displaying school events. Whether it's sports schedules, upcoming concerts, or school plays, digital signage ensures everyone stays informed and excited about what's happening on campus. Additionally, digital signage can attract prospective students by showcasing campus events, school spirit, and real-time social media updates.",
    icon: "unicon-cloud-download",
    reverseOrder: false,
  },
  {
    imageSrc: "https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/education/school7.png",
    altText: "edu",
    title: 'Digital Noticeboards for Timely Updates',
    description:
    "Another great way to use digital signage is by creating digital noticeboards. These can be used to display important information like emergency alerts, school news, and announcements. Unlike traditional bulletin boards, digital signage is easy to update, so the information is always current and accurate.",
    icon: "unicon-course",
    reverseOrder: true,
  },
  {
    imageSrc: "https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/education/school11.png",
    altText: "edu",
    title: 'Showcasing Student Achievements',
    description:
    "Digital signage is also perfect for showcasing student work. Highlight artwork, science fair projects, or other creative achievements on large, vibrant screens. This not only celebrates students' efforts but also inspires others to get involved.",
    icon: "unicon-volume-block-storage",
    reverseOrder: false,
  },
  {
    imageSrc: "https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/education/edu9.png",
    altText: "edu",
    title:'Transforming School Spaces',
    description:
    "Finally, consider placing digital signage in key areas like classrooms, libraries, and common spaces. In classrooms, it can enhance learning by displaying lesson materials. In libraries, it can highlight new books or resources. In common areas, it can serve as a hub for news, updates, and inspiration, making school spaces more engaging and connected.",
    icon: "unicon-volume-block-storage",
    reverseOrder: true,
  },
];

// featureItems_restaurant2
export const featureItems_restaurant2 = [
  {
    imageSrc: "https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/restaurant/food2.png",
    altText: "restaurant",
    title: "Create Eye-Catching Displays: ",
    description:
    "Use high-quality images and videos to create visually appealing displays that showcase menu items and promotions. The more attractive the display, the more likely it is to catch the customer's eye and encourage them to make a purchase.",
    icon: "unicon-cloud-download",
    reverseOrder: false,
  },
  {
    imageSrc: "https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/restaurant/20off.jpeg",
    altText: "restaurant",
    title: "Offer Limited-Time Deals: ",
    description:
    "Use digital signage to promote limited-time deals and events, such as happy hour specials or seasonal menu items. Highlighting these offers can create a sense of urgency and encourage customers to take advantage of the deal before it's gone.",
    icon: "unicon-course",
    reverseOrder: true,
  },
  {
    imageSrc: "https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/restaurant/food10.png",
    altText: "restaurant",
    title: "Highlight Menu Items: ",
    description:
    "Use digital signage to highlight popular or profitable menu items, such as signature dishes or high-margin items. By drawing attention to these items, you can increase their sales and boost your overall revenue.",
    icon: "unicon-volume-block-storage",
    reverseOrder: false,
  },
  {
    imageSrc: "https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/restaurant/food8.png",
    altText: "restaurant",
    title: "Provide Nutritional Information: ",
    description:
    "Use digital signage to provide customers with nutritional information about menu items, such as calorie counts or ingredient lists. This transparency can build trust with health-conscious customers and help them make informed choices.",
    icon: "unicon-volume-block-storage",
    reverseOrder: true,
  },
  {
    imageSrc: "https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/restaurant/rest3.png",
    altText: "restaurant",
    title: "Promote Loyalty Programs: ",
    description:
    "Use digital signage to promote loyalty programs and rewards, such as discounts or free menu items. Encouraging customers to join your loyalty program can increase repeat visits and build long-term customer loyalty.",
    icon: "unicon-volume-block-storage",
    reverseOrder: false,
  },
];

// featureItems_education
export const featureItems_education = [
  {
    imageSrc: "https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/education/school1.png",
    altText: "edu",
    title: "Transforming School Communication",
    description:
    "Digital signage for schools is becoming a game-changer in how educational institutions communicate. From preschool to universities, this technology helps schools share important information quickly and efficiently. Instead of relying on outdated bulletin boards or paper flyers, schools can use vibrant screens to grab attention and keep everyone informed.",
    icon: "unicon-cloud-download",
    reverseOrder: false,
  },
  {
    imageSrc: "https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/education/school2.png",
    altText: "edu",
    title: "Flexible and Real-Time School Communication",
    description:
    "One of the biggest benefits of digital signage is the flexibility of digital signage content. Schools can easily update announcements, promote upcoming events, or share student achievements. It's also a great tool for delivering emergency alerts in real-time, ensuring safety across the campus.",
    icon: "unicon-course",
    reverseOrder: true,
  },
  {
    imageSrc: "https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/education/school6.png",
    altText: "edu",
    title: "Enhancing School Spirit with Digital Signage",
    description:
    "Beyond announcements, digital signage can enhance school spirit by showcasing team victories, academic successes, or creative projects. Whether it's in the hallways, cafeteria, or library, these screens make learning spaces feel more connected and engaging within educational environments.",
    icon: "unicon-volume-block-storage",
    reverseOrder: false,
  },
];

export const featureItems_healthcare = [
  {
    imageSrc: "https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/health_care/hh4.png",
    altText: "healthcare",
    title: "Enhancing Patient Communication and Safety",
    description:
    "Clear and effective communication is the cornerstone of any successful healthcare facility. In environments where every second counts, healthcare digital signage systems can deliver instant updates and key information to both patients and staff. This not only streamlines communication but also significantly reduces costs associated with printing and updating traditional signage. Moreover, by providing clear and engaging information, digital signage plays a vital role in alleviating patient anxiety and enhancing adherence to health regulations.",
    icon: "unicon-cloud-download",
    reverseOrder: false,
  },
  {
    imageSrc: "https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/health_care/h2.png",
    altText: "healthcare",
    title: "Real-Time Updates for Better Patient Experience",
    description:
    "Delivering real-time updates significantly benefits healthcare providers. Whether it’s announcing new health protocols, updating wait times, or communicating emergency procedures, digital signage ensures that critical information reaches its intended audience promptly. This not only improves safety but also enhances the overall patient experience in waiting rooms and other areas of the facility.",
    icon: "unicon-course",
    reverseOrder: true,
  },
  {
    imageSrc: "https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/health_care/hh6.png",
    altText: "healthcare",
    title: "Real-time Announcements",
    description:
    "Imagine being able to share crucial updates and announcements with patients and staff instantly. Healthcare digital signage makes this possible by providing real-time updates on health protocols, emergency alerts, and critical announcements. This transforms communication management in healthcare facilities, keeping everyone informed and safe.",
    icon: "unicon-volume-block-storage",
    reverseOrder: false,
  },
  {
    imageSrc: "https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/health_care/hh7.png",
    altText: "healthcare",
    title: "Real-time Announcements",
    description:
    "Digital displays quickly communicate emergency procedures, ensuring prompt dissemination of critical information. From evacuation routes to emergency protocols, digital signage software can display vital information prominently, guiding individuals during emergencies. This enhances safety and ensures a coordinated response during critical situations.",
    icon: "unicon-volume-block-storage",
    reverseOrder: true,
  },
  {
    imageSrc: "https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/health_care/hh8.png",
    altText: "healthcare",
    title: "Real-time Announcements",
    description:
    "In addition to emergency information, digital signage can provide updates on wait times and other important announcements, improving the overall patient experience. Keeping patients informed and engaged fosters a more efficient and reassuring environment.",
    icon: "unicon-volume-block-storage",
    reverseOrder: false,
  },
  {
    imageSrc: "https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/health_care/hh9.png",
    altText: "healthcare",
    title: "Streamlined Internal Communications",
    description:
    "Digital signage is not just for patients; it also plays a crucial role in internal communications within healthcare facilities. Real-time updates from digital signage keep staff informed about patient status, staff assignments, and other essential information, boosting productivity. This reduces reliance on printed materials and minimizes the need for frequent meetings, making communication more efficient and effective.",
    icon: "unicon-volume-block-storage",
    reverseOrder: true,
  },
];

export const featureItems_healthcare2 = [
  {
    imageSrc: "https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/health_care/heal1.png",
    altText: "healthcare",
    title: "Cost-Effective Communication",
    description:
    "Digital signage offers a cost-effective solution for healthcare facilities by repurposing existing screens and reducing the need for paper memos. This not only lowers operational costs but also improves coordination and response times within the facility.",
    icon: "unicon-cloud-download",
    reverseOrder: false,
  },
  {
    imageSrc: "https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/health_care/heal6.png",
    altText: "healthcare",
    title: "Enhanced Operational Efficiency",
    description:
    "Integrating digital signage with existing hospital systems enhances operational efficiency, making it a valuable investment for healthcare providers.",
    icon: "unicon-course",
    reverseOrder: true,
  },
  {
    imageSrc: "https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/health_care/heal7.png",
    altText: "healthcare",
    title: "Reduced Printing Costs",
    description:
    "Utilizing digital displays minimizes the need for physical printed materials, contributing to lower operational costs. Eliminating recurring expenses for printed materials saves healthcare facilities time and money on production and distribution.",
    icon: "unicon-volume-block-storage",
    reverseOrder: false,
  },
  {
    imageSrc: "https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/health_care/heal8.png",
    altText: "healthcare",
    title: "Reduced Printing Costs",
    description:
    "This small investment in digital signage leads to significant savings, making it a sound choice for any facility looking to enhance efficiency.",
    icon: "unicon-volume-block-storage",
    reverseOrder: true,
  },
  {
    imageSrc: "https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/health_care/heal9.png",
    altText: "healthcare",
    title: "Energy Efficiency",
    description:
    "Modern digital signage systems are designed to consume less power, enhancing their energy efficiency compared to older technologies. In fact, these systems can be up to 90% more energy efficient than older display models, contributing to lower utility costs and promoting sustainability in healthcare facilities. This focus on energy efficiency makes digital signage not only a cost-effective solution but also an environmentally responsible choice.",
    icon: "unicon-volume-block-storage",
    reverseOrder: false,
  },
  {
    imageSrc: "https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/health_care/heal10.png",
    altText: "healthcare",
    title: "Energy Efficiency",
    description:
    "The benefits of energy-efficient digital signage extend beyond cost savings. By reducing the environmental impact and enhancing overall energy efficiency, healthcare providers can demonstrate their commitment to sustainability and safety excellence. It’s a vital tool in the hi-tech world of healthcare, ensuring that facilities can operate smartly and responsibly.",
    icon: "unicon-volume-block-storage",
    reverseOrder: true,
  },
];