import React from "react";

export default function About2() {
  return (
    <div
      id="about_us"
      className="about-us section panel overflow-hidden"
      data-anime="onview: -100; opacity: [0, 1]; easing: spring(1, 80, 10, 0); duration: 450; delay: 250;"
    >
      <div className="section-outer panel py-6 xl:py-9 bg-secondary dark:bg-gray-800">
        <div
          className="d-none lg:d-block"
          data-anime="onview: -100; targets: img; scale: [0.8, 1]; opacity: [0, 1]; easing: spring(1, 80, 10, 0); duration: 450; delay: 350;"
        >
          <div className="position-absolute bottom-0 start-0 ms-n8 mb-6">
            <img
              className="w-150px xl:w-250px d-block dark:d-none"
              alt="walking"
              src="/images/template/walking.svg"
              width="224"
              height="226"
            />
            <img
              className="w-150px xl:w-250px d-none dark:d-block"
              alt="walking-dark"
              src="/images/template/walking-dark.svg"
              width="224"
              height="227"
            />
          </div>
        </div>
        <div className="container max-w-lg">
          <div className="section-inner panel">
            <div
              className="panel vstack text-center"
              data-anime="onview: -100; targets: >*; translateY: [48, 0]; opacity: [0, 1]; easing: spring(1, 80, 10, 0); duration: 450; delay: anime.stagger(100, {start: 200});"
            >
              <h2 className="h3 lg:h2 mb-4">Case Studies of Successful Digital Signage Implementation</h2>
              <p className="fs-5 xl:fs-4 text-dark dark:text-white">
              Real-world examples of successful digital signage implementation demonstrate its transformative impact in the real estate industry. Agencies adopting digital signage have witnessed significant boosts in engagement, leading to higher sales and improved customer satisfaction.
              </p> 
              <p className="fs-5 xl:fs-4 text-dark dark:text-white">
              The following case studies highlight the innovative use of digital signs in real estate offices, showcasing the benefits and outcomes of integrating this technology.
              </p>
              <p className="fs-5 xl:fs-4 text-dark dark:text-white" style={{fontWeight:'700'}}>
              • Sydney Real Estate Office Transformation  
              </p>
              <p className="fs-5 xl:fs-4 text-dark dark:text-white">
              A Sydney-based real estate office recognized the need to enhance their property listings display to attract more potential buyers and modernize their marketing strategies. Implementing real-time updates for property listings via cloud-based digital signage software allowed the office to provide accurate information instantly to potential buyers. Interactive digital displays were incorporated, allowing clients to explore property portfolios in detail, significantly enhancing engagement.
              </p>
              <p className="fs-5 xl:fs-4 text-dark dark:text-white">
              The structured implementation process included selecting appropriate digital signage hardware and managing content for regular updates, leading to increased client engagement and improved visibility for property listings. This digital signage strategy ultimately led to a boost in sales, demonstrating the effectiveness of modern marketing techniques in the real estate industry.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
