// import { features6 } from "./../data/features";
import React from "react";

export default function Section() {
  return (
    <div id="hero_header" className="hero-header section panel overflow-hidden">
      <div
        className="position-absolute top-0 start-0 end-0 min-h-screen overflow-hidden d-none lg:d-block"
        data-anime="targets: >*; scale: [0, 1]; opacity: [0, 1]; easing: spring(1, 80, 10, 0); duration: 450; delay: anime.stagger(100, {start: 750});"
      >
        <div
          className="position-absolute rotate-45"
          style={{
            top: "30%",
            left: "18%",
          }}
        >
          <img
            className="w-32px text-gray-900 dark:text-white"
            src="/images/template/star-1.svg"
            width={193}
            height={216}
            alt="star-1"
            data-uc-svg=""
          />
        </div>
        <div
          className="position-absolute rotate-45"
          style={{ top: "15%", right: "18%" }}
        >
          <img
            className="w-24px text-gray-900 dark:text-white"
            src="/images/template/star-2.svg"
            width={69}
            height={95}
            alt="star-2"
            data-uc-svg=""
          />
        </div>
      </div>
      <div className="section-outer panel pt-9 lg:pt-10 pb-6 xl:pb-9">
        <div className="container max-w-lg">
          <div className="section-inner panel mt-2 sm:mt-4 lg:mt-0">
            {/* <div
              className="panel vstack items-center gap-3 lg:gap-4 mb-6 sm:mb-8 lg:mb-9 max-w-650px mx-auto text-center"
              data-anime="targets: >*; translateY: [48, 0]; opacity: [0, 1]; easing: spring(1, 80, 10, 0); duration: 450; delay: anime.stagger(100, {start: 200});"
            >
              <h1 className="h2 sm:h1 lg:display-6 xl:display-5 m-0">
                Benefits of Using Digital Signage in Real Estate Offices
              </h1>
              <p className="fs-6 sm:fs-5 text-dark dark:text-white">
                Digital signage in real estate offices enhances both property visibility and customer engagement. Agencies using digital signage report increased property inquiries and higher sales, as these displays showcase homes through rotating images and vibrant videos, drawing more attention from potential buyers. A passerby glancing at a high-brightness window display can be instantly drawn in by the stunning visuals of a featured property.
              </p>
            </div> */}

            {/* 无图的section */}
            <div className="sticky-scene panel vstack gap-4 sm:gap-6 xl:gap-8">
                <div
                  className="feature-item panel px-3 lg:px-4 py-4 rounded-2 bg-secondary dark:bg-gray-800"
                  data-anime="onview: -200; translateY: [48, 0]; opacity: [0, 1]; easing: spring(1, 80, 10, 0); duration: 450; delay: 100;"
                >
                  <div className="panel vstack justify-center gap-4 h-100">
                      <div className="panel vstack gap-2">
                        <h3 className="h4 lg:h2 m-0">Building Brand Storytelling with Digital Signage</h3>
                        <p className="fs-6 lg:fs-5 dark:opacity-80">
                          Digital signage displays are a powerful tool for hospitality venues to showcase their unique personality and connect with customers. By sharing stories about the restaurant—like introducing staff members along with their favorite menu recommendations—digital displays can create a warm, welcoming feel. They can even share fun facts and behind-the-scenes moments that give customers a peek into the restaurant's daily life.
                        </p>
                        <p className="fs-6 lg:fs-5 dark:opacity-80">
                          Using digital signage to share these personal touches helps build a stronger community, turning first-time visitors into loyal customers. It also creates a modern atmosphere that appeals to new guests and makes the experience more memorable. Plus, digital signage is great for promoting add-on sales, like highlighting sides or drinks that pair well with popular dishes. By making the dining experience more interactive, restaurants can build brand loyalty and encourage repeat visits.
                        </p>
                      </div>
                  </div>
                </div>
            </div>
            <div className="sticky-scene panel vstack gap-4 sm:gap-6 xl:gap-8 mt-8">
                <div
                  className="feature-item panel px-3 lg:px-4 py-4 rounded-2 bg-secondary dark:bg-gray-800"
                  data-anime="onview: -200; translateY: [48, 0]; opacity: [0, 1]; easing: spring(1, 80, 10, 0); duration: 450; delay: 100;"
                >
                  <div className="panel vstack justify-center gap-4 h-100">
                      <div className="panel vstack gap-2">
                        <h3 className="h4 lg:h2 m-0">Measuring the Impact of Digital Signage on Restaurant Operations</h3>
                        <p className="fs-6 lg:fs-5 dark:opacity-80">
                          Measuring the success of restaurant digital signage through digital signage analytics is key to knowing if it’s worth the investment. By tracking sales data, customer feedback, and engagement metrics, restaurants can get a clear picture of their return on investment (ROI). Digital signage solutions are a smart choice for improving ROI because they lower printing costs—no more need for constantly updated printed menus or promotional signs.
                        </p>
                        <p className="fs-6 lg:fs-5 dark:opacity-80">
                          Using electronic menu boards and other digital displays can also cut down on labor costs, as staff spend less time setting up and changing out printed materials. Altogether, digital signage solutions provide a cost-effective way to reach and engage customers. With measurable data, restaurants and cafes can see how their digital displays are driving sales, enhancing customer experience, and keeping operations efficient.
                        </p>
                      </div>
                  </div>
                </div>
            </div>

            <div className="sticky-scene panel vstack gap-4 sm:gap-6 xl:gap-8 mt-8">
                <div
                  className="feature-item panel px-3 lg:px-4 py-4 rounded-2 bg-secondary dark:bg-gray-800"
                  data-anime="onview: -200; translateY: [48, 0]; opacity: [0, 1]; easing: spring(1, 80, 10, 0); duration: 450; delay: 100;"
                >
                  <div className="panel vstack justify-center gap-4 h-100">
                      <div className="panel vstack gap-2">
                        <h3 className="h4 lg:h2 m-0">Implementing Digital Signage in Your Restaurant</h3>
                        <p className="fs-6 lg:fs-5 dark:opacity-80">
                          Switching to a digital signage network is a smart step for any hospitality business aiming to create a modern, interactive experience. Digital screens go beyond simple displays—they can transform how customers view and engage with your offerings. Our team can help you find the ideal digital signage solution for your café or restaurant, from dynamic menu boards to engaging video walls and interactive touchscreens.
                        </p>
                        <p className="fs-6 lg:fs-5 dark:opacity-80">
                          For businesses in the restaurant industry, digital signage makes it easy to showcase daily specials, promote seasonal items, and keep customers interested. If you’d like assistance from our AI image generator to design digital signage content and styles or to assess the effectiveness of your signage, we’re here to support you every step of the way. Browse our website to learn more about our digital menu design solutions and discover how digital signage can improve both customer engagement and sales for your business. Start creating an inviting, memorable experience that keeps customers coming back!
                        </p>
                      </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
