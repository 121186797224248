import Cta from './component/HealthCare/Cta';
import Faq from './component/HealthCare/Faq';
import Features from './component/HealthCare/Features';
import Hero from './component/HealthCare/Hero';
import TextArea from './component/HealthCare/TextArea';
import TextArea2 from './component/HealthCare/TextArea2';
import Paragraph from './component/HealthCare/Paragraph';
import About2 from "./component/HealthCare/About2";
import Features2 from "./component/HealthCare/Features2";
import Slides from "./component/HealthCare/Slides";
import Slides2 from "./component/HealthCare/Slides2";
import Green from "./component/HealthCare/Green";
import React, { useEffect, useState } from 'react';

export default function HomePage2() {
  const [isCSSLoaded, setIsCSSLoaded] = useState(false);
    useEffect(() => {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = '/main.min.purge.css';
        link.onload = () => setIsCSSLoaded(true);
        document.head.appendChild(link);
    
        return () => {
            document.head.removeChild(link);
        };
    }, []);
  return (
    <div style={{ visibility: isCSSLoaded ? 'visible' : 'hidden' }}>
      {/* <MetaComponent meta={metadata} /> */}
      <div className="uni-body panel bg-white text-gray-900 dark:bg-gray-900 dark:text-gray-200 overflow-x-hidden bp-xs bp-sm bp-md bp-lg bp-xl bp-xxl-max dom-ready">
        {/* <Header2 /> */}
        <div id="wrapper" className="wrap">
          {/* 标题 */}
          <Hero />
          {/* key features */}
          <Features />
          {/* 段落 */}
          <TextArea />
          <Slides />
          <Paragraph />
          <Features2 />
          <div className="pt-6 xl:pt-9"></div>
          <Slides2/>
          {/*  */}
          <About2 />
          <TextArea2 />
          <Green />
          <div className="pt-6 xl:pt-9"></div>
          <Faq />
          <Cta />
        </div>
      </div>
    </div>
  );
}
