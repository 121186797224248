import './App.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './assets/styles/tailwind.css';
import './assets/styles/zls.css';

import {
    AccountManager,
    AccountSettings,
    CampaignManager,
    Dashboard,
    DeviceManager,
    Log,
    Login,
    MediaManager,
    PublishCampaign,
    Register,
    ScheduleCampaign,
    SetUpNewPassword,
    SwitchUser,
    Template,
    VerifyCodePage,
} from './pages';
import CaseStudy from './pages/CaseStudy';
import { AdPerformanceTest, ContactUs, Home, Installation, PricingPlans, WebFooter } from './webPages';
import { Blogs, Features, Hardware, HowItWorks, Page404, Pricing } from './publicPages';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import { CampaignDesign, EditCampaign } from './components/dashboard/campaign';
import { Contract, PaymentPage, Subscription, SubscriptionMain } from './components/dashboard/accounting';
import { DashboardNotFound, Notification } from './components/dashboard/utils';
import { Footer, Sidebar } from './components/dashboard';
import { GetMenuList, GetUserInfo, UpdateTokenExpire } from './api/api';
import { IconButton, Tooltip } from '@mui/material';
import { MdDevices, MdManageAccounts, MdPermMedia } from 'react-icons/md';
import React, { useEffect, useState } from 'react';
import { ScrollToTop, WebNavbar } from './components/website';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import AIPage from './pages/AIPage';
import { Admin } from './components/dashboard/admin';
import { AiOutlineRobot } from 'react-icons/ai';
import { FiSettings } from 'react-icons/fi';
// import { GetMenuList, GetUserInfo } from './api/api';
import { GiArtificialIntelligence } from 'react-icons/gi';
import HomePage from './components/web/HomePage';
import { MdDashboard } from 'react-icons/md';
// import { Page404 } from './publicPages';
import { SidebarItem } from './components/dashboard/Sidebar';
import WebBreadcrumbs from './components/website/WebBreadcrumbs';
// import { StripeSubscription } from './components/dashboard/profile/payment';
// import WebBreadcrumbs from './components/website/WebBreadcrumbs';
import { getLastApiCallTime } from './api/TokenManager';
import { links } from './data/dummy';
// import moment from 'moment';
import { useStateContext } from './contexts/ContextProvider';

// blog css format
// import './assets/css/theme/main.min.purge.css'

// 具体的blog的页面
import RealEstate from './blogs/RealEstate';
import Education from './blogs/Education';
import HealthCare from './blogs/HealthCare';
import Restaurant from './blogs/Restaurant';
import { useLocation } from 'react-router-dom';

// import { WebFooter, WebNavbar } from './components/website';

const theme = createTheme({
    typography: {
        fontFamily:
            'Poppins, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Droid Sans, Helvetica Neue, sans-serif',
    },
    palette: {
        primary: {
            main: '#0444AB',
            darker: '#053e85',
        },
        neutral: {
            main: '#64748B',
            contrastText: '#fff',
        },
        gray: {
            light: '#f7f7f7',
            main: '#a9a9a9',
            dark: '#595959',
            contrastText: '#000',
        },
        white: {
            main: '#fff',
            dark: '#f7f7f7',
            contrastText: '#000',
        },
    },
    components: {
        MuiDialog: {
            styleOverrides: {
                paper: {
                    borderRadius: '10px',
                    padding: '20px',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '6px',
                    color: 'white',
                    fontWeight: 600,
                    // letterSpacing: '0.5px',
                    // width: '150px',
                    height: '40px',
                    textTransform: 'capitalize',
                },
                text: {
                    fontWeight: '',
                },
            },
        },
    },
});
const TOKEN_UPDATE_INTERVAL = 10 * 60 * 1000; // 10 mins
const updateToken = async () => {
    // update token
    const data = await UpdateTokenExpire();
};

function App() {
    // const navigate = useNavigate();
    const { currentUser, setCurrentUser, activeMenu, setScreenSize, screenSize } = useStateContext();
    //console.log('App => currentUser:', currentUser);
    // const lastApiCallTime = getLastApiCallTime();
    //  //console.log(
    //     'file: App.js:104 => App => getLastApiCallTime:',
    //     moment(lastApiCallTime).format('MMMM Do YYYY, h:mm:ss a'),
    // );
    const switchUser = JSON.parse(localStorage.getItem('switchUser'));
    // const currentUser = JSON.parse(localStorage.getItem('currentUser'));

    if (process.env.REACT_APP_ENV === 'development') {
        //console.log('This is the development environment');
    } else if (process.env.REACT_APP_ENV === 'production') {
        //console.log('This is the production environment');
    } else {
        //  //console.log(process.env.REACT_APP_ENV);
        //console.log('This is a different environment');
    }

    const pathname = window.location.pathname;
    const [menuList, setMenuList] = useState([]);
    //console.log('App => menuList:', menuList);
    const handleMenuList = async () => {
        const { code, data, msg } = await GetMenuList(switchUser);
        if (code === 0) {
            setMenuList(data?.list);
        }
        // else {
        //     alert(msg);
        // }
    };

    useEffect(() => {
        const handleResize = () => setScreenSize(window.innerWidth);

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Clear local storage if the pathname includes 'login'
    // Otherwise, set the current user if it exists in local storage
    useEffect(() => {
        if (pathname.includes('login')) {
            localStorage.removeItem('currentUser');
            localStorage.removeItem('switchUser');
            localStorage.removeItem('switchUserName');
        } else if (!pathname.includes('switch-user')) {
            const switchedUser = JSON.parse(localStorage.getItem('switchUser'));
            const user = JSON.parse(localStorage.getItem('currentUser'));
            if (user) {
                setCurrentUser(user);
                localStorage.setItem('currentUser', JSON.stringify(user));
            }
        } else {
        }
        handleMenuList();
    }, [pathname]);

    useEffect(() => {
        // 设置定时器检查是否需要更新 token
        // const intervalId = setInterval(checkAndUpdateToken, TOKEN_UPDATE_INTERVAL);
        //  //console.log('useEffect => intervalId:', intervalId);

        // 设置页面点击事件监听器
        const clickListener = () => {
            const lastApiCallTime = getLastApiCallTime();
            //  //console.log('clickListener => lastApiCallTime:', moment(lastApiCallTime).format('MMMM Do YYYY, h:mm:ss a'));
            const currentTime = Date.now();
            //  //console.log('clickListener => currentTime:', moment(currentTime).format('MMMM Do YYYY, h:mm:ss a'));

            //  //console.log('clickListener => currentTime - lastApiCallTime:', currentTime - lastApiCallTime);
            if (currentTime - lastApiCallTime > TOKEN_UPDATE_INTERVAL) {
                //console.log('大于十分钟了，如果用户点击页面更新token');
                updateToken();
                // setLastApiCallTime(currentTime);
            }
        };

        document.addEventListener('click', clickListener);

        return () => {
            // clearInterval(intervalId);
            document.removeEventListener('click', clickListener);
        };
    }, []);

    const [open, setOpen] = useState(false);
    const toggleDrawer = (isOpen) => () => {
        setOpen(isOpen);
    };

    const [userInfo, setUserInfo] = useState();
    //console.log('App => userInfo:', userInfo);
    const handleGetUserInfo = async () => {
        //  //console.log('file: Sidebar.jsx:132 => handleGetStorage => user_id:', switchedUser);
        const { code, data } = await GetUserInfo(currentUser?.user_id);
        if (code === 0) {
            setUserInfo(data);
            localStorage.setItem('userDetails', JSON.stringify(data));
        }
    };

    useEffect(() => {
        handleGetUserInfo();
    }, [currentUser]);

    // const location = useLocation(); // 获取当前路径
    // const { currentpathname } = location; // 获取 pathname
    
    // const shouldShowFooter = currentpathname.includes('dashboard');
    // const shouldShowWebFooter = !(
    //     currentpathname.includes('login') || 
    //     currentpathname.includes('signup')
    // );
    // const location = useLocation();
    // const [showFooter, setShowFooter] = useState(false);

    // useEffect(() => {
    //     const { pathname } = location;
    //     if (pathname.includes('dashboard')) {
    //         setShowFooter(true);
    //     } else if (
    //         pathname.includes('login') ||
    //         pathname.includes('signup')
    //     ) {
    //         setShowFooter(false);
    //     } else {
    //         setShowFooter(true);
    //     }
    // }, [location]);
    // const location = useLocation();
    // const hideFooterPaths = ['/signup','signin'];
    // const shouldShowFooter = !location.pathname.includes(hideFooterPaths);

    const currentLocation = useLocation(); // 获取当前路径
    const [footerType, setFooterType] = useState(null); // 控制 Footer 类型

    useEffect(() => {
        const { pathname } = currentLocation;

        // 判断 Footer 显示逻辑
        if (pathname.includes('dashboard')) {
            setFooterType('dashboard'); // 显示 Footer
        } else if (pathname.includes('signup') || pathname.includes('login')) {
            setFooterType(null); // 不显示任何 Footer
        } else {
            setFooterType('web'); // 显示 WebFooter
        }
    }, [currentLocation]);
    return (
        <ThemeProvider theme={theme}>
            <div className="w-full">
                <Notification />
                
                    {/* {<Routes>
                        <Route path="home-page" element={<HomePage />} />
                    </Routes> } */}
                    <div className="w-full flex relative dark:bg-main-dark-bg ">
                        {currentUser && pathname.includes('dashboard') && (
                            <>
                                <div className="fixed right-2 bottom-4" style={{ zIndex: '1000' }}>
                                    <Tooltip
                                        title="AI"
                                        sx={{
                                            bgcolor: 'primary.main',
                                            color: 'primary.contrastText',
                                            p: 2,
                                            '&:hover': {
                                                bgcolor: 'primary.main', // 确保在hover时背景颜色不变
                                                color: 'primary.contrastText', // 确保在hover时文字颜色不变
                                            },
                                        }}
                                    >
                                        <IconButton onClick={toggleDrawer(true)}>
                                            <AiOutlineRobot />
                                        </IconButton>
                                    </Tooltip>
                                </div>

                                <div
                                    className={`${
                                        activeMenu ? 'w-[320px] fixed' : 'w-[80px] fixed'
                                    }  dark:bg-secondary-dark-bg z-[100] `}
                                >
                                    <Sidebar>
                                        {/* {currentUser.user_level === 0 && ( */}
                                        <SidebarItem
                                            icon={<MdDashboard />}
                                            text={`Dashboard`}
                                            path={`/dashboard/home`}
                                            // active={link.path === '/dashboard/schedule'}
                                        />
                                        <div className="border-t mt-2 pt-3 border-slate-300 ">
                                            <small
                                                className={`${
                                                    activeMenu
                                                        ? 'pl-3 text-slate-500 inline-block mb-2'
                                                        : 'hidden'
                                                }`}
                                            >
                                                AI
                                            </small>
                                            <SidebarItem
                                                icon={<MdPermMedia />}
                                                text={`Media`}
                                                path={`/dashboard/media`}
                                                // active={link.path === '/dashboard/schedule'}
                                            />
                                        </div>
                                        {/* )} */}
                                        <div className="border-t mt-2 pt-3 border-slate-300 ">
                                            <small
                                                className={`${
                                                    activeMenu
                                                        ? 'pl-3 text-slate-500 inline-block mb-2'
                                                        : 'hidden'
                                                }`}
                                            >
                                                Deployment categories
                                            </small>
                                            {links
                                                ?.filter((x) =>
                                                    menuList?.find(
                                                        (y) =>
                                                            x.name.toLowerCase() ===
                                                                y.menu_name.toLowerCase() &&
                                                            x.name.toLowerCase() !== 'media',
                                                    ),
                                                )
                                                ?.map((link) => (
                                                    <SidebarItem
                                                        key={link.name}
                                                        icon={link.icon}
                                                        text={link.name}
                                                        path={link.path}
                                                        // active={link.path === '/dashboard/schedule'}
                                                    />
                                                ))}
                                        </div>
                                    </Sidebar>
                                </div>
                            </>
                        )}

                        <div
                            className={`dark:bg-main-dark-bg  bg-[#f6f7f8] min-h-screen w-full flex flex-col mt-auto
							${currentUser && pathname.includes('dashboard') ? (activeMenu ? 'md:ml-80 ' : 'ml-[80px]') : 'flex-2 '}`}
                        >
                            {/* <div className="mb-auto">
                                <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg  w-full z-[100]">
                                    {currentUser && pathname.includes('dashboard') ? <Navbar /> : ''}
                                </div>
                            </div> */}
                            {/* <Routes>
                                <Route path="/" element={<HomePage />} />
                            </Routes> */}
                            <div className="mt-0 grow ">
                                <Routes>
                                    {/* <Route path="home-page" element={<HomePage />} /> */}
                                    {/* <Route path="/" element={<Layout />}>
                                        <Route index element={<Home />} />
                                        <Route path="installation" element={<Installation />} />
                                        <Route path="pricing" element={<PricingPlans />} />
                                        <Route path="contact" element={<ContactUs />} />
                                        <Route path="ad-attention-test" element={<AdPerformanceTest />} />
                                    </Route> */}
                                    {/* dashboard  */}
                                    <Route path="/" element={<WebNavbar />}>
                                        <Route index element={<HomePage />} />
                                        {/* <Route path="pricing" element={<Pricing />} /> */}
                                        <Route path="download" element={<Installation />} />
                                        <Route path="pricing" element={<PricingPlans />} />
                                        <Route path="contact" element={<ContactUs />} />
                                        <Route path="ad-attention-test" element={<AdPerformanceTest />} />
                                        {/* 
                                        <Route path="product/hardware" element={<Hardware />} />
                                        <Route path="product/features" element={<Features />} />
                                        <Route path="product/howitworks" element={<HowItWorks />} />
                                        <Route path="resources/all-blogs" element={<Blogs />} /> */}
                                        <Route path="/solution" element={<CaseStudy />} />
                                        {/* <Route path="blog-details/:id" element={<BlogDetailsPage1 />} /> */}
                                        <Route path="/solution/real-estate" element={<RealEstate />} />
                                        <Route path="/solution/restaurant" element={<Restaurant />} />
                                        <Route path="/solution/education" element={<Education />} />
                                        <Route path="/solution/healthcare" element={<HealthCare />} />
                                        {/* <Route path="contact" element={<ContactUs />} /> */}
                                        {/* <Route
                                            path="contact/find/like/subscribe"
                                            element={
                                                <WebBreadcrumbs
                                                    sites={[
                                                        {
                                                            name: 'Contact us',
                                                            link: 'contact',
                                                        },
                                                        {
                                                            name: 'find us',
                                                            link: 'find',
                                                        },
                                                        {
                                                            name: 'like',
                                                            link: 'like',
                                                        },
                                                        {
                                                            name: 'subscribe',
                                                            link: 'subscribe',
                                                        },
                                                    ]}
                                                />
                                            }
                                        />
                                        <Route
                                            path="contact/find/like"
                                            element={
                                                <WebBreadcrumbs
                                                    sites={[
                                                        {
                                                            name: 'Contact us',
                                                            link: 'contact',
                                                        },
                                                        {
                                                            name: 'find us',
                                                            link: 'find',
                                                        },
                                                        {
                                                            name: 'like',
                                                            link: 'like',
                                                        },
                                                    ]}
                                                />
                                            }
                                        />
                                        <Route
                                            path="contact/find"
                                            element={
                                                <WebBreadcrumbs
                                                    sites={[
                                                        {
                                                            name: 'Contact us',
                                                            link: 'contact',
                                                        },
                                                        {
                                                            name: 'find us',
                                                            link: 'find',
                                                        },
                                                    ]}
                                                />
                                            }
                                        />*/}
                                    </Route>
                                </Routes>
                                
                                <Routes>
                                    <Route path="/login" element={<Login />} />
                                    <Route path="/signup" element={<Register />} />
                                    {/* <Route path="/activate" element={<Activate />} /> */}
                                    <Route path="/reset-password" element={<SetUpNewPassword />} />
                                    <Route path="/switch-user" element={<SwitchUser />} />
                                    <Route path="/verify-code" element={<VerifyCodePage />} />
                                    {/* dashboard  */}
                                    {/* <Route path="/" element={<Home />}>
                                        <Route path="/installation" element={<Installation />} />
                                        <Route path="/pricing" element={<PricingPlans />} />{' '}
                                        <Route path="/contact" element={<ContactUs />} />{' '}
                                    </Route> */}

                                    {currentUser && (
                                        <Route>
                                            <Route path="/dashboard/home" element={<Dashboard />} />
                                            <Route path="/dashboard/screen" element={<DeviceManager />} />
                                            <Route path="/dashboard/media" element={<MediaManager />} />
                                            <Route path="/dashboard/playlist" element={<CampaignManager />} />
                                            <Route
                                                path="/dashboard/schedule"
                                                element={<ScheduleCampaign />}
                                            />
                                            <Route
                                                path="/dashboard/publish-campaign"
                                                element={<PublishCampaign />}
                                            />
                                            {currentUser.user_level === 0 && (
                                                <Route
                                                    path="/dashboard/account-manager"
                                                    element={<AccountManager />}
                                                />
                                            )}
                                            <Route
                                                path="/dashboard/playlist/edit-campaign"
                                                element={<EditCampaign />}
                                            />
                                            <Route
                                                path="/dashboard/playlist/template"
                                                element={<Template />}
                                            />

                                            <Route
                                                path="/dashboard/playlist/campaign-design"
                                                element={<CampaignDesign />}
                                            />
                                            <Route
                                                path="/dashboard/account-settings"
                                                element={<AccountSettings />}
                                            />

                                            {/* <Route path="/dashboard/contract" element={<Contract />} /> */}
                                            <Route path="/dashboard/payment" element={<SubscriptionMain />} />
                                            <Route path="/dashboard/log" element={<Log />} />
                                            <Route path="/dashboard/admin" element={<Admin />} />
                                            <Route
                                                path="/dashboard/subscription"
                                                element={<Subscription />}
                                            />
                                            <Route path="/dashboard/*" element={<DashboardNotFound />} />
                                            {/* <Route path="/*" element={<Page404 />} /> */}
                                            
                                        </Route>
                                    )}
                                </Routes>
                                
                            </div>
                            {/* {shouldShowFooter && <WebFooter />} */}
                            {/* {currentUser
                                ? (pathname.includes('dashboard') ||
                                      pathname.includes('login') ||
                                      pathname.includes('signup') ||
                                      pathname.includes('activate')) && <Footer />
                                : !(
                                      pathname.includes('dashboard') ||
                                      pathname.includes('login') ||
                                      pathname.includes('signup') ||
                                      pathname.includes('activate')
                                  ) && <WebFooter />} */}

                            {/* {currentUser && pathname.includes('dashboard') ? <Footer /> : (!pathname.includes('signup') && !pathname.includes('signin') && <WebFooter />)} */}
                            {/* {shouldShowFooter ? <Footer /> : shouldShowWebFooter && <WebFooter />} */}
                            {/* {showFooter ? <Footer /> : <WebFooter />} */}
                            {footerType === 'dashboard' && <Footer />}
                            {footerType === 'web' && <WebFooter />}
                        </div>
                    </div>
                    {/* {currentUser && (
                        <TokenChecker
                            token={token}
                            expirationTime={expirationTime}
                            onTokenExpired={handleTokenExpired}
                        />
                    )} */}
                {/* </BrowserRouter> */}
                <AIPage toggleDrawer={toggleDrawer} open={open} />
            </div>
        </ThemeProvider>
    );
}

// const Layout = () => {
//     return (
//         <>
//             <WebHeader />
//             <Outlet />
//             <WebFooter />
//         </>
//     );
// };
export default App;
