import React from "react";

export default function White() {
  return (
    <div
      id="main_features"
      className="key-features section panel overflow-hidden"
    >
      <div className="section-outer panel py-6 xl:py-9">
        <div className="container sm:max-w-lg xl:max-w-xl">
          <div className="section-inner panel">
            <div
              className="panel vstack items-center gap-2 xl:gap-3 mb-4 sm:mb-8 xl:mb-9 max-w-900px mx-auto"
              data-anime="targets: >*; translateY: [48, 0]; opacity: [0, 1]; easing: spring(1, 80, 10, 0); duration: 450; delay: anime.stagger(100, {start: 200});"
            >
              <h2 className="h3 lg:h2 xl:h1 m-0 text-center">
                How Digital Signage Transforms Restaurant Sales
              </h2>
              <p className="fs-6 xl:fs-5 text-dark dark:text-white">
                Imagine this: A customer walks into your restaurant and is instantly drawn to your digital menu board, where a mouth-watering video of your signature dish plays. Their curiosity is piqued. They see today's chef special highlighted, a customer testimonial pops up, and suddenly, they're more than just hungry—they're ready to explore your menu.
              </p>
              <p className="fs-6 xl:fs-5 text-dark dark:text-white">
                Digital signage can be that powerful when used creatively with digital menu board software. But where do you start? In this post, we'll dive into digital signage solutions for restaurants that not only engage but also drive sales, creating a memorable experience for your guests.
              </p>
              <p className="fs-6 xl:fs-5 text-dark dark:text-white">
                <span style={{fontWeight:700}}>Tl;DR: </span>Increase sales and attract guests by using our online digital signage software to create eye-catching menu displays and engaging promotional content that leaves a memorable impression on your customers.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
