import Cta from './component/RealEstate/Cta';
import Faq from './component/RealEstate/Faq';
import Features from './component/RealEstate/Features';
import Hero from './component/RealEstate/Hero';
import Paragraph2 from "./component/RealEstate/Paragraph2";
import TextArea from './component/RealEstate/TextArea';
import Section from './component/RealEstate/Section';
import Paragraph from './component/RealEstate/Paragraph';
import About2 from "./component/RealEstate/About2";
import Features2 from "./component/RealEstate/Features2";
import About3 from "./component/RealEstate/About3";
import Sldies from "./component/RealEstate/Slides";
import React, { useEffect, useState } from 'react';
// import MetaComponent from "@/components/common/MetaComponent";
// const metadata = {
//   title:
//     "Home 2 || Lexend - Full-featured, professional-looking software, saas and startup reactjs template.",
//   description:
//     "Lexend - Full-featured, professional-looking software, saas and startup reactjs template.",
// };

export default function HomePage2() {
  const [isCSSLoaded,setIsCSSLoaded] = useState(false);
    useEffect(() => {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = '/main.min.purge.css';
        link.onload = () => setIsCSSLoaded(true);
        document.head.appendChild(link);
    
        return () => {
            document.head.removeChild(link);
        };
    }, []);
  return (
    <div style={{ visibility: isCSSLoaded ? 'visible' : 'hidden' }}>
      <div className="uni-body panel bg-white text-gray-900 dark:bg-gray-900 dark:text-gray-200 overflow-x-hidden bp-xs bp-sm bp-md bp-lg bp-xl bp-xxl-max dom-ready">
        {/* <Header2 /> */}
        <div id="wrapper" className="wrap">
          <Hero />
          <Features />
          <TextArea />
          <About2 />
          <Section />
          <Paragraph />
          <Features2 />
          <div className="pt-6 xl:pt-9"></div>
          <About3 />
          <div className="pt-6 xl:pt-9"></div>
          <Sldies />
          <Paragraph2 />
          <div className="pt-6 xl:pt-9"></div>
          <Faq />
          <Cta />
        </div>
      </div>
    </div>
  );
}
