import React from "react";

export default function About2() {
  return (
    <div
      id="about_us"
      className="about-us section panel overflow-hidden"
      data-anime="onview: -100; opacity: [0, 1]; easing: spring(1, 80, 10, 0); duration: 450; delay: 250;"
    >
      <div className="section-outer panel py-6 xl:py-9 bg-secondary dark:bg-gray-800">
        <div
          className="d-none lg:d-block"
          data-anime="onview: -100; targets: img; scale: [0.8, 1]; opacity: [0, 1]; easing: spring(1, 80, 10, 0); duration: 450; delay: 350;"
        >
          <div className="position-absolute bottom-0 start-0 ms-n8 mb-6">
            <img
              className="w-150px xl:w-250px d-block dark:d-none"
              alt="walking"
              src="/images/template/walking.svg"
              width="224"
              height="226"
            />
            <img
              className="w-150px xl:w-250px d-none dark:d-block"
              alt="walking-dark"
              src="/images/template/walking-dark.svg"
              width="224"
              height="227"
            />
          </div>
        </div>
        <div className="container max-w-lg">
          <div className="section-inner panel">
            <div
              className="panel vstack text-center"
              data-anime="onview: -100; targets: >*; translateY: [48, 0]; opacity: [0, 1]; easing: spring(1, 80, 10, 0); duration: 450; delay: anime.stagger(100, {start: 200});"
            >
              <h2 className="h3 lg:h2 mb-4">The Role of Digital Signage in Campus Navigation</h2>
              <p className="fs-5 xl:fs-4 text-dark dark:text-white">
                Digital signage plays a crucial role in campus navigation, helping students, staff, and visitors find their way around the campus. With interactive digital signage, educational institutions can provide a more engaging and user-friendly experience for campus navigation. One of the most effective uses of digital signage is providing interactive campus maps. These maps allow users to search for specific locations, such as buildings, classrooms, or amenities, making it easier for everyone to navigate the campus.
              </p> 
              <p className="fs-5 xl:fs-4 text-dark dark:text-white">
                Displaying wayfinding information is another important function. Digital signs can provide directions to specific locations, building names, and room numbers, ensuring that everyone can find their way quickly and easily. Real-time information on campus events, such as lectures, meetings, and conferences, can also be displayed, keeping everyone informed about what's happening on campus.
              </p>
              <p className="fs-5 xl:fs-4 text-dark dark:text-white">
                Digital signage can also provide information on campus amenities, such as food outlets, libraries, and recreational facilities. This helps students and visitors make the most of the resources available to them. Additionally, digital signage can promote campus safety and security by displaying emergency contact information and safety protocols. By enhancing campus navigation, digital signage helps create a more connected and efficient educational environment.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
