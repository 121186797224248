import React from "react";

export default function Green() {
  return (
    <div
      id="pricing_tiers"
      className="pricing-tiers section panel overflow-hidden"
    >
      <div className="section-outer panel py-4 md:py-6 xl:py-9 rounded-1-5 lg:rounded-2 bg-primary-600 dark:bg-primary text-white">
        <div className="container sm:max-w-lg xl:max-w-xl">
          <div className="section-inner panel">
            <div className="panel vstack gap-4 sm:gap-6 xl:gap-8">
              <div
                className="heading vstack gap-2 max-w-900px panel mx-auto"
                data-anime="onview: -100; targets: >*; translateY: [48, 0]; opacity: [0, 1]; easing: easeOutCubic; duration: 500; delay: anime.stagger(100, {start: 200});"
              >
                {/* <span className="fw-bold text-secondary">Pricing</span> */}
                <h2 className="title h3 lg:h2 xl:h1 text-white m-0">
                  Try Our Digital Signage Solutions for Free!
                </h2>
                <p className="fs-6 xl:fs-5 text-white max-w-900px">
                  Exploring digital signage solutions can greatly enhance communication and patient experience in healthcare facilities. Our webisite provides a free trial, allowing healthcare providers to test their systems before committing. This opportunity to explore features and see the benefits firsthand can be a game-changer for your facility.
                </p>
                <h4 className="title h5 lg:h4 xl:h3 text-white m-0">• Explore Features</h4>
                <p className="fs-6 xl:fs-5 text-white max-w-900px">
                  Sign inspire's online digital signage software offers a user-friendly interface, allowing users to input prompts to generate customized digital signage content. Additionally, the platform provides numerous pre-designed workflows on its website, enabling users to quickly meet specific needs. This intuitive setup ensures that even non-IT users can operate the system effortlessly, making it an ideal tool for enhancing communication in healthcare environments.
                </p>
                <h4 className="title h5 lg:h4 xl:h3 text-white m-0">• Easy Setup</h4>
                <p className="fs-6 xl:fs-5 text-white max-w-900px">
                  Our digital signage software is designed with a user-friendly interface, ensuring that even non-IT users can operate it effortlessly. Implementing the system is incredibly easy, allowing for quick deployment in healthcare facilities.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
