import React from "react";

export default function Features2() {
  return (
    <div id="features" className="features section panel  scrollSpysection">
      <div className="section-outer panel pt-6 lg:pt-8 xl:pt-10">
        <div className="container xl:max-w-xl">
          <div className="section-inner panel">
            <div
              className="panel vstack items-center gap-2 xl:gap-3 mb-4 lg:mb-8  mx-auto "
              data-anime="onview: -100; targets: >*; translateY: [48, 0]; opacity: [0, 1]; easing: easeOutCubic; duration: 500; delay: anime.stagger(100, {start: 200});"
            >
              <h2 className="h3 lg:h2 xl:h1 m-0 px-2 max-w-650px text-center">
                Promoting{" "}
                <span className="d-inline-flex px-1 bg-secondary text-primary -rotate-1 lg:-rotate-2 rounded-1 lg:rounded-1-5">
                Health and Safety
                </span>{" "}
                Information
              </h2>
              <p className="fs-6 xl:fs-5 text-black dark:text-white max-w-950px">
              In healthcare settings, delivering vital health and safety information efficiently is crucial. Digital signage allows healthcare facilities to distribute emergency alerts and health protocols promptly, ensuring swift dissemination of critical information. By centrally controlling digital signage, healthcare providers can customize content updates across various locations, enhancing the relevance and timeliness of the information.                
              </p>
            </div>
            <div className="row child-cols-12 lg:child-cols-5 col-match g-2">
              <div className="lg:col-7">
                <div
                  className="panel overflow-hidden bg-secondary text-gray-900 dark:bg-gray-800 dark:text-white rounded-2 lg:rounded-3"
                  data-anime="onview: -100; translateY: [80, 0]; opacity: [0, 1]; easing: easeOutCubic; duration: 500; delay: 0;"
                >
                  <div
                    className="panel vstack items-start gap-2 p-3 lg:p-4 xl:p-6"
                    data-anime="onview: -100; targets: >*; translateY: [16, 0]; opacity: [0, 1]; easing: easeOutCubic; duration: 500; delay: anime.stagger(100, {start: 500});"
                  >
                    
                    <h4 className="h4 m-0">• Health Protocols</h4>
                    <p className="fs-6 lg:fs-5 text-black dark:text-white">
                    Health protocols such as mandatory mask-wearing and handwashing are crucial for infection control in healthcare settings. Digital signage can effectively display reminders about these essential health protocols, enhancing understanding and compliance.                 
                    </p>
                  </div>
                  <div className="panel ltr:ps-3 ltr:lg:ps-4 ltr:xl:ps-6 rtl:pe-3 rtl:lg:pe-4 rtl:xl:pe-6">
                    <img
                      className="ltr:rounded-top-start-1-5 rtl:rounded-top-end-1-5"
                      alt="healthcare"
                      src="https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/health_care/health1.png"
                      width="1280"
                      height="837"
                    />
                  </div>
                </div>
              </div>
              <div>
                <div
                  className="panel vstack items-start overflow-hidden bg-primary-700 rounded-2 lg:rounded-3 uc-dark"
                  data-anime="onview: -100; translateY: [80, 0]; opacity: [0, 1]; easing: easeOutCubic; duration: 500; delay: 100;"
                >
                  <div
                    className="position-cover opacity-70 bg-cover"
                    style={{ backgroundPosition: "50% 85%" }}
                    data-src="/assets/images/template/feature-06-bg-masked.png"
                    data-uc-img=""
                  />
                  <div className="position-cover bg-gradient-to-t from-gray-800 via-transparent to-gray-900" />
                  <div className="position-absolute d-inline-block w-500px h-500px rounded-circle bg-gradient-45 from-primary to-white start-50 blur-10 translate-middle blend-soft-light" />
                  <div className="panel p-3">
                    <img
                      className="rounded-bottom-1-5 lg:rounded-bottom-3"
                      alt="healthcare"
                      src="https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/health_care/health4.png"
                      width="664"
                      height="496"
                    />
                  </div>
                  <div
                    className="panel vstack items-start justify-between gap-2 p-3 lg:p-4 xl:p-6 pt-0 lg:pt-0 xl:pt-0"
                    data-anime="onview: -100; targets: >*; translateY: [16, 0]; opacity: [0, 1]; easing: easeOutCubic; duration: 500; delay: anime.stagger(100, {start: 500});"
                  >
                    <div className="content vstack items-start gap-2">
                      <h4 className="h4 m-0">• Health Protocols</h4>
                      <p className="fs-6 lg:fs-5 dark:text-white">
                      Continuous display of these reminders fosters a culture of safety awareness and ensures that safety starts with adherence to necessary workplace safety habit protocols.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div
                  className="panel vstack items-start overflow-hidden bg-gray-800 rounded-2 lg:rounded-3 uc-dark"
                  data-anime="onview: -100; translateY: [80, 0]; opacity: [0, 1]; easing: easeOutCubic; duration: 500; delay: 200;"
                >
                  <div
                    className="position-cover opacity-70 bg-cover"
                    style={{ backgroundPosition: "50% 85%" }}
                    data-src="/assets/images/template/feature-06-bg-masked-2.png"
                    data-uc-img=""
                  />
                  <div className="position-cover bg-gradient-to-t from-gray-800 via-transparent to-gray-900" />
                  <div className="position-absolute d-inline-block w-500px h-500px rounded-circle bg-gradient-45 from-primary to-white start-50 blur-10 translate-middle blend-soft-light" />
                  <div className="panel px-3 lg:px-4 xl:px-6">
                    <img
                      className="rounded-bottom-1-5 border border-top-0"
                      alt="healthcare"
                      src="https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/health_care/health3.png"
                      width="800"
                      height="620"
                    />
                  </div>
                  <div
                    className="panel vstack items-start justify-between gap-2 p-3 lg:p-4 xl:p-6"
                    data-anime="onview: -100; targets: >*; translateY: [16, 0]; opacity: [0, 1]; easing: easeOutCubic; duration: 500; delay: anime.stagger(100, {start: 500});"
                  >
                    <div className="content vstack items-start gap-2">
                      <h4 className="h4 m-0">• Emergency Information</h4>
                      <p className="fs-6 lg:fs-5 dark:text-white">
                        Emergency information is vital in healthcare settings to ensure the safety of patients, staff, and visitors. Digital signage can be used for immediate updates on health protocols, emergency alerts, and important announcements, ensuring that everyone stays informed and safe.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="lg:col-7">
                <div
                  className="panel vstack items-start overflow-hidden bg-secondary text-gray-900 dark:bg-gray-800 dark:text-white rounded-2 lg:rounded-3"
                  data-anime="onview: -100; translateY: [80, 0]; opacity: [0, 1]; easing: easeOutCubic; duration: 500; delay: 300;"
                >
                  <div
                    className="panel vstack items-center gap-2 p-3 lg:p-4 xl:p-6"
                    data-anime="onview: -100; targets: >*; translateY: [16, 0]; opacity: [0, 1]; easing: easeOutCubic; duration: 500; delay: anime.stagger(100, {start: 500});"
                  >
                    <h4 className="h4 m-0">• Emergency Information</h4>
                    <p className="fs-6 md:fs-5 lg:fs-4 m-0 xl:px-4 text-center">
                      Displaying reminders about health protocols like mask-wearing and handwashing through digital signage can enhance compliance during emergencies, further ensuring the life safety of everyone in the facility.
                    </p>
                  </div>
                  <div className="panel px-3 lg:px-4 xl:px-6 mb-2 lg:mb-5">
                    <img
                      alt="healthcare"
                      src="https://signinspire-marketing-documents.s3.ap-southeast-2.amazonaws.com/HomePage/blog/health_care/health2.png"
                      width="1280"
                      height="800"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
