import React from "react";

export default function KeyFeatures() {
  return (
    <div
      id="key_features"
      className="key-features section panel overflow-hidden bg-gray-900 uc-dark"
    >
      <div className="section-outer panel py-6 xl:py-9 dark:bg-gray-800">
        <div className="container sm:max-w-md lg:max-w-lg">
          <div className="section-inner panel">
            <div className="panel vstack gap-4 sm:gap-6 xl:gap-8">
              <h2
                className="title h3 lg:h2 xl:h1 m-0 text-center max-w-550px mx-auto"
                data-anime="onview: -100; translateY: [48, 0]; opacity: [0, 1]; easing: spring(1, 80, 10, 0); duration: 450; delay: 250;"
              >
                <span className="text-primary">Real-Time Updates and Customer Satisfaction</span>
              </h2>
              <p className="desc fs-6 dark:text-white">
              Real-time updates on a digital menu board can make a big difference in restaurant operations. With digital menu board solutions, hospitality businesses can quickly adjust menu items and promotions, ensuring customers always see the latest offerings. This means no more outdated menus—everything on display is up-to-date and accurate.
              </p>
              <p className="desc fs-6 dark:text-white">
              Real-time updates also make things easier for staff. By using menu boards that can be changed instantly, staff spend less time on manual updates and more time focusing on customer service. This helps create a smoother experience for everyone, from employees to guests.
              </p>
              <p className="desc fs-6 dark:text-white">
              In the hospitality business, having real-time information available can improve the overall customer experience. When customers see fresh, current options on digital menu boards, they’re more likely to feel engaged and satisfied with their visit, making real-time updates a valuable tool for any restaurant or café.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
